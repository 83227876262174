import React, { useState, useEffect } from 'react';

import { DOMAIN, HEALTH_CHECK_RETRY_TIMEOUT } from '../../../../constants';
import InternalLink from '../../../InternalLink';
import { checkVmServiceHealth } from '../../../../utilities/api';
import AppLinks from './AppLinks';
import VmLinks from './VmLinks';

const checkStatus = async (host, service) => {
  try {
    const response = await checkVmServiceHealth(host, service);
    return response.ok;
  } catch (err) {
    return false;
  }
};

const WorkspaceLinks = ({ item, status }) => {
  const [statusRstudio, setStatusRstudio] = useState(false);
  const [statusJupyter, setStatusJupyter] = useState(false);

  const noServices = item.tags.noServices === 'true';

  useEffect(() => {
    const timeouts = {};

    if (item.type === 'vm' && status === 'available' && !noServices) {
      const host = `${item.name}.${DOMAIN}`;

      const checkService = async (service, setState) => {
        const status = await checkStatus(host, service);
        setState(status);

        if (!status) {
          timeouts[service] = setTimeout(
            () => checkService(service, setState),
            HEALTH_CHECK_RETRY_TIMEOUT
          );
        }
      };

      checkService('rstudio', setStatusRstudio);
      checkService('jupyter', setStatusJupyter);

      return () => Object.values(timeouts).forEach(timeout => clearTimeout(timeout));
    }
  }, [item, status, noServices]);

  if (item.type === 'vm') {
    return <VmLinks item={item} statusJupyter={statusJupyter} statusRstudio={statusRstudio} />;
  }
  if (item.type === 'app') {
    return <AppLinks item={item} />;
  }

  return (
    <InternalLink
      type={{ name: item.tags?.dswname || item.name, icon: item.icon }}
      url={item.location}
    />
  );
};

export default WorkspaceLinks;
