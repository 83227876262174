import React from 'react';
import Icon from './Icon';
import styles from './NewTabLink.module.css';

const NewTabLink = ({ url, type, disabled }) => url ? (
  <span className={styles.link} title={url}>
    <Icon
      disabled={disabled}
      iconName={type.icon}
      title={type.name}
    />
    {disabled ?
      <span>{type.name} is starting...</span> :
      <a className={styles.newTab} href={url} rel="noopener noreferrer" target="_blank">{type.name}</a>
    }
  </span>
) : false;

export default NewTabLink;
