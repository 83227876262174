import { fetchJson, putJson, postJson, del } from './request';


export function getWorkspaces() {
  return fetchJson('/api/workspaces');
}

export function getAllWorkspaces() {
  return fetchJson('/api/workspaces/all');
}

export async function startRStudio() {
  const response = await postJson('/api/workspaces/rstudio');
  return await response.json();
}

export async function startJupyter() {
  const response = await postJson('/api/workspaces/jupyter');
  return await response.json();
}

export function deleteWorkspace(name) {
  return del(`/api/workspaces/${name}`);
}

export function updateWorkspace(name) {
  return putJson(`/api/workspaces/${name}`, null, { upgradeImage: true });
}

export function updateAllWorkspaces() {
  return putJson(`/api/workspaces`, null, { upgradeImage: true });
}
