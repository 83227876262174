import React, { useState, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import Content from '../components/Content';
import GridContainer from '../components/GridContainer';
import Button from '../components/Button';
import Navigation from '../components/navigation/Navigation';

import { saveUserSettings } from '../store/settingsSlice';
import useToaster from '../utilities/hooks/useToaster';
import AppContext from '../context/App';

import styles from './Account.module.css';

const isValidTimeZone = (timeZone) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch (err) {
    return false;
  }
};

const Account = () => {
  const dispatch = useDispatch();
  const { accountInfo } = useContext(AppContext);
  const { userName, name } = accountInfo.account;
  const [isValidSettings, setIsValidSettings] = useState(true);
  const { permissions, settings } = useSelector(state => state);
  const timezoneInput = useRef();
  const { addSuccessToast } = useToaster();

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const groups = permissions.groups;

  const updateSettings = async () => {
    dispatch(saveUserSettings({ ...settings, timezone: timezoneInput.current.value })).then(
      unwrapResult
    );
    addSuccessToast('Settings saved');
  };

  const validateSettings = () => {
    if (!isValidTimeZone(timezoneInput.current.value)) {
      setIsValidSettings(false);
    } else {
      setIsValidSettings(true);
    }
  };

  return (
    <GridContainer>
      <Navigation
        items={[{ value: '/account', label: 'Your account' }]}
        label="Account"
        logoId="account"
      />
      <Content>
        <h2>Your account</h2>
        <div className={styles.details}>
          <h4>Username</h4>
          <span>{userName}</span>
          <h4>Name</h4>
          <span>{name}</span>
          <h4>Application permissions</h4>
          {permissions.dataSharing && (
            <ul className={styles.list}>
              <li>Data sharing</li>
              <ul className={styles.list}>
                {permissions.dataSharing.user && <li>User</li>}
                {permissions.dataSharing.admin && <li>Admin</li>}
              </ul>
            </ul>
          )}
          {permissions.dataScience && (
            <ul className={styles.list}>
              <li>Data science</li>
              <ul className={styles.list}>
                {permissions.dataScience.user && <li>User</li>}
                {permissions.dataScience.admin && <li>Admin</li>}
                {permissions.dataScience.vmLocalAdmin && <li>VM Local Admin</li>}
              </ul>
            </ul>
          )}
          <h4>Settings</h4>
          <div className={styles.settings}>
            <div>
              <label>Timezone:</label>
              <input
                defaultValue={settings.timezone || browserTimezone}
                onChange={validateSettings}
                ref={timezoneInput}
                type="text"
              />
            </div>
            <div>
              <label>Browser timezone:</label> {browserTimezone}
            </div>
            <Button disabled={!isValidSettings} onClick={updateSettings} type="button">
              Save
            </Button>
          </div>
          <h4>All user groups</h4>
          <ul className={styles.list}>
            {groups.map(group => (
              <li key={group.name}>{group.name}</li>
            ))}
          </ul>
        </div>
      </Content>
    </GridContainer>
  );
};

export default Account;
