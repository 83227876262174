import { useContext } from 'react';

import AppContext from '../../context/App';

import Hidden from '../../components/Hidden';
import MobileMenu from '../../components/navigation/MobileMenu';

import Logo from '../Logo';

import NavigationLeft from './NavigationLeft';
import NavigationList from './NavigationList';

const NavigationContent = ({ items, logoId, label }) => {
  const { accountInfo, logout, permissions } = useContext(AppContext);

  return (
    <NavigationLeft accountInfo={accountInfo} logout={logout} permissions={permissions}>
      <Logo id={logoId} label={label} />
      <NavigationList centered items={items} />
    </NavigationLeft>
  );
};

const Navigation = ({ items, logoId, label }) => {
  const { setMobileMenuOpen, mobileMenuOpen } = useContext(AppContext);

  return (
    <>
      <Hidden mobile>
        <NavigationContent items={items} label={label} logoId={logoId} />
      </Hidden>
      <Hidden desktop>
        <MobileMenu close={() => setMobileMenuOpen(false)} open={mobileMenuOpen}>
          <NavigationContent items={items} label={label} logoId={logoId} />
        </MobileMenu>
      </Hidden>
    </>
  );
};

export default Navigation;
