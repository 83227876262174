import React from 'react';

import ActionButton from '../../ActionButton';
import { parseStatus } from './utils';

const VmActions = ({
  actionsDisabled, start, stop, refresh, status, refreshing, confirmDelete, createImage, settings
}) => {
  const { itemIsAvailable, itemIsFailed, itemIsOffline } = parseStatus(status);

  return (
    <>
      {itemIsAvailable &&
        <>
          <ActionButton disabled={actionsDisabled} icon="stop" name="Shutdown" onClick={stop} />
          <ActionButton disabled={refreshing} icon="rename" name="Settings" onClick={settings} />
        </>
      }
      {itemIsOffline &&
        <>
          <ActionButton disabled={actionsDisabled} icon="start" name="Start" onClick={start} />
          <ActionButton disabled={actionsDisabled} icon="delete" name="Delete" onClick={confirmDelete} />
          <ActionButton disabled={actionsDisabled} icon="add" name="Create image" onClick={createImage} />
        </>
      }
      {itemIsFailed &&
        <>
          <ActionButton disabled={actionsDisabled} icon="start" name="Start" onClick={start} />
          <ActionButton disabled={actionsDisabled} icon="delete" name="Delete" onClick={confirmDelete} />
        </>
      }
      <ActionButton disabled={refreshing} icon="refresh" name="Refresh" onClick={refresh} />
    </>
  );
};

export default VmActions;
