import React from 'react';

import ActionButton from '../../ActionButton';
import { parseStatus } from './utils';

const AppActions = ({
  actionsDisabled, setIsAppUpdating, status, confirmDelete, refresh, refreshing
}) => {
  const { itemIsStarting } = parseStatus(status);

  return (
    <>
      {!itemIsStarting &&
        <>
          <ActionButton
            disabled={actionsDisabled}
            icon="update"
            name="Update application"
            onClick={setIsAppUpdating}
          />
        </>
      }
      <ActionButton disabled={actionsDisabled} icon="delete" name="Delete" onClick={confirmDelete} />
      <ActionButton disabled={refreshing} icon="refresh" name="Refresh" onClick={refresh} />
    </>
  );
};

export default AppActions;
