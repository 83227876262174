import React, { useEffect, useRef, useState } from 'react';

import ActionButton from '../ActionButton';
import Button from '../Button';

import useToaster from '../../utilities/hooks/useToaster';
import { isTargetButtonOrLink } from '../../utilities/utils';

import { ReactComponent as Add } from '../icons/add.svg';

import styles from './New.module.css';

const New = ({ inProgress, hasWriteAccess, addUploads, createFolder, showNewFolderDialog }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const uploadFormRef = useRef(null);
  const fileInputRef = useRef(null);
  const folderInputRef = useRef(null);

  const { addErrorToast } = useToaster();

  const handleClickOutside = (event) => {
    if (event.target.id === 'newBtn') {
      return;
    }

    if (!menuRef.current?.contains(event.target) || isTargetButtonOrLink(event)) {
      setTimeout(() => setMenuVisible(false), 200); // Avoid closing before navigation is done
    }
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });


  const processFileUpload = async (e) => {
    try {
      const files = [...e.target.files];

      if (files.length > 0) {
        addUploads(files);
        uploadFormRef.current.reset();
      }
    } catch (err) {
      console.log(err);
      addErrorToast(err.message);
    }
  };

  function getFolderPaths(files) {
    return [...new Set(files.map(x => x.webkitRelativePath.split('/').slice(0, -1).join('/')))].sort();
  }

  const processFolderUpload = async (e) => {
    try {
      const files = [...e.target.files];
      const folderPaths = getFolderPaths(files);

      for (const path of folderPaths) {
        await createFolder(`/${path}`, false);
      }

      addUploads(files);
    } catch (err) {
      console.log(err);
      addErrorToast(err.message);
    }
  };

  const handleUploadFiles = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleUploadFolder = (e) => {
    e.preventDefault();
    folderInputRef.current.click();
  };

  const handleNewFolder = (e) => {
    e.preventDefault();
    showNewFolderDialog();
  };

  const actions = [
    { name: 'New folder', icon: 'newFolder', onClick: handleNewFolder, dividerAfter: true },
    { name: 'Upload files', icon: 'uploadFile', onClick: handleUploadFiles },
    { name: 'Upload folder', icon: 'uploadFolder', onClick: handleUploadFolder }
  ];

  const Menu = () => (
    <div className={styles.menu} ref={menuRef} >
      {actions.map(action => <ActionButton key={action.name} {...action} />)}
    </div>
  );

  if (!hasWriteAccess) {
    return false;
  }

  return (
    <form className={styles.form} ref={uploadFormRef}>
      <input
        className={styles.fileInput}
        multiple
        onChange={processFileUpload}
        ref={fileInputRef}
        type="file"
      />
      <input
        className={styles.fileInput}
        multiple
        onChange={processFolderUpload}
        ref={folderInputRef}
        type="file"
        webkitdirectory="true"
      />
      {inProgress ?
        <img alt="In progress" className={styles.loadingIcon} src="/icons/loading.svg" /> :
        <Button color="new" id="newBtn" onClick={() => setMenuVisible(x => !x)} type="button">
          <Add />
          New
        </Button>
      }
      {menuVisible && <Menu />}
    </form>
  );
};

export default New;
