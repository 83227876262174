import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  getAvailableConfigurations,
  getDefaultSizes,
  getSshKey,
  listVMs,
  listImages,
  getVmLimitPerUser
} from '../../../utilities/api';
import { toVmSizeOption } from '../../../utilities/formatting';
import useToaster from '../../../utilities/hooks/useToaster';

import Content from '../../Content';
import Button from '../../Button';
import FormLabel from '../../FormLabel';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';
import Spinner from '../../Spinner';
import formStyles from '../../form.module.css';

import VmDetails from '../VmDetails';
import Images from './Images';

import styles from './RequestWorkspace.module.css';

import Disabled from './Disabled';
import CostAllocation from './CostAllocation';
import SshKey from './SshKey';
import VmSize from './VmSize';
import MultiUser from './MultiUser';

const RequestWorkspace = ({ useVirtualMachines }) => {
  const { addSuccessToast } = useToaster();
  const { register, handleSubmit, errors, control, setValue, reset, watch } = useForm();

  const [disabled, setDisabled] = useState(false);
  const [vmSize, setVmSize] = useState();
  const [vmConfigurations, setVmConfigurations] = useState({});
  const [defaultSizes, setDefaultSizes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sshKey, setSshKey] = useState(null);
  const [vmLimitPerUser, setVmLimitPerUser] = useState(0);
  const [images, setImages] = useState([]);
  const form = useRef(null);
  const history = useHistory();
  const permissions = useSelector(state => state.permissions);
  const user = useSelector(state => state.user);

  const { createVirtualMachine } = useVirtualMachines;

  useEffect(() => {
    const fetchConfigurations = async () => {
      const { configurations } = await getAvailableConfigurations();
      setVmConfigurations(configurations);

      const defaultSizes = await getDefaultSizes();
      setDefaultSizes(defaultSizes);
      const defaultSize = defaultSizes[0].size;
      setVmSize(defaultSize);

      reset({
        costCenter: undefined,
        wbsCode: undefined,
        vmSize: toVmSizeOption(configurations[defaultSize]),
        username: user.username
      });
    };

    const fetchSshKey = async () => {
      const res = await getSshKey();
      setSshKey(res.publicKey);
    };

    const fetchImages = async () => {
      const data = await listImages();
      setImages(data);
    };

    fetchConfigurations();
    fetchSshKey();
    fetchImages();
  }, [reset, user]);

  useEffect(() => {
    const checkVmCount = async () => {
      setIsLoading(true);
      const vms = await listVMs();
      const { vmLimitPerUser } = await getVmLimitPerUser();

      if (vms.length >= vmLimitPerUser) {
        setDisabled(true);
      }
      setIsLoading(false);
      setVmLimitPerUser(vmLimitPerUser);
    };

    checkVmCount();
  }, []);

  const onSubmit = async (data) => {
    addSuccessToast('Started creating virtual machine. You will be redirected to overview page in 5 seconds');
    setIsLoading(true);
    createVirtualMachine(data);
    setTimeout(() => { history.push('/data-science'); }, 5000);
    setIsLoading(false);
  };

  const handleClear = (event) => {
    event.target.blur();
    reset();
  };

  return (
    <>
      <Content>
        <h2>Create virtual machine</h2>

        <Disabled disabled={disabled} vmLimitPerUser={vmLimitPerUser} />

        {vmSize && !disabled && (
          <form onSubmit={handleSubmit(onSubmit)} ref={form}>
            <h5 className={styles.description}>Enter virtual machine details</h5>
            <div className={styles.form}>
              <div className={styles.formItem}>
                <FormLabel error={errors.vmName}>
                  Name
                  {errors.vmName && ' *'}
                </FormLabel>
                <input
                  autoFocus
                  className={`${formStyles.input} ${errors.vmName && formStyles.error}`}
                  name="vmName"
                  placeholder="Name"
                  ref={register({ required: true })}
                  type="text"
                />
              </div>

              <div className={styles.formItem}>
                <FormLabel>Description</FormLabel>
                <textarea
                  className={formStyles.input}
                  name="description"
                  placeholder="Description that helps you identify this virtual machine"
                  ref={register}
                  rows="2"
                />
                <div className={styles.error} />
              </div>

              <CostAllocation register={register} />

              <VmSize
                control={control}
                defaultSizes={defaultSizes}
                errors={errors}
                setValue={setValue}
                setVmSize={setVmSize}
                vmConfigurations={vmConfigurations}
                vmSize={vmSize}
              />

              <Images images={images} register={register} />

              {!watch('multiUser') && (
                <div className={styles.formItem}>
                  <FormLabel error={errors.username}>
                    Username
                    {errors.username && ' *'}
                  </FormLabel>
                  <input
                    autoComplete="username"
                    className={`${formStyles.input} ${errors.username && formStyles.error}`}
                    name="username"
                    placeholder="Username"
                    ref={register({ required: true })}
                    type="text"
                  />
                </div>
              )}

              <MultiUser errors={errors} owner={user} register={register} />
              <SshKey errors={errors} register={register} sshKey={sshKey} />

              {(permissions.dataScience.admin || permissions.dataScience.vmLocalAdmin) && (
                <div className={styles.formItem}>
                  <div className={styles.checkbox}>
                    <input id="sudo" name="sudo" ref={register} type="checkbox" />
                    <label htmlFor="sudo">Root access</label>
                  </div>
                </div>
              )}
              <div className={styles.actions}>
                <Button color="secondary" disabled={isLoading} onClick={handleClear} type="button">Clear</Button>
                <Button color="primary" disabled={isLoading} type="submit">Create virtual machine</Button>
                {isLoading && <Spinner />}
              </div>
            </div>
          </form>
        )}
      </Content>
      <Hidden mobile>
        <NavigationRight>
          <VmDetails
            image={images.find(image => image.id === watch('image'))}
            item={vmConfigurations[watch('vmSize')?.value]}
          />
        </NavigationRight>
      </Hidden>
    </>
  );
};

export default RequestWorkspace;
