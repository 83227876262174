import React from 'react';
import SignoutButton from '../components/SignoutButton';
import styles from './Home.module.css';

const Loading = ({ error, logout }) => (
  <main>
    <div className={styles.page}>
      <div className={styles.content}>
        <img alt="Orion" className={styles.logo} src="/images/logo-white.svg" />
        <div className={styles.cards}>
          <h3>
            {error ?? 'Loading ...'}
          </h3>
        </div>
      </div>
      <div className={styles.actions}>
        <SignoutButton logout={logout} style={{ visibility: error ? 'visible' : 'hidden' }} />
      </div>
    </div>
  </main>
);

export default Loading;
