export const SCOPES = {
  storage: 'https://storage.azure.com/user_impersonation'
};

let authProvider;

class RequestError extends Error {
  constructor(message, response) {
    super(`${message} Status: ${response.status} - ${response.statusText}`);
    this.statusCode = response.status;
    this.statusText = response.statusText;
    this.name = 'RequestError';
  }
}

export function setAuthProvider(provider) {
  authProvider = provider;
}

export function getAuthProvider() {
  return authProvider;
}

export async function setAuthCookie(scopes) {
  const token = scopes ? await getAuthorizationTokenForScopes(scopes) : await authProvider.getAccessToken();
  const bearerToken = token.accessToken;

  document.cookie = `accessToken=${bearerToken}; max-age=3600; path=/;`;
}

export async function getAuthorizationHeader(scopes) {
  const token = scopes ? await getAuthorizationTokenForScopes(scopes) : await authProvider.getAccessToken();
  const bearerToken = token.accessToken;

  return { 'Authorization': `Bearer ${bearerToken}` };
}

async function getAuthorizationTokenForScopes(scopes) {
  return await authProvider.acquireTokenSilent({ scopes, redirectUri: authProvider.getProviderOptions().tokenRefreshUri });
}

export async function fetchJsonUnauthenticated(path) {
  const response = await fetch(path);

  if (response.ok) {
    return response.json();
  }

  throw new RequestError(`Fetching data from ${path} failed.`, response);
}

export async function fetchJson(path, scopes) {
  const response = await fetch(path, {
    headers: await getAuthorizationHeader(scopes)
  });

  if (response.ok) {
    return response.json();
  }

  throw new RequestError(`Fetching data from ${path} failed.`, response);
}

export async function sendJson(method, path, scopes, data) {
  const response = await fetch(path, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...await getAuthorizationHeader(scopes)
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    const error = new Error(`${method} to ${path} failed.`);
    error.body = await response.text();
    throw error;
  }

  return response;
}

export async function postJson(path, scopes, data) {
  return sendJson('POST', path, scopes, data);
}

export async function putJson(path, scopes, data) {
  return sendJson('PUT', path, scopes, data);
}

export async function del(path, scopes) {
  const response = await fetch(path, {
    method: 'DELETE',
    headers: await getAuthorizationHeader(scopes)
  });
  return response.ok;
}
