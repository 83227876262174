import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { formatDate } from '../utilities/formatting';

const DateTime = ({ date }) => {
  const { timezone } = useSelector(state => state.settings);

  return <>{formatDate(date, timezone)}</>;
};

export default memo(DateTime);
