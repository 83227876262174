import React, { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import Button from '../Button';
import { getPdfFileRequest, getFile } from '../../utilities/api';
import useToaster from '../../utilities/hooks/useToaster';

import Modal, { Header } from './Modal';
import modalStyles from './Modal.module.css';
import styles from './Preview.module.css';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// https://github.com/wojtekmaj/react-pdf/wiki/Known-issues#create-react-app
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MAX_INITIAL_PAGES = 25;

const Pages = ({ pagesLoaded, width }) =>
  Array.from(
    new Array(pagesLoaded),
    (el, index) => (
      <Page
        className={styles.page}
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        width={width}
      />
    )
  );

const Preview = ({ close, filePath, fileName }) => {
  const { addErrorToast } = useToaster();
  const [request, setRequest] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pagesLoaded, setPagesLoaded] = useState(0);

  const width = window.innerWidth < 1024 ? window.innerWidth * 0.75 : undefined;

  useEffect(() => {
    const getRequest = async () => {
      const request = await getPdfFileRequest(filePath);
      setRequest(request);
    };

    getRequest();
  }, [filePath]);

  const onSuccess = ({ numPages }) => {
    setPageCount(numPages);
    setPagesLoaded(Math.min(numPages, MAX_INITIAL_PAGES));
  };

  const onError = (data) => {
    addErrorToast('PDF load error');
    console.error(data);
  };

  const loadMorePages = () => {
    if (pagesLoaded + MAX_INITIAL_PAGES <= pageCount) {
      setPagesLoaded(pagesLoaded + MAX_INITIAL_PAGES);
    } else {
      setPagesLoaded(pageCount);
    }
  };

  const download = () => getFile(filePath, fileName);

  return (
    <Modal className={styles.modal} close={close}>
      <Header>{fileName}</Header>
      <div className={`${modalStyles.body} ${styles.body}`}>
        <div>
          <Button onClick={download} type="button">Download</Button>
        </div>
        <Document
          className={styles.document}
          file={request}
          onLoadError={onError}
          onLoadSuccess={onSuccess}
        >
          <Pages pagesLoaded={pagesLoaded} width={width} />
        </Document>
        {pagesLoaded < pageCount && (
          <Button color="primary" onClick={loadMorePages} type="button">More pages</Button>
        )}
      </div>
    </Modal>
  );
};

export default Preview;
