import React from 'react';

import NewTabLink from '../../../NewTabLink';

const AppLinks = ({ item }) => (
  (
    <NewTabLink
      type={{
        name: 'Application',
        icon: 'vm'
      }}
      url={`https://${window.location.host}/app/${item.name}/`}
    />
  )
);

export default AppLinks;
