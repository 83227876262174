import React from 'react';

import { formatDuration } from '../../utilities/formatting';

import StatusIcon from './StatusIcon';
import styles from './Status.module.css';

const Status = ({ status, statusDate }) => (
  <div className={styles.container}>
    <StatusIcon status={status} />
    <span className={styles.status}>
      {status} {statusDate && ` (${formatDuration(statusDate)})`}
    </span>
  </div>
);

export default Status;
