import React from 'react';

import styles from './FileBrowserError.module.css';

const FileBrowserError = ({ errorCode, path }) => {
  if (errorCode === 403) {
    return path === '' ?
      <span>No assigned projects</span> :
      <span>You don&apos;t have permission to access <span className={styles.path}>{path}</span></span>;
  }

  if (errorCode === 404) {
    return (
      <span>
        Folder <span className={styles.path}>{path}</span> not found
      </span>
    );
  }

  return <div>Error</div>;
};

export default FileBrowserError;
