import React, { useState, createContext } from 'react';

export const LayoutContext = createContext([false, () => {}]);

const LayoutProvider = (props) => {
  const [minimized, setMinimized] = useState(false);

  return (
    <LayoutContext.Provider value={[minimized, setMinimized]}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
