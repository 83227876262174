import React from 'react';
import styles from './form.module.css';

const ProjectSearch = ({ value, onChange }) => (
  <input
    className={`${styles.input} ${styles.search}`}
    onChange={onChange}
    placeholder="Search projects"
    value={value}
  />
);

export default ProjectSearch;
