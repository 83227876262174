import { ReactComponent as Clipboard } from './icons/clipboard.svg';
import { ReactComponent as Copy } from './icons/copy.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as Move } from './icons/move.svg';
import { ReactComponent as NewFolder } from './icons/newFolder.svg';
import { ReactComponent as Open } from './icons/open.svg';
import { ReactComponent as Refresh } from './icons/refresh.svg';
import { ReactComponent as Rename } from './icons/rename.svg';
import { ReactComponent as Restore } from './icons/restore.svg';
import { ReactComponent as Share } from './icons/share.svg';
import { ReactComponent as Star } from './icons/star.svg';
import { ReactComponent as StarBorder } from './icons/star-border.svg';
import { ReactComponent as Start } from './icons/start.svg';
import { ReactComponent as Stop } from './icons/stop.svg';
import { ReactComponent as Update } from './icons/update.svg';
import { ReactComponent as UploadFile } from './icons/uploadFile.svg';
import { ReactComponent as UploadFolder } from './icons/uploadFolder.svg';
import { ReactComponent as Add } from './icons/add.svg';

const SvgIcon = (icon) => {
  switch (icon) {
    case 'clipboard': return Clipboard;
    case 'copy': return Copy;
    case 'delete': return Delete;
    case 'download': return Download;
    case 'move': return Move;
    case 'newFolder': return NewFolder;
    case 'open': return Open;
    case 'refresh': return Refresh;
    case 'rename': return Rename;
    case 'restore': return Restore;
    case 'share': return Share;
    case 'star': return Star;
    case 'starBorder': return StarBorder;
    case 'start': return Start;
    case 'stop': return Stop;
    case 'update': return Update;
    case 'uploadFile': return UploadFile;
    case 'uploadFolder': return UploadFolder;
    case 'add': return Add;
    default:
      console.log('Missing icon', icon);
      return Start;
  }
};

export default SvgIcon;
