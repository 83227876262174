import React from 'react';
import styles from './Tooltip.module.css';

const Tooltip = ({ children, content }) => content ? (
  <div className={styles.container}>
    {children}
    <span className={styles.tooltip}>{content}</span>
  </div>
) : children;

export default Tooltip;
