import React, { useState, useEffect } from 'react';

import Button from '../../Button';
import { listGroupMembers } from '../../../utilities/api';

import styles from './GroupMembers.module.css';

const GroupMembers = ({ group, name, close }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const getMembers = async () => setMembers(await listGroupMembers(group));
    getMembers();
  }, [group]);

  return (
    <div>
      <h3>Members of {name}</h3>
      <Button onClick={close}>Back</Button>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>UPN</th>
          </tr>
        </thead>
        <tbody>
          {members.map(member => (
            <tr key={member.upn}>
              <td>{member.name}</td>
              <td>{member.upn}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GroupMembers;
