import { useCallback, useState } from 'react';

import useToaster from '../../utilities/hooks/useToaster';
import { uploadFile, uploadFileInChunks } from '../api';

import { getFileFromEntry, readAllDirectoryEntries } from '../utils';

const TWENTY_MB = 20 * 1000000;
const MAX_PARALLER_UPLOADS = 5;

const excludeFiles = ['.DS_Store'];

export const useUploader = (path, refresh, createFolder) => {
  const [uploadFiles, setUploadFiles] = useState([]);
  const { addErrorToast, addSuccessToast } = useToaster();

  function addUploads(files, currentPath = path) {
    const uploads = [...uploadFiles, ...files.filter(x => excludeFiles.includes(x.name) === false)];
    processMultipleFiles(uploads, currentPath);
  }

  async function addFolderUpload(items, currentPath = path) {
    // Folders
    items.filter(x => x.isDirectory).forEach(async (item) => {
      await createFolder(item.fullPath, false);
      const entries = await readAllDirectoryEntries(item.createReader());
      addFolderUpload(entries, path + item.fullPath);
    });

    // Files
    const files = await Promise.all(items
      .filter(x => x.isFile && excludeFiles.includes(x.name) === false)
      .map(entry => getFileFromEntry(entry)));

    addUploads(files, currentPath);

    if (files.length === 0) {
      refresh();
    }
  }

  function removeFromArray(array, item) {
    const index = array.findIndex(x => x === item);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  function updateInArray(array, item) {
    const index = array.findIndex(x => x === item);
    if (index !== -1) {
      array[index] = item;
    }
    return array;
  }

  function cancelUpload(file) {
    if (file.xhr) {
      file.xhr.abort();
    }
    const files = updateInArray([...uploadFiles], file);
    setUploadFiles(files);
  }

  const setUploadProgress = useCallback(
    (file, progress) => {
      setUploadFiles((uploadFiles) => {
        file.inProgress = true;
        if (progress > 100) {
          progress = 100;
        }
        file.progress = progress;
        return updateInArray([...uploadFiles], file);
      });
    }, []
  );

  const processUpload = useCallback(
    async (path, file) => {
      try {
        const smallFile = file.size < TWENTY_MB;
        const filePath = `${path}/${file.webkitRelativePath || file.name}`;

        const response = smallFile
          ? await uploadFile(filePath, file, setUploadProgress)
          : await uploadFileInChunks(filePath, file, setUploadProgress);

        if (response === 'Canceled') {
          addSuccessToast(`File upload canceled: ${file.name}`);
        }
        refresh();
      } catch (err) {
        console.log(err);
        addErrorToast(`Uploading file ${file.name} failed: ${err.status ?? ''} ${err.statusText ?? err.message}`);
      }

      setUploadFiles(uploadFiles => removeFromArray([...uploadFiles], file));
    }, [addErrorToast, addSuccessToast, setUploadProgress, refresh]
  );

  const processMultipleFiles = useCallback(async (files, currentPath) => {
    while (files.length > 0) {
      const filesToProcess = files.splice(0, MAX_PARALLER_UPLOADS);
      setUploadFiles(filesToProcess);
      await Promise.all(filesToProcess.map(file => processUpload(currentPath, file)));
    }
  }, [processUpload]);

  return {
    uploadFiles,
    addUploads,
    addFolderUpload,
    cancelUpload
  };
};

export default useUploader;
