import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';

import Button from '../components/Button';
import Content from '../components/Content';
import FormItem from '../components/FormItem';
import FormLabel from '../components/FormLabel';
import GridContainer from '../components/GridContainer';

import AppContext from '../context/App';

import useToaster from '../utilities/hooks/useToaster';
import formStyles from '../components/form.module.css';
import Switch from '../components/Switch';
import { sendFeedback } from '../utilities/api';

import Navigation from '../components/navigation/Navigation';

const categoryOptions = [
  {
    label: 'General',
    options: [
      { value: 'General', label: 'General' },
      { value: 'Bug report', label: 'Bug report' },
      { value: 'Feature request', label: 'Feature request' },
      { value: 'Other', label: 'Other' }
    ]
  },
  {
    label: 'Data Sharing',
    options: [
      { value: 'Data Sharing / Projects and studies', label: 'Projects and studies' },
      { value: 'Data Sharing / File management', label: 'File management' }
    ]
  },
  {
    label: 'Data Science',
    options: [
      { value: 'Data Science / Workspaces', label: 'Workspaces' },
      { value: 'Data Science / Virtual machines', label: 'Virtual machines' },
      { value: 'Data Science / Applications', label: 'Applications' },
      { value: 'Data Science / Package installation', label: 'Package installation' }
    ]
  },
  {
    label: 'User account',
    options: [
      { value: 'User account / Login issues', label: 'Login issues' },
      { value: 'User account / User rights', label: 'User rights' }
    ]
  }
];

const Feedback = () => {
  const [isSending, setIsSending] = useState(false);
  const { addErrorToast, addSuccessToast } = useToaster();
  const { accountInfo } = useContext(AppContext);
  const { register, handleSubmit, errors, control, reset, setValue } = useForm();

  useEffect(() => { register({ name: 'saveEmail' }); }, [register]);

  const save = async (data) => {
    setIsSending(true);

    const feedback = {
      sender: data.saveEmail ? accountInfo.account.userName : 'anonymous',
      category: data.category.value,
      content: data.content
    };

    try {
      await sendFeedback(feedback);
      addSuccessToast('Your feedback has been sent. Thank you!');
      reset();
    } catch (err) {
      addErrorToast('Failed to send feedback');
    }
    setIsSending(false);
  };

  return (
    <GridContainer>
      <Navigation items={[{ value: '/feedback', label: 'Feedback' }]} label="Feedback" logoId="feedback" />
      <Content>
        <h4>Feedback</h4>
        <form className={formStyles.form} onSubmit={handleSubmit(save)}>
          <FormItem>
            <FormLabel>Select feedback category</FormLabel>
            <Controller
              as={
                <Select
                  options={categoryOptions}
                  styles={{
                    control: styles => ({
                      ...styles,
                      border: errors.category ? '2px solid var(--error)' : styles.border,
                      height: 46,
                      marginBottom: '0.5em'
                    })
                  }}
                />}
              control={control}
              defaultValue={categoryOptions[0].options[0]}
              name="category"
              rules={{ required: true }}
            />
          </FormItem>
          <FormItem>
            <FormLabel>Message</FormLabel>
            <textarea
              className={`${formStyles.input} ${formStyles.textarea} ${errors.content && formStyles.error}`}
              name="content"
              placeholder="Your feedback"
              ref={register({ required: true })}
              rows="15"
            />
          </FormItem>
          <Switch
            label="I'd like a response (your email address will be saved)"
            name="saveEmail"
            onChange={e => setValue('saveEmail', e.target.checked)}
          />
          <div className={formStyles.actions}>
            <Button color="secondary" disabled={isSending} onClick={() => reset()} type="button">Clear</Button>
            <Button color="primary" disabled={isSending} type="submit">Send</Button>
          </div>
        </form>
      </Content>
    </GridContainer>
  );
};

export default Feedback;
