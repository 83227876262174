import React from 'react';
import { ReactComponent as Exit } from './icons/exit.svg';
import styles from './SignoutButton.module.css';

const SignoutButton = ({ logout, style }) => (
  <button className={styles.logout} onClick={logout} style={style} type="button">
    <Exit />
    Sign out
  </button>
);

export default SignoutButton;
