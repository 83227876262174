import React, { useState, useRef, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import Button from '../../Button';
import Content from '../../Content';
import FormLabel from '../../FormLabel';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';
import Spinner from '../../Spinner';
import FormItem from '../../FormItem';

import useToaster from '../../../utilities/hooks/useToaster';

import formStyles from '../../form.module.css';
import styles from '../../datascience/admin/Admin.module.css';
import { extractTags } from '../../../utilities/utils';
import { createProject, searchUsers } from '../../../utilities/api';

import SearchUsers from '../../SearchUsers';

const NewProject = ({ accountInfo, files }) => {
  const [isSaving, setIsSaving] = useState(false);
  const form = useRef(null);
  const { addErrorToast, addSuccessToast } = useToaster();
  const { register, handleSubmit, errors, control, reset, setValue } = useForm();

  const { projects, getProjects, isLoadingProjects } = files;

  const onSubmit = async (data) => {
    try {
      setIsSaving(true);
      const metadata = {
        description: data.description,
        projects: data.tags ? JSON.stringify(data.tags.map(x => x.value)) : undefined,
        owner_name: data.owner_name,
        owner: data.owner
      };
      await createProject(`/${data.name}`, metadata);
      await getProjects();
      addSuccessToast(<span>Project <b>{data.name}</b> created</span>);
    } catch (err) {
      addErrorToast(`Failed to create project. ${err.message}`);
      console.log(err);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    reset({
      owner_name: accountInfo.account.name,
      owner: accountInfo.account.userName
    });
  }, [accountInfo, reset]);

  const handleOwnerChange = ({ name, upn }) => {
    setValue('owner_name', name);
    setValue('owner', upn);
  };

  return (
    <>
      <Content>
        <h2>Administration</h2>
        <h4 className={styles.description}>
          Create new project
        </h4>
        {isLoadingProjects ?
          <Spinner /> :
          <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)} ref={form}>
            <span className={styles.explanation}>
              For clinical studies, use {'{'}study number{'}'} {'{'}study name{'}'} as a template. For example,
              the REFALS study area would be named “3119002 REFALS”. For less formal projects, use your own discretion to
              pick an appropriate project name.
            </span>
            <FormItem>
              <FormLabel>Project name</FormLabel>
              <input
                className={`${formStyles.input} ${errors.name && formStyles.error}`}
                name="name"
                placeholder="1234567 MyStudy"
                ref={register({
                  required: true,
                  validate: value => !projects.map(x => x.name).includes(value) || 'Project already exists'
                })}
                type="text"
              />
            </FormItem>
            <span className={formStyles.errorMessage}>
              <ErrorMessage errors={errors} name="name" />
            </span>
            <FormItem>
              <FormLabel>Description</FormLabel>
              <textarea
                className={`${formStyles.input} ${formStyles.textarea} ${errors.description && formStyles.error}`}
                name="description"
                placeholder="Project description"
                ref={register()}
                rows="6"
                type="text"
              />
            </FormItem>
            <FormItem>
              <FormLabel>Owner</FormLabel>
              <SearchUsers
                defaultUser={accountInfo.account}
                handleChange={handleOwnerChange}
                searchUsers={searchUsers}
              />
              <input name="owner_name" ref={register({ required: true })} type="hidden" />
              <input name="owner" ref={register({ required: true })} type="hidden" />
            </FormItem>
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <Controller
                as={
                  <CreatableSelect
                    isMulti
                    options={extractTags(projects).map(x => ({ value: x, label: x }))}
                    styles={{
                      control: styles => ({
                        ...styles,
                        height: 46,
                        marginBottom: '0.5em'
                      })
                    }}
                  />}
                control={control}
                defaultValue=""
                name="tags"
              />
            </FormItem>
            <div className={formStyles.actions}>
              <Button color="primary" disabled={isLoadingProjects || isSaving} type="submit">Create</Button>
            </div>
          </form>
        }
      </Content>
      <Hidden mobile>
        <NavigationRight />
      </Hidden>
    </>
  );
};

export default NewProject;
