import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { LayoutContext } from '../../context/LayoutProvider';
import { ReactComponent as Minimize } from '../icons/navigate_next.svg';

import styles from './NavigationRight.module.css';

const Minimizer = ({ minimized, onClick }) => (
  <div
    className={styles.minimizer}
    style={minimized ? { transform: 'rotate(180deg)' } : null}
    title={minimized ? 'Show' : 'Hide'}
  >
    <Minimize onClick={onClick} />
  </div>
);

const shouldShowMinimizer = (location) => {
  switch (location) {
    case '/data-science/workspaces/rstudio':
    case '/data-science/workspaces/jupyter':
      return true;
    default:
      return false;
  }
};

const NavigationRight = ({ children }) => {
  const [minimized, setMinimized] = useContext(LayoutContext);
  const location = useLocation();
  const showMinimizer = shouldShowMinimizer(location.pathname);

  useEffect(() => {
    setMinimized(showMinimizer);
  }, [showMinimizer, setMinimized]);

  return (
    <div className={styles.container}>
      {minimized ? (
        <Minimizer minimized={minimized} onClick={() => setMinimized(false)} />
      ) : (
        <OverlayScrollbarsComponent style={{ maxHeight: '100vh' }}>
          {children}
          {showMinimizer && <Minimizer minimized={minimized} onClick={() => setMinimized(true)} />}
        </OverlayScrollbarsComponent>
      )}
    </div>
  );
};

export default NavigationRight;
