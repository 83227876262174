import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button';
import Content from '../Content';
import Hidden from '../Hidden';
import NavigationRight from '../navigation/NavigationRight';
import RefreshButton from '../RefreshButton';
import SkeletonCards from '../SkeletonCards';

import vmIcon from '../icons/vm.svg';
import applicationIcon from '../icons/workspace.svg';

import useMediaQuery from '../../utilities/hooks/useMediaQuery';

import WorkspaceCard from './WorkspaceCard';
import WorkspaceDetails from './WorkspaceDetails';

import styles from './Overview.module.css';

const Overview = ({ useApplications, useVirtualMachines, useWorkspaces, vmLocalAdmin }) => {
  const { isDesktop } = useMediaQuery();
  const [selected, select] = useState();
  const [showAllApplications, setShowAllApplications] = useState(false);
  const [showAllVms, setShowAllVms] = useState(false);
  const {
    virtualMachines,
    fetchVms,
    isLoadingVms,
    refreshVm,
    setVirtualMachines,
    resize,
    setVmStar
  } = useVirtualMachines;

  const {
    applications,
    getApplications,
    isLoadingApplications,
    refreshApplication,
    starApplication
  } = useApplications;

  const {
    workspaces,
    isLoadingWorkspaces,
    refreshWorkspaces,
    updateWorkspace
  } = useWorkspaces;

  const getVirtualMachines = useCallback(() => fetchVms(), [fetchVms]);

  const refreshVirtualMachine = vm => refreshVm(vm, select);

  function handleSelect(name) {
    selected === name ? select(undefined) : select(name);
  }

  function unselect(event) {
    event.stopPropagation();
    select(undefined);
  }

  function star(event, item, items) {
    event.stopPropagation();
    const updated = [...items];
    const targetItem = items.find(i => i === item);
    targetItem.isStarred = !targetItem.isStarred;

    if (item.type === 'vm') {
      setVmStar(item.name, item.isStarred);
    }

    return updated;
  }

  function starVm(event, item) {
    const updated = star(event, item, virtualMachines);
    setVirtualMachines(updated);
  }

  const maxDisplayedAmount = isDesktop ? 3 : 2;
  const allItems = [...virtualMachines, ...applications, ...workspaces];

  return (
    <>
      <Content>
        <h2>Overview</h2>
        <h4 className={styles.description}>
          Your workspaces
          <RefreshButton disabled={isLoadingWorkspaces} onClick={refreshWorkspaces} />
        </h4>
        <div className={styles.flex}>
          {workspaces?.map(item =>
            <WorkspaceCard
              image={<img alt="Workspace" height="48" src={item.icon} />}
              isSelected={selected === item.name}
              item={item}
              key={item.name}
              onClick={() => handleSelect(item.name)}
              unselect={unselect}
            />
          )}
        </div>
        <h4 className={styles.description}>
          Your virtual machines
          <RefreshButton disabled={isLoadingVms} onClick={() => getVirtualMachines()} />
        </h4>
        {isLoadingVms ?
          <div className={styles.flex}>
            <SkeletonCards />
          </div> :
          <div className={styles.flex}>
            {virtualMachines
              .sort((a, b) => (a.dswName.localeCompare(b.dswName)))
              .sort((a, b) => b.isStarred - a.isStarred)
              .slice(0, showAllVms ? virtualMachines.length : maxDisplayedAmount)
              .map(item => (
                <WorkspaceCard
                  image={<img alt="Virtual machine" height="48" src={vmIcon} />}
                  isSelected={selected === item.name}
                  item={item}
                  key={item.name}
                  onClick={() => handleSelect(item.name)}
                  star={event => starVm(event, item)}
                  unselect={unselect}
                />
              ))}
            {!isLoadingVms && virtualMachines.length === 0 && (
              <div className={styles.empty}>
                You have no available virtual machines.
                Click <Link to="/data-science/vms/create">here</Link> to create a new VM.
              </div>
            )}
          </div>
        }
        <div className={styles.flexCentered}>
          {!showAllVms && virtualMachines.length > maxDisplayedAmount &&
            <Button color="secondary" onClick={() => setShowAllVms(true)}>
              Show all ({virtualMachines.length})
            </Button>
          }
        </div>

        <h4 className={styles.description}>
          Published Data Science Applications
          <RefreshButton onClick={() => getApplications()} />
        </h4>
        {isLoadingApplications ?
          <div className={styles.flex}>
            <SkeletonCards />
          </div> :
          <div className={styles.flex}>
            {applications
              .sort((a, b) => (a.name.localeCompare(b.name)))
              .sort((a, b) => b.isStarred - a.isStarred)
              .slice(0, showAllApplications ? applications.length : maxDisplayedAmount)
              .map(item => (
                <WorkspaceCard
                  image={<img alt={item.type} height="48" src={applicationIcon} />}
                  isSelected={selected === item.name}
                  item={item}
                  key={item.name}
                  onClick={() => handleSelect(item.name)}
                  star={event => starApplication(event, item)}
                  unselect={unselect}
                />
              ))}
          </div>
        }
        <div className={styles.flexCentered}>
          {!showAllApplications && applications.length > maxDisplayedAmount &&
            <Button color="secondary" onClick={() => setShowAllApplications(true)}>
              Show all ({applications.length})
            </Button>
          }
          {showAllApplications &&
            <Button color="secondary" onClick={() => setShowAllApplications(false)}>
              Show less
            </Button>
          }
        </div>
      </Content>
      <Hidden mobile>
        <NavigationRight>
          <WorkspaceDetails
            item={allItems.find(x => x.name === selected)}
            refreshApplication={refreshApplication}
            refreshVm={refreshVirtualMachine}
            refreshWorkspaces={refreshWorkspaces}
            resizeVm={resize}
            select={select}
            updateAppList={getApplications}
            updateVmList={getVirtualMachines}
            updateWorkspace={updateWorkspace}
            vmLocalAdmin={vmLocalAdmin}
          />
        </NavigationRight>
      </Hidden>
    </>
  );
};

export default Overview;
