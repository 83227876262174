import React from 'react';
import Label from '../../Label';
import styles from '../../DetailsPanel.module.css';

const WorkspaceHelp = ({ item, url }) => (
  <div className={styles.column}>
    <h2 className={styles.header}>
      <img alt="Icon" className={styles.icon} height="32" src={item.icon} />
      {item.tags?.dswname}
    </h2>
    <span className={styles.details}>
      <Label>Introduction</Label>
      This is a placeholder for {item.tags?.dswname} help material.

      {url &&
        <>
          <Label>Links</Label>
          <a className={styles.details} href={url} rel="noopener noreferrer" target="_blank">
            Open {item.tags?.dswname} session in a new tab
          </a>
        </>
      }
    </span>
  </div>
);

export default WorkspaceHelp;
