import React, { useState } from 'react';

import { round } from '../../utilities/formatting';

import Label from '../Label';
import styles from './VmCost.module.css';

const VmCost = ({ price }) => {
  const [active, setActive] = useState('hour');
  if (price === undefined) {
    return false;
  }

  return (
    <>
      <Label>
        <span className={active === 'hour' ? styles.active : styles.link} onClick={() => setActive('hour')}>Hourly</span>
        {' / '}
        <span className={active === 'day' ? styles.active : styles.link} onClick={() => setActive('day')}>Daily</span>
        {' / '}
        <span className={active === 'week' ? styles.active : styles.link} onClick={() => setActive('week')}>Weekly</span>
        {' '}
        cost
      </Label>
      {price === 0 ?
        <span>Not available</span> :
        <>
          {active === 'hour' && <span>{round(price)}€</span>}
          {active === 'day' && <span>{round(price * 24)}€</span>}
          {active === 'week' && <span>{round(price * 24 * 7)}€</span>}

        </>
      }
    </>
  );
};

export default VmCost;
