import React from 'react';
import styles from './Content.module.css';

const Content = ({ children, style }) => (
  <div className={styles.content} style={style}>
    {children}
  </div>
);

export default Content;
