import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Content from '../../Content';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';
import RefreshButton from '../../RefreshButton';
import WorkspaceCard from '../WorkspaceCard';
import WorkspaceDetails from '../WorkspaceDetails';

import styles from '../Overview.module.css';

import Workspace from './Workspace';

const WorkspaceOverview = ({ useWorkspaces }) => {
  const [selected, select] = useState();

  const {
    workspaces,
    isLoadingWorkspaces,
    refreshWorkspaces,
    updateWorkspace
  } = useWorkspaces;

  function unselect(event) {
    event.stopPropagation();
    select(null);
  }

  return (
    <>
      <Content>
        <h2>Workspaces</h2>
        <h4 className={styles.description}>
          Your workspaces
          <RefreshButton disabled={isLoadingWorkspaces} onClick={refreshWorkspaces} />
        </h4>
        <div className={styles.flex}>
          {workspaces.map(item =>
            <WorkspaceCard
              image={<img alt="Workspace" height="48" src={item.icon} />}
              isSelected={selected === item.name}
              item={item}
              key={item.name}
              onClick={() => select(item.name)}
              unselect={unselect}
            />
          )}
        </div>
      </Content>
      <Hidden mobile>
        <NavigationRight>
          <WorkspaceDetails
            item={workspaces.find(x => x.name === selected)}
            refreshWorkspaces={refreshWorkspaces}
            select={select}
            updateWorkspace={updateWorkspace}
          />
        </NavigationRight>
      </Hidden>
    </>
  );
};

const Workspaces = ({ useWorkspaces }) => (
  <Switch>
    <Route
      exact
      path="/data-science/workspaces"
      render={() => <WorkspaceOverview useWorkspaces={useWorkspaces} />}
    />
    <Route
      exact
      path="/data-science/workspaces/rstudio"
      render={() => <Workspace item={useWorkspaces.workspaces[0]} useWorkspaces={useWorkspaces} />}
    />
    <Route
      exact
      path="/data-science/workspaces/jupyter"
      render={() => <Workspace item={useWorkspaces.workspaces[1]} useWorkspaces={useWorkspaces} />}
    />
    <Redirect to="/data-science" />
  </Switch>
);

export default Workspaces;
