import React from 'react';

import styles from './Switch.module.css';

const Switch = props => (
  <div className={styles.container}>
    <label className={styles.switch}>
      <input type="checkbox" {...props} />
      <span className={styles.slider} />
    </label>
    <label className={styles.label}>
      {props.label || (props.checked === true ? 'Enabled' : 'Disabled')}
    </label>
  </div>
);

export default Switch;
