import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { union, without } from 'lodash';
import { getUserSettings, updateUserSettings } from '../utilities/api';

export const fetchUserSettings = createAsyncThunk('settings/fetchUserSettings', async () => {
  const settings = await getUserSettings();
  return settings;
});

export const saveUserSettings = createAsyncThunk(
  'settings/saveUserSettings',
  async (newSettings) => {
    const response = await updateUserSettings(newSettings);
    const settings = await response.json();
    return settings;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    favoriteProjects: [],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    starredVms: [],
    starredApps: []
  },
  reducers: {
    update: (state, action) => ({ ...state, ...action.payload }),
    setAppStar: (state, action) => {
      if (action.payload.isStarred === true) {
        return { ...state, starredApps: union(state.starredApps, [action.payload.name]) };
      }

      if (action.payload.isStarred === false) {
        return { ...state, starredApps: without(state.starredApps, action.payload.name) };
      }

      return state;
    },
    setVirtualMachineStar: (state, action) => {
      if (action.payload.isStarred === true) {
        return { ...state, starredVms: union(state.starredVms, [action.payload.name]) };
      }

      if (action.payload.isStarred === false) {
        return { ...state, starredVms: without(state.starredVms, action.payload.name) };
      }

      return state;
    }
  },
  extraReducers: {
    [fetchUserSettings.fulfilled]: (state, action) => ({ ...state, ...action.payload }),
    [saveUserSettings.fulfilled]: (state, action) => ({ ...state, ...action.payload })
  }
});

export const { update, setAppStar, setVirtualMachineStar } = settingsSlice.actions;

export default settingsSlice.reducer;
