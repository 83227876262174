import { configureStore } from '@reduxjs/toolkit';

import permissions from './permissionsSlice';
import settings from './settingsSlice';
import user from './userSlice';

export default configureStore({
  reducer: {
    permissions,
    settings,
    user
  }
});
