import React from 'react';

import styles from './RequestWorkspace.module.css';

const Disabled = ({ disabled, vmLimitPerUser }) => {
  if (disabled) {
    return (
      <span className={styles.errorMessage}>
        You can have {vmLimitPerUser} virtual machines at a time.
        Delete an existing virtual machine in order to create a new one.
      </span>
    );
  }

  return null;
};

export default Disabled;
