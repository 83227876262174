import React, { useState } from 'react';

import {
  enableRootAccess,
  disableRootAccess,
  enableSsh,
  disableSsh,
  setIdleShutdown
} from '../../../utilities/api';
import useToaster from '../../../utilities/hooks/useToaster';

import Button from '../../Button';
import FormLabel from '../../FormLabel';
import Switch from '../../Switch';
import FormItem from '../../FormItem';
import Spinner from '../../Spinner';

import Modal, { Header, Body, Actions } from '../../modals/Modal';

import UpdateSshKey from './UpdateSshKey';
import AddUser from './AddUser';
import VmUsers from './VmUsers';

import styles from './VmSettings.module.css';

const VmSettings = ({ close, item, admin, vmLocalAdmin, isModalOpen, refresh }) => {
  const isRunningVm = item?.type === 'vm' && item?.status?.state === 'available';

  const { addErrorToast, addSuccessToast } = useToaster();

  const [isUpdatingSudo, setIsUpdatingSudo] = useState(false);
  const [isUpdatingSshKey, setIsUpdatingSshKey] = useState(false);
  const [isUpdateSshKeyOpen, setIsUpdateSshKeyOpen] = useState(false);

  const updateVmSetting = async (event, name, enable, disable, tagName) => {
    const enabled = event.target.checked;

    try {
      if (enabled) {
        addSuccessToast(`Enabling ${name}`);
        item.tags[tagName] = 'true';
        await enable(item.name);
      } else {
        addSuccessToast(`Disabling ${name}`);
        item.tags[tagName] = 'false';
        await disable(item.name);
      }
    } catch (err) {
      console.log(err);
      addErrorToast(`Updating ${name} failed`);
    }
  };

  const setVmRootAccess = async (event) => {
    setIsUpdatingSudo(true);
    await updateVmSetting(event, 'root access', enableRootAccess, disableRootAccess, 'sudo');
    setIsUpdatingSudo(false);
  };

  const setVmSshAccess = (event) =>
    updateVmSetting(event, 'SSH access', enableSsh, disableSsh, 'ssh');

  const setVmIdleShutdownStatus = (event) =>
    updateVmSetting(
      event,
      'idle shutdown',
      (vm) => setIdleShutdown(vm, true),
      (vm) => setIdleShutdown(vm, false),
      'disble_idle_shutdown'
    );

  if (!isModalOpen) {
    return null;
  }

  const AdminSettings = () => (
    <>
      <FormItem>
        <FormLabel>Root access</FormLabel>
        <Switch
          checked={item?.tags?.sudo !== 'false'}
          disabled={isUpdatingSudo}
          onChange={setVmRootAccess}
        />
      </FormItem>
      <FormItem>
        <FormLabel>SSH</FormLabel>
        <Switch checked={item?.tags?.ssh !== 'false'} onChange={setVmSshAccess} />
      </FormItem>
      <FormItem>
        <FormLabel>Idle shutdown</FormLabel>
        <Switch
          checked={item?.tags?.disble_idle_shutdown !== 'false'}
          onChange={setVmIdleShutdownStatus}
        />
      </FormItem>
    </>
  );

  return (
    <Modal close={close}>
      <Header>
        Settings for {item.dswName} {item.name}
      </Header>
      <Body>
        {(admin || vmLocalAdmin) && isRunningVm && item.tags?.sudo && <AdminSettings />}

        <FormItem>
          <FormLabel>SSH keys</FormLabel>
          {isRunningVm && !isUpdateSshKeyOpen && (
            <div className={styles.buttonWithSpinner}>
              <Button disabled={isUpdatingSshKey} onClick={() => setIsUpdateSshKeyOpen(true)}>
                Update my SSH key
              </Button>
              {isUpdatingSshKey && <Spinner />}
            </div>
          )}

          {isUpdateSshKeyOpen && (
            <UpdateSshKey
              close={() => setIsUpdateSshKeyOpen(false)}
              setIsUpdating={setIsUpdatingSshKey}
              vmName={item.name}
            />
          )}
        </FormItem>

        {item.tags.multiUser === 'true' && (
          <FormItem>
            <FormLabel>Users</FormLabel>
            <VmUsers item={item} />
            <AddUser item={item} refresh={refresh} />
          </FormItem>
        )}
      </Body>
      <Actions>
        <Button onClick={close}>Close</Button>
      </Actions>
    </Modal>
  );
};

export default VmSettings;
