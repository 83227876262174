import React from 'react';
import ActionButton from './ActionButton';
import styles from './DetailsPanelActions.module.css';

const DetailsPanelActions = ({ actions, item, multiple }) => {
  const applicableActions = multiple ? actions.multipleFiles : item.isDirectory ? actions.folder : actions.singleFile;
  return (
    <div className={styles.actions}>
      {applicableActions.filter(x => !x.disabled).map(action => (
        <ActionButton icon={action.icon} key={action.name} name={action.name} onClick={action.action} />
      ))}
    </div>
  );
};

export default DetailsPanelActions;
