import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.css';
import { ReactComponent as Home } from './icons/home.svg';
import { ReactComponent as NavigateNext } from './icons/navigate_next.svg';

const Separator = () => <NavigateNext className={styles.separator} />;
const noop = () => {};

const Breadcrumb = ({ prefix = '/data-sharing', route, clickHandler = noop }) => {
  const ellipsis = { path: '…', name: '…', disabled: true };

  const path = route.length < 4
    ? route
    : [route[0], ellipsis, route[route.length - 2], route[route.length - 1]];

  const Item = ({ item }) => (
    <>
      {item.disabled
        ? <span>{item.name}</span>
        : (
          <Link onClick={e => clickHandler(e, item)} to={encodeURI(`${prefix}${item.path}`)}>
            <span>{item.name}</span>
          </Link>
        )
      }
      <Separator />
    </>
  );

  return (
    <span className={styles.breadcrumb}>
      <Link to={prefix}>
        <Home className={styles.home} onClick={e => clickHandler(e, { path: '' })} />
      </Link>
      <Separator />
      {path.map(item => <Item item={item} key={item.path} />)}
    </span>
  );
};

export default Breadcrumb;
