import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Button from '../../Button';
import FormItem from '../../FormItem';

import { updateSshKey } from '../../../utilities/api';
import { validateSshKey } from '../../../utilities/utils';
import useToaster from '../../../utilities/hooks/useToaster';

import formStyles from '../../form.module.css';

import styles from '../../modals/Modal.module.css';

const UpdateSshKey = ({ close, setIsUpdating, vmName }) => {
  const { addErrorToast, addSuccessToast } = useToaster();
  const { register, handleSubmit, errors } = useForm();
  const user = useSelector(state => state.user);

  const updateKey = async (data) => {
    close();

    try {
      setIsUpdating(true);
      await updateSshKey(vmName, data.sshKey, user.username);
      addSuccessToast('SSH key updated');
    } catch (err) {
      addErrorToast('SSH key update failed');
      console.log(err);
    }

    setIsUpdating(false);
  };

  return (
    <form onSubmit={handleSubmit(updateKey)}>
      <FormItem>
        {errors.sshKey && (
          <div className={`${styles.error} ${styles.marginBottom}`}>
            SSH key should be in single line format <strong>ssh-rsa AABBcc...</strong>
          </div>
        )}
        <textarea
          className={formStyles.textarea}
          name="sshKey"
          placeholder="SSH public key"
          ref={register({ validate: validateSshKey })}
          rows="10"
        />
      </FormItem>
      <div className={formStyles.actions}>
        <Button color="secondary" onClick={close} type="button">Cancel</Button>
        <Button color="primary" type="submit">Update key</Button>
      </div>
    </form>
  );
};

export default UpdateSshKey;
