import React from 'react';

import styles from './Spinner.module.css';

const Spinner = () => (
  <div className={styles.loading}>
    <img alt="Loading" src="/icons/loading.svg" />
  </div>
);

export default Spinner;
