import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as DataScience } from '../../components/icons/data-science.svg';
import { ReactComponent as DataSharing } from '../../components/icons/data-sharing.svg';
import { ReactComponent as Feedback } from '../../components/icons/feedback.svg';

import AccountInfo from '../AccountInfo';
import { ReactComponent as Back } from '../icons/back.svg';

import styles from './NavigationLeft.module.css';

const NavigationItem = ({ children, to, title }) => (
  <Link to={to}>
    <div className={styles.navigationItem} title={title}>
      {children}
    </div>
  </Link>
);

const NavigationLeft = ({ accountInfo, children, logout, permissions }) => {
  const { pathname } = window.location;
  const { user: dataScienceUser, restrictedAccess } = permissions.dataScience;
  const { user: dataSharingUser } = permissions.dataSharing;

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.content}>{children}</div>
        <div className={styles.navigation}>
          <div className={styles.copyright}>
            Version: v1.1.8 20240117.1
            <br />
            Copyright © Orion 2023
          </div>
          {pathname !== '/' && (
            <NavigationItem title="Home" to="/">
              <Back />
            </NavigationItem>
          )}
          {dataSharingUser && !restrictedAccess && !pathname.startsWith('/data-sharing') && (
            <NavigationItem to="/data-sharing">
              <DataSharing />
              Data Sharing
            </NavigationItem>
          )}
          {dataScienceUser && !pathname.startsWith('/data-science') && (
            <NavigationItem to="/data-science">
              <DataScience />
              Data Science
            </NavigationItem>
          )}
          {!pathname.startsWith('/feedback') && (
            <NavigationItem to="/feedback">
              <Feedback />
              Give feedback
            </NavigationItem>
          )}
        </div>
        {accountInfo && <AccountInfo accountInfo={accountInfo} logout={logout} />}
      </div>
    </div>
  );
};

export default NavigationLeft;
