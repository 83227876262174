import React from 'react';
import styles from './StatusIcon.module.css';

const blinkingStates = ['starting', 'stopping', 'updating'];

const StatusIcon = ({ status }) => (
  <svg className={styles[status]} height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" id="status" r="12">
      {blinkingStates.includes(status) &&
        <animate
          attributeName="opacity"
          dur="2s"
          repeatCount="indefinite"
          values="1;0.5;0;0.5;1"
        />
      }
    </circle>
  </svg>
);

export default StatusIcon;
