import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Home from '../pages/Home';
import DataScience from '../pages/DataScience';
import DataSharing from '../pages/DataSharing';
import DesignSystem from '../pages/DesignSystem';
import Account from '../pages/Account';
import Feedback from '../pages/Feedback';

import AppContext from '../context/App';

const ProtectedRoute = (props) => {
  const { permissions } = props;
  return permissions ?
    <Route {...props} /> :
    <Redirect to="/" />;
};

const Router = ({ accountInfo, logout, mobileMenuOpen, permissions, setMobileMenuOpen }) => {
  const isDataScienceUser = permissions.dataScience?.user;
  const isDataSharingUser = permissions.dataSharing?.user;

  return (
    <AppContext.Provider value={{ accountInfo, logout, mobileMenuOpen, permissions, setMobileMenuOpen }}>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <ProtectedRoute exact path="/account" permissions={isDataSharingUser || isDataScienceUser}>
          <Account />
        </ProtectedRoute>
        <ProtectedRoute exact path="/design-system" permissions={isDataSharingUser || isDataScienceUser}>
          <DesignSystem />
        </ProtectedRoute>
        <ProtectedRoute exact path="/feedback" permissions={isDataSharingUser || isDataScienceUser}>
          <Feedback />
        </ProtectedRoute>
        <ProtectedRoute path="/data-science" permissions={isDataScienceUser}>
          <DataScience />
        </ProtectedRoute>
        <ProtectedRoute path="/data-sharing" permissions={isDataSharingUser}>
          <DataSharing />
        </ProtectedRoute>
        <Redirect from="*" to="/" />
      </Switch>
    </AppContext.Provider>
  );
};

export default Router;
