import React from 'react';
import styles from './Button.module.css';

const Button = ({ children, color, disabled, id, onClick, style, title, type }) => (
  <button
    className={`${styles.button} ${color ? styles[color] : styles.primary}`}
    disabled={disabled}
    id={id}
    onClick={onClick}
    style={style}
    title={title}
    type={type}
  >
    {children}
  </button>
);

export default Button;
