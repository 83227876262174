import { useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from "../../Button";
import FormItem from '../../FormItem';
import FormLabel from '../../FormLabel';
import Spinner from '../../Spinner';
import SearchUsers from '../../SearchUsers';

import { addUser, searchUsers } from '../../../utilities/api';
import useToaster from '../../../utilities/hooks/useToaster';
import { validateSshKey } from '../../../utilities/utils';

import formStyles from '../../form.module.css';

import { getVmUsers } from './utils';

import styles from './VmSettings.module.css';

const AddUser = ({ item, refresh }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { addErrorToast, addSuccessToast } = useToaster();
  const { register, handleSubmit, setValue, errors, reset } = useForm();

  const users = getVmUsers(item);

  const onSubmit = async (data) => {
    setIsUpdating(true);
    setIsFormOpen(false);

    try {
      await addUser(item.name, data.upn, data.sshKey, data.username);
      addSuccessToast('User added');
    } catch (err) {
      console.error(err);
      addErrorToast(`Adding user failed: ${err}`);
    }

    setIsUpdating(false);
    refresh();
  };

  const handleChange = ({ accountName, upn }) => {
    setValue('upn', upn);
    setValue('username', accountName);
  };

  const onReset = () => {
    reset();
    setIsFormOpen(false);
  };

  if (item.tags.multiUser !== 'true') {
    return null;
  }

  if (!isFormOpen) {
    return (
      <div className={styles.buttonWithSpinner}>
        <Button disabled={isUpdating} onClick={() => setIsFormOpen(true)}>Add user</Button>
        {isUpdating && <Spinner />}
      </div>
    );
  };

  const validateKey = sshKey => sshKey === '' || validateSshKey(sshKey);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem>
        <FormLabel>User to add</FormLabel>
        <SearchUsers
          className={`${errors.upn && formStyles.error}`}
          defaultUser={{ name: '', userName: '' }}
          handleChange={handleChange}
          searchUsers={searchUsers}
        />
        <input
          name="upn"
          placeholder="firstname.lastname@orion.fi"
          ref={register({ required: true, validate: upn => !users.includes(upn) })}
          type="hidden"
        />
        <input
          name="username"
          ref={register({ required: true })}
          type="hidden"
        />
      </FormItem>
      <FormItem>
        <FormLabel>Add user&apos;s SSH public key (optional)</FormLabel>
        <textarea
          className={formStyles.textarea}
          name="sshKey"
          placeholder="SSH public key"
          ref={register({ validate: validateKey })}
          rows="10"
        />
      </FormItem>
      <div className={formStyles.formAction}>
        <Button color="primary" type="submit">Add user</Button>
        <Button color="secondary" onClick={onReset} type="button">Cancel</Button>
      </div>
    </form>
  );
};

export default AddUser;
