import React from 'react';

import FileTable from '../files/FileTable';
import { formatVersionTimestamp } from '../../utilities/formatting';

import Button from '../Button';
import Modal, { Header, Body, Actions } from './Modal';

const VersionHistory = (props) => {
  const { item, close, handleVersionRestore, handleGetFileVersion, handleVersionDelete } = props;
  const getItemVersions = () => {
    const versions = item.versions || [];
    const currentVersion = formatVersionTimestamp(item.lastModified);

    return versions
      .map(version => ({
        ...version,
        currentVersionName: item.name,
        versionName: version.name,
        name: version.name.split('#')[0],
        isCurrent: version.version === currentVersion
      }))
      .sort((a, b) => b.version - a.version);
  };

  const content = {
    items: getItemVersions()
  };

  const columns = [
    { key: 'fileType', name: 'Type', hideInHeaders: true },
    { key: 'name', name: 'Name', sortable: true },
    { key: 'creationTime', name: 'Created at', sortable: true },
    { key: 'last_modified_by_name', name: 'Created by user', sortable: true },
    { key: 'contentLength', name: 'Size', sortable: true },
    { key: 'action', name: 'Actions' }
  ];

  const actions = {
    singleFile: [
      { name: 'Restore version', icon: 'restore', action: handleVersionRestore },
      { name: 'Get exact version', icon: 'clipboard', action: handleGetFileVersion },
      { name: 'Delete version', icon: 'delete', action: handleVersionDelete }
    ]
  };

  return (
    <Modal close={close}>
      <Header>Version history: {item.name}</Header>
      <Body style={{ minHeight: '25vh' }}>
        <FileTable
          actions={actions}
          columns={columns}
          content={content}
          handleSelectOne={() => { }}
        />
      </Body>
      <Actions>
        <Button color="primary" onClick={close}>Close</Button>
      </Actions>
    </Modal>
  );
};

export default VersionHistory;
