import React from 'react';
import { Link } from 'react-router-dom';
import Content from '../../Content';

import styles from '../../datascience/admin/Admin.module.css';

const Dashboard = () => (
  <Content>
    <h2>
      Administration
    </h2>
    <h4 className={styles.description}>
      Dashboard
    </h4>
    <span className={styles.explanation}>
      Nothing here yet
    </span>
    <h4 className={styles.description}>
      Manage
    </h4>
    <span className={styles.explanation}>
      Administration content is divided into following sections:
    </span>
    <ul className={styles.links}>
      <li><Link to="/data-sharing/admin/new-project">Create new project</Link></li>
      <li><Link to="/data-sharing/admin/project-groups">Review AD groups assigned to projects</Link></li>
    </ul>
  </Content>
);

export default Dashboard;
