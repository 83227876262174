import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { encodePath } from '../utilities/utils';
import { formatSize } from '../utilities/formatting';
import { setStorageAuthCookie } from '../utilities/api';
import { saveUserSettings } from '../store/settingsSlice';

import Avatar from './Avatar';
import FileIcon from './files/FileIcon';
import FileAction from './files/FileAction';
import SvgIcon from './SvgIcon';
import DateTime from './DateTime';

import styles from './TableCellTypes.module.css';

const Star = SvgIcon('star');
const StarBorder = SvgIcon('starBorder');

function getFileType(file) {
  return file.isDirectory ? 'folder' : file.name.split('.').pop();
}

export const SelectCell = ({ row, selected, handleSelect }) => (
  <input checked={selected.includes(row)} onChange={() => handleSelect(row.name)} type="checkbox" />
);

export const FilenameCell = ({ col, row, handleNavigation, openPreview }) => {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);
  const isRoot = !row.fullPath.includes('/');

  // Used by MoveAndCopy dialog
  if (handleNavigation) {
    return (
      <div className={styles.filename} key={col} onClick={() => handleNavigation(row)}>
        <span>{row[col]}</span>
      </div>
    );
  }

  let path = encodePath(row.fullPath);

  if (row.version) {
    path = `${path.substring(0, path.lastIndexOf('#'))}?version=${row.version}`;
  }

  if (path.match(new RegExp('\\.pdf$', 'i'))) {
    const handleClick = (e) => {
      e.stopPropagation();
      openPreview(row);
    };

    return (
      <button
        className={styles.previewLink}
        onClick={handleClick}
        type="button"
      >
        {row[col]}
      </button>
    );
  }

  const handleStarClick = (e) => {
    e.stopPropagation();

    const { favoriteProjects } = settings;

    if (!favoriteProjects.includes(row.fullPath)) {
      dispatch(saveUserSettings({ ...settings, favoriteProjects: [...favoriteProjects, row.fullPath] }));
    } else {
      dispatch(saveUserSettings({ ...settings, favoriteProjects: favoriteProjects.filter(x => x !== row.fullPath) }));
    }
  };

  const StarButton = () => (
    <>
      {settings.favoriteProjects.includes(row.fullPath)
        ? (
          <span className={styles.star}>
            <Star onClick={handleStarClick} />
          </span>
        )
        : (
          <span className={`${styles.notStarred} ${styles.star}`}>
            <StarBorder onClick={handleStarClick} />
          </span>
        )
      }
    </>
  );

  return (
    <div className={styles.filename} key={col}>
      {row.isDirectory
        ? (
          <div>
            <Link className={styles.filename} to={`/data-sharing/${path}`}>{row[col]}</Link>
            {isRoot && <StarButton />}
          </div>
        )
        :
        <a
          className={styles.filename}
          download
          href={`/api/download/${path}`}
          onClick={setStorageAuthCookie}
        >
          {row[col]}
        </a>
      }
    </div>
  );
};

export const DateCell = ({ col, row }) => <DateTime date={row[col]} />;
export const SizeCell = ({ col, row }) => formatSize(row[col]);
export const TextCell = ({ col, row }) => row[col] ?? '';

export const ActionCell = ({ actions, row, handleSelectOne }) => (
  <FileAction
    actions={actions}
    handleSelectOne={handleSelectOne}
    item={row}
  />
);

export const AuthorCell = ({ col, row }) => {
  const name = row[col] ?? 'Unknown';
  return (
    <div className={styles.nowrap}>
      <Avatar name={name} />
      {name}
    </div>
  );
};

export const FileTypeCell = ({ row }) => (
  <FileIcon type={getFileType(row)} />
);
