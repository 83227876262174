import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';

import ConfirmWorkspaceDelete from '../../modals/ConfirmWorkspaceDelete';
import Label from '../../Label';
import PublishApp from '../../modals/PublishApp';
import Rename from '../../modals/Rename';
import Status from '../Status';
import ResizeVm from '../../modals/ResizeVm';

import actionStyles from '../../DetailsPanelActions.module.css';
import styles from '../../DetailsPanel.module.css';

import {
  startVM, stopVM, deleteVM, updateVmMetadata, getConfiguration, deleteApp,
  publishApp, listGroups, deleteWorkspace
} from '../../../utilities/api';
import useToaster from '../../../utilities/hooks/useToaster';
import { getAppGroups } from '../../../utilities/utils';
import CreateImage from '../../modals/CreateImage';
import VmSettings from '../VmSettings';

import VmActions from './VmActions';
import WorkspaceActions from './WorkspaceActions';
import AppActions from './AppActions';

import { CreationDate, Size, UserName, CostCenter, OwnerName, WbsCode, Image, Groups, Services } from './fields';

const WorkspaceDetails = (props) => {
  const {
    item, updateVmList, updateAppList, updateWorkspace, refreshVm, refreshApplication, refreshWorkspaces,
    select, admin, vmLocalAdmin, resizeVm
  } = props;

  const { addErrorToast, addSuccessToast } = useToaster();
  const [description, setDescription] = useState(item ? item.description : '');
  const [vmSizeDetails, setVmSizeDetails] = useState();
  const [groups, setGroups] = useState();
  const [isAppUpdating, setIsAppUpdating] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isCreateImage, setIsCreateImage] = useState(false);
  const [isVmSettingsOpen, setVmSettingsOpen] = useState(false);
  const history = useHistory();

  const itemIsApp = item?.type === 'app';
  const itemIsVm = item?.type === 'vm';
  const itemIsWorkspace = item?.type === 'workspace';

  useEffect(() => {
    setDescription(item?.description ?? '');
  }, [item]);

  useEffect(() => {
    const getConfigurationDetails = async () => {
      if (itemIsVm) {
        setVmSizeDetails(await getConfiguration(item.vmSize));
      }
    };
    const getGroups = async () => {
      if (itemIsApp) {
        const groups = await listGroups();
        setGroups(groups);
      }
    };
    getConfigurationDetails();
    getGroups();
  }, [itemIsApp, itemIsVm, item]);

  const updateVm = async (action, message) => {
    try {
      await action(item.name);
      addSuccessToast(message);
      updateVmList();
    } catch (err) {
      addErrorToast(err.message);
    }
  };

  const start = () => updateVm(startVM, `${item.dswName} is starting.`);
  const stop = () => updateVm(stopVM, `${item.dswName} is shutting down.`);

  const refresh = async () => {
    setRefreshing(true);

    itemIsWorkspace && await refreshWorkspaces();
    itemIsVm && await refreshVm(item);
    itemIsApp && refreshApplication(item);

    setRefreshing(false);
  };

  const confirmDelete = () => setIsDeleting(true);
  const cancelDelete = () => setIsDeleting(false);
  const handleWorkspaceDelete = async () => {
    setIsDeleting(false);
    select(undefined);

    try {
      if (itemIsVm) {
        await deleteVM(item.name);
        updateVmList();
        addSuccessToast(`Deleting VM: ${item.dswName}. This will take some time to complete.`);
      } else if (itemIsApp) {
        await deleteApp(item.name);
        await updateAppList();
        addSuccessToast(`Deleted application: ${item.name}.`);
      } else if (itemIsWorkspace) {
        await deleteWorkspace(item.name);
        await refreshWorkspaces();
        addSuccessToast(`Workspace deleted: ${item.name}. It will be re-created automatically on next usage`);
      } else {
        console.log('Delete failed. Unknown item type', item);
      }
    } catch (err) {
      addErrorToast(err.message);
    }
  };

  const saveMetadata = useRef(
    debounce(async (vmName, propertyName, value) => {
      try {
        if (value.length < 256) {
          addSuccessToast('Description updated');
          await updateVmMetadata(vmName, propertyName, value);
        } else {
          addErrorToast('Description too long (max. 256 characters)');
        }
      } catch (err) {
        console.log(err);
        addErrorToast(err.message);
      }
    }, 1000)
  );

  function handleDescriptionUpdate(e) {
    setDescription(e.target.value);
    item.description = e.target.value;
    saveMetadata.current(item.name, e.target.id, e.target.value);
  }

  async function handleRename(newName) {
    setIsRenaming(false);
    updateVmMetadata(item.name, 'dswName', newName); // takes a long time to run
    setTimeout(async () => {
      await updateVmList();
      addSuccessToast('Name updated');
    }, 2000);
  }

  async function handleAppUpdate(data) {
    setIsAppUpdating(false);

    try {
      addSuccessToast('Updating application');
      await publishApp(item.name, data.app.value, data.tag.value, item.tags.dswname, getAppGroups(data.groups));
      addSuccessToast('Application updated');
      updateAppList();
    } catch (err) {
      addErrorToast(`Application publishing failed: ${err.message}\n${err.body}`);
    }
  }

  const actionsDisabled = !(item?.owner || item?.user) && !admin;
  const status = item?.status?.state ?? 'updating';

  const itemIsAvailable = status === 'available';
  const itemIsFailed = status === 'failed';

  return item ? (
    <div className={styles.column}>
      <h2 className={styles.header}>
        {itemIsVm ?
          <span
            className={styles.renameable}
            onClick={() => setIsRenaming(true)}
            title="Click to rename"
          >
            {item.dswName || item.name}
          </span> :
          <span>
            {item.tags?.dswname || item.name}
          </span>
        }
      </h2>
      {item.type !== 'workspace' &&
        <h4 className={styles.altName}>
          {item.name}
        </h4>
      }
      <div className={styles.details}>
        <Label>Description</Label>
        <textarea
          className={styles.description}
          id="description"
          onChange={handleDescriptionUpdate}
          placeholder="Workspace description"
          readOnly={!itemIsVm}
          rows="6"
          value={description}
        />

        <Label>Status</Label>
        <Status status={status} statusDate={itemIsVm && item.statusDate} />

        {itemIsFailed && <div className={styles.statusMessage}>{item.status.message}</div>}

        <Services
          admin={admin}
          item={item}
          itemIsAvailable={itemIsAvailable}
          itemIsWorkspace={itemIsWorkspace}
          status={status}
        />

        <Size item={item} setIsResizing={setIsResizing} vmSizeDetails={vmSizeDetails} />
        <CreationDate item={item} />
        <OwnerName item={item} />
        <UserName item={item} />
        <CostCenter item={item} />
        <WbsCode item={item} />
        <Image item={item} />
        <Groups groups={groups} item={item} />
      </div>

      <div className={actionStyles.actions}>
        {itemIsWorkspace &&
          <WorkspaceActions
            actionsDisabled={actionsDisabled}
            confirmDelete={confirmDelete}
            history={history}
            item={item}
            refresh={refresh}
            refreshing={refreshing}
            status={status}
            updateWorkspace={updateWorkspace}
          />
        }
        {itemIsVm &&
          <VmActions
            actionsDisabled={actionsDisabled}
            confirmDelete={confirmDelete}
            createImage={() => setIsCreateImage(true)}
            refresh={refresh}
            refreshing={refreshing}
            settings={() => setVmSettingsOpen(true)}
            start={start}
            status={status}
            stop={stop}
          />
        }
        {itemIsApp &&
          <AppActions
            actionsDisabled={actionsDisabled}
            confirmDelete={confirmDelete}
            refresh={refresh}
            refreshing={refreshing}
            setIsAppUpdating={setIsAppUpdating}
            status={status}
          />
        }
      </div>
      {isDeleting &&
        <ConfirmWorkspaceDelete
          close={cancelDelete}
          confirm={handleWorkspaceDelete}
          item={item}
        />
      }
      {isRenaming &&
        <Rename close={() => setIsRenaming(false)} confirm={handleRename} item={item} />
      }
      {isAppUpdating &&
        <PublishApp
          close={() => setIsAppUpdating(false)}
          confirm={handleAppUpdate}
          displayName={item.tags?.dswname}
          groups={item.groups}
          image={item.image}
          imageTag={item.imageTag}
          name={item.name}
          updateApp
        />
      }
      {isResizing &&
        <ResizeVm
          close={() => setIsResizing(false)}
          item={item}
          resizeVm={resizeVm}
        />
      }
      {isCreateImage &&
        <CreateImage
          close={() => setIsCreateImage(false)}
          dswName={item.dswName}
          refresh={updateVmList}
          vmName={item.name}
        />
      }
      <VmSettings
        admin={admin}
        close={() => setVmSettingsOpen(false)}
        isModalOpen={isVmSettingsOpen}
        item={item}
        refresh={refresh}
        vmLocalAdmin={vmLocalAdmin}
      />
    </div>
  ) : <div />;
};

export default WorkspaceDetails;
