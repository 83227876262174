import { useCallback, useEffect, useState, useRef } from 'react';
import {
  getWorkspaces, getAllWorkspaces, startRStudio, startJupyter,
  updateWorkspace, updateAllWorkspaces
} from '../api';
import useToaster from '../../utilities/hooks/useToaster';

const useWorkspaces = (admin) => {
  const { addErrorToast, addSuccessToast } = useToaster();
  const addErrorToastRef = useRef(addErrorToast);
  const [isLoadingWorkspaces, setLoadingWorkspaces] = useState(false);
  const [workspaces, setWorkspaces] = useState(admin ? [] : [
    {
      name: 'RStudio',
      description: 'Quick workspace',
      status: { state: 'updating' },
      location: '/data-science/workspaces/rstudio',
      icon: '/icons/rstudio.svg',
      type: 'workspace',
      workspaceType: 'rstudio',
      owner: true,
      vmSize: 'Container',
      tags: {
        dswname: 'RStudio'
      },
      startWorkspace: startRStudio
    },
    {
      name: 'Jupyter Notebook',
      description: 'Quick workspace',
      status: { state: 'updating' },
      location: '/data-science/workspaces/jupyter',
      icon: '/icons/jupyter.svg',
      type: 'workspace',
      workspaceType: 'jupyter',
      owner: true,
      vmSize: 'Container',
      tags: {
        dswname: 'Jupyter Notebook'
      },
      startWorkspace: startJupyter
    }
  ]);

  const updateWorkspaceInfo = (list, updatedData) => {
    const workspace = list.find(x => x.workspaceType === updatedData.workspaceType);
    return {
      ...updatedData,
      tags: {
        ...workspace?.tags,
        ...updatedData?.tags
      },
      name: workspace?.name || updatedData.name,
      status: workspace?.status || { state: 'offline' }
    };
  };

  const refreshWorkspaces = useCallback(async () => {
    setLoadingWorkspaces(true);
    try {
      if (admin) {
        const list = await getAllWorkspaces();
        setWorkspaces(list);
      } else {
        const list = await getWorkspaces();
        setWorkspaces(workspaces => workspaces.map(workspace => updateWorkspaceInfo(list, workspace)));
      }
    } catch (err) {
      addErrorToastRef.current('Failed to retrieve workspaces');
      console.log(err);
    }

    setLoadingWorkspaces(false);
  }, [admin, addErrorToastRef]);

  useEffect(() => {
    refreshWorkspaces();
  }, [refreshWorkspaces]);


  const handleWorkspaceUpdate = async (name) => {
    try {
      await updateWorkspace(name);
      await refreshWorkspaces();
      addSuccessToast(`Workspace updated to latest image version: ${name}`);
    } catch (err) {
      addErrorToast('Updating workspace failed');
      console.log(err);
    }
  };

  const handleAllWorkspacesUpdate = async () => {
    try {
      await updateAllWorkspaces();
      await refreshWorkspaces();
      addSuccessToast('All workspaces updated to latest image version');
    } catch (err) {
      addErrorToast('Updating all workspaces failed');
      console.log(err);
    }
  };

  return {
    workspaces,
    isLoadingWorkspaces,
    refreshWorkspaces,
    updateWorkspace: handleWorkspaceUpdate,
    updateAllWorkspaces: handleAllWorkspacesUpdate
  };
};

export default useWorkspaces;
