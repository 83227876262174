import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button';
import Content from '../Content';
import Hidden from '../Hidden';
import NavigationRight from '../navigation/NavigationRight';
import RefreshButton from '../RefreshButton';
import SkeletonCards from '../SkeletonCards';
import useMediaQuery from '../../utilities/hooks/useMediaQuery';

import vmIcon from '../icons/vm.svg';

import WorkspaceCard from './WorkspaceCard';
import WorkspaceDetails from './WorkspaceDetails';

import styles from './Overview.module.css';

const VirtualMachines = ({ useVirtualMachines, vmLocalAdmin }) => {
  const { isDesktop } = useMediaQuery();
  const [selected, select] = useState();
  const [showAllVms, setShowAllVms] = useState(false);
  const {
    virtualMachines,
    fetchVms,
    isLoadingVms,
    refreshVm,
    setVirtualMachines,
    setVmStar,
    resize
  } = useVirtualMachines;

  const getVirtualMachines = useCallback(() => fetchVms(), [fetchVms]);

  const refreshVirtualMachine = vm => refreshVm(vm, select);

  function unselect(event) {
    event.stopPropagation();
    select(undefined);
  }

  function star(event, item, items) {
    event.stopPropagation();
    const updated = [...items];
    const targetItem = items.find(i => i === item);
    targetItem.isStarred = !targetItem.isStarred;

    if (item.type === 'vm') {
      setVmStar(item.name, item.isStarred);
    }

    return updated;
  }

  function starVm(event, item) {
    const updated = star(event, item, virtualMachines);
    setVirtualMachines(updated);
  }

  const maxDisplayedAmount = isDesktop ? 3 : 2;

  return (
    <>
      <Content>
        <h2>Virtual machines</h2>
        <h4 className={styles.description}>
          Your virtual machines
          <RefreshButton disabled={isLoadingVms} onClick={() => getVirtualMachines()} />
        </h4>
        {isLoadingVms ?
          <div className={styles.flex}>
            <SkeletonCards />
          </div> :
          <div className={styles.flex}>
            {virtualMachines
              .sort((a, b) => (a.dswName.localeCompare(b.dswName)))
              .sort((a, b) => b.isStarred - a.isStarred)
              .slice(0, showAllVms ? virtualMachines.length : maxDisplayedAmount)
              .map(item => (
                <WorkspaceCard
                  image={<img alt="Virtual machine" height="48" src={vmIcon} />}
                  isSelected={selected === item}
                  item={item}
                  key={item.name}
                  onClick={() => selected === item ? select(undefined) : select(item.name)}
                  star={event => starVm(event, item)}
                  unselect={unselect}
                />
              ))}
            {!isLoadingVms && virtualMachines.length === 0 && (
              <div className={styles.empty}>
                You have no available virtual machines.
                Click <Link to="/data-science/vms/create">here</Link> to create a new VM.
              </div>
            )}
          </div>
        }
        <div className={styles.flexCentered}>
          {!showAllVms && virtualMachines.length > maxDisplayedAmount &&
            <Button color="secondary" onClick={() => setShowAllVms(true)}>
              Show all ({virtualMachines.length})
            </Button>
          }
        </div>
      </Content>
      <Hidden mobile>
        <NavigationRight>
          <WorkspaceDetails
            item={virtualMachines.find(x => x.name === selected)}
            refreshVm={refreshVirtualMachine}
            resizeVm={resize}
            select={select}
            updateVmList={getVirtualMachines}
            vmLocalAdmin={vmLocalAdmin}
          />
        </NavigationRight>
      </Hidden>
    </>
  );
};

export default VirtualMachines;
