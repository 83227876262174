import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUser } from "../utilities/api";

export const fetchUser = createAsyncThunk('user/fetch', async () => {
  const user = await getUser();
  return user;
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    firstName: null,
    lastName: null,
    name: null,
    upn: null,
    username: null
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, action) => ({ ...state, ...action.payload })
  }
});

export default userSlice.reducer;
