import React, { useRef, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

import { searchFiles } from '../../utilities/api';

import styles from './Search.module.css';

const Search = ({ setSearchResults, clear, isSearchActive }) => {
  const [searchInput, setSearchInput] = useState('');
  const searchField = useRef(undefined);

  const debounceSearch = useRef(debounce(async (input) => {
    if (input !== '') {
      const results = await searchFiles(input);
      setSearchResults(results);
    }
  }, 500));

  const handleChange = (e) => {
    if (e.target.value !== '') {
      setSearchInput(e.target.value);
    } else if (isSearchActive) {
      clear();
    }
  };

  const handleClear = () => {
    searchField.current.value = "";
    clear();
  };

  useEffect(() => { debounceSearch.current(searchInput); }, [searchInput]);

  return (
    <div className={styles.search}>
      <input
        onChange={handleChange}
        placeholder="Search files"
        ref={searchField}
        type="text"
      />
      {searchField.current?.value.length > 0 &&
        <div className={styles.close} onClick={handleClear}>✕</div>}
    </div>
  );
};

export default Search;
