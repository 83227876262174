import React from 'react';
import Label from '../Label';
import styles from '../DetailsPanel.module.css';
import { formatVmSize } from '../../utilities/formatting';
import VmCost from './VmCost';

const VmDetails = ({ image, item }) => item ? (
  <div className={styles.column}>
    <h2 className={styles.header}>
      <img alt="Virtual machine" className={styles.icon} height="48" src={'/icons/vm.svg'} />
      <span className={styles.filename}>
          Details
      </span>
    </h2>
    <div className={styles.details}>
      <Label>Tier</Label>
      {item.tier}

      <Label>Size</Label>
      {formatVmSize(item.size)}

      <Label>Size description</Label>
      <span className={styles.limitedWidth}>{item.description ?? '(Not available)'}</span>

      <Label>Virtual CPUs</Label>
      {item.vCPUs}

      <Label>Memory</Label>
      {item.MemoryGB} GB
      {item.GPUs &&
          <>
            <Label>GPUs</Label>
            {item.GPUs}
          </>
      }

      <Label>VM image</Label>
      <div>{image?.name}</div>
      <div className={styles.vmImageField}>{image && !image?.isDefault && 'User created image'}</div>
      <div className={styles.vmImageField}>{image?.description}</div>

      <VmCost price={item.price} />
    </div>
  </div>
) : <div />;

export default VmDetails;
