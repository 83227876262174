import React from 'react';
import { Link } from 'react-router-dom';
import Content from '../../Content';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';

import { stringToHslColor } from '../../../utilities/colors';

import styles from './Admin.module.css';

const Widget = ({ name, count }) => {
  const backgroundColor = stringToHslColor(name);
  return (
    <div className={styles.widget}>
      <div className={styles.count} style={{ backgroundColor }}>{count === undefined ? '...' : count}</div>
      {name}
    </div>
  );
};

const Dashboard = ({ useWorkspaces, useApplications, useVirtualMachines }) => {
  const { workspaces } = useWorkspaces;
  const { applications } = useApplications;
  const { virtualMachines } = useVirtualMachines;

  const uniqueVmUsers = virtualMachines && [...new Set(virtualMachines.map(vm => vm.tags.owner_name))].length;

  return (
    <>
      <Content>
        <h2>Administration</h2>
        <h4 className={styles.description}>
          Dashboard
        </h4>
        <div className={styles.flex}>
          <Widget count={workspaces.filter(x => x.status?.state === 'available').length} name="Running workspaces" />
          <Widget count={workspaces.length} name="Total workspaces" />
          <Widget count={0} name="Workspaces that can be updated" />
          <Widget count={virtualMachines.filter(x => x.status?.state === 'available').length} name="Running VMs" />
          <Widget count={virtualMachines.length} name="Total VMs" />
          <Widget count={uniqueVmUsers} name="Unique VM users" />
          <Widget count={applications.filter(x => x.status?.state === 'available').length} name="Running applications" />
          <Widget count={applications.length} name="Total applications" />
          <Widget count={0} name="Apps that can be updated" />
        </div>
        <h4 className={styles.description}>
          Manage
        </h4>
        <span className={styles.explanation}>
          Administration content is divided into following sections:
        </span>
        <ul className={styles.links}>
          <li><Link to="/data-science/admin/workspaces">Workspaces</Link></li>
          <li><Link to="/data-science/admin/virtual-machines">Virtual machines</Link></li>
          <li><Link to="/data-science/admin/applications">Applications</Link></li>
          <li><Link to="/data-science/admin/images">Virtual machine images</Link></li>
          <li><Link to="/data-science/admin/settings">Settings</Link></li>
        </ul>
      </Content>
      <Hidden mobile>
        <NavigationRight />
      </Hidden>
    </>
  );
};

export default Dashboard;
