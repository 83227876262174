import React from 'react';
import ReactModal from 'react-modal';

import { ReactComponent as Close } from '../icons/close.svg';

import styles from './Modal.module.css';

ReactModal.setAppElement(document.getElementById('root'));

const Modal = ({ children, close, isOpen = true, className }) => (
  <ReactModal
    className={`${styles.modal} ${className}`}
    isOpen={isOpen}
    onRequestClose={close}
    overlayClassName={styles.overlay}
  >
    <button className={styles.close} onClick={close} title="Close" type="button">
      <Close />
    </button>
    {children}
  </ReactModal>
);

export const Header = ({ children }) => <div className={styles.header}>{children}</div>;

export const Body = ({ children, style }) => (
  <div className={styles.body} style={style}>
    {children}
  </div>
);

export const Actions = ({ children }) => (
  <div className={styles.actions}>
    {children}
  </div>
);

export default Modal;
