import React, { useState } from 'react';

import Content from '../../Content';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';
import PublishApp from '../../modals/PublishApp';
import RefreshButton from '../../RefreshButton';
import Spinner from '../../Spinner';
import WorkspaceDetails from '../WorkspaceDetails';
import WorkspaceTable from '../WorkspaceTable';

import useToaster from '../../../utilities/hooks/useToaster';
import { publishApp } from '../../../utilities/api';
import { getAppGroups } from '../../../utilities/utils';
import styles from './Admin.module.css';

const Applications = ({useApplications}) => {
  const [isPublishing, setIsPublishing] = useState(false);
  const [selected, select] = useState();
  const { addErrorToast, addSuccessToast } = useToaster();

  const {
    applications,
    getApplications,
    isLoadingApplications,
    refreshApplication
  } = useApplications;

  const handlePublish = async (data, name) => {
    try {
      addSuccessToast('Publishing app ...');
      await publishApp(name, data.app.value, data.tag.value, data.displayName, getAppGroups(data.groups));
      addSuccessToast('Deployment succeeded');
    } catch (err) {
      console.error(`${err.message}\n${err.body}`);
      addErrorToast(`Application publishing failed: ${err.message}\n${err.body}`);
    }
    getApplications();
    setIsPublishing(false);
  };

  const runningWs = applications.filter(x => x.status?.state === 'available').length;

  return (
    <>
      <Content>
        <h2>Administration</h2>
        <h4 className={styles.description}>
          Applications
          <RefreshButton disabled={isLoadingApplications} onClick={getApplications} />
        </h4>
        {isLoadingApplications ?
          <Spinner /> :
          <span className={styles.explanation}>
            There are currently {applications.length} published applications, {runningWs} of which are available.
          </span>
        }
        <WorkspaceTable
          isLoading={isLoadingApplications}
          items={applications}
          publish={() => setIsPublishing(true)}
          select={select}
          selected={selected}
        />
      </Content>
      <Hidden mobile>
        <NavigationRight>
          <WorkspaceDetails
            admin
            item={applications.find(x => x.name === selected)}
            refreshApplication={refreshApplication}
            refreshVm={() => {}}
            select={select}
            updateAppList={getApplications}
            updateVmList={() => {}}
          />
        </NavigationRight>
      </Hidden>
      {isPublishing &&
        <PublishApp close={() => setIsPublishing(false)} confirm={handlePublish} publishedApps={applications} />
      }
    </>
  );
};

export default Applications;
