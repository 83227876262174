import React, { useState, useEffect } from 'react';
import Select from 'react-select/creatable';

const toOption = label => ({ label, value: label });
const toOptions = values => values ? values.map(toOption) : [];
const toItemTags = options => options.map(item => item.value);

const Tags = ({ itemTags, handleTagsChange, disabled }) => {
  const [inputValue, setInputValue] = useState();
  const [values, setValues] = useState(toOptions(itemTags));

  useEffect(() => setValues(toOptions(itemTags)), [itemTags]);

  const handleChange = (value) => {
    setValues(value);
    handleTagsChange(toItemTags(value));
  };
  const handleInputChange = value => setInputValue(value);

  const handleKeyDown = (event) => {
    if (!inputValue) {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const updatedValues = [...values, toOption(inputValue)];
        setInputValue('');
        setValues(updatedValues);
        handleTagsChange(toItemTags(updatedValues));
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  return (
    <Select
      components={{ DropdownIndicator: null }}
      inputValue={inputValue}
      isClearable={false}
      isDisabled={disabled}
      isMulti
      menuIsOpen={false}
      onChange={value => handleChange(value ?? [])}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      value={values}
    />
  );
};

export default Tags;
