import React from 'react';
import Hidden from './Hidden';
import styles from './Header.module.css';

const Header = ({ mobileMenuOpen, setMobileMenuOpen }) => (
  <header className={styles.header}>
    <Hidden desktop>
      <button className={styles.headerMenu} onClick={() => setMobileMenuOpen(!mobileMenuOpen)} type="button">
        <img alt="Menu" src="/icons/menu.svg" />
      </button>
    </Hidden>
  </header >
);

export default Header;
