import React, { useEffect, useState } from 'react';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { Provider } from 'react-redux';

import App from './App';
import Loading from './pages/Loading';

import store from './store';
import * as api from './utilities/api';
import { getAuthProvider } from './utilities/authProvider';

const Authentication = () => {
  const [authProvider, setAuthProvider] = useState(undefined);
  const [authError, setAuthError] = useState();

  useEffect(() => {
    async function getConfig() {
      try {
        const data = await api.getAuthConfig();
        const provider = getAuthProvider(data.authority, data.clientId);

        setAuthProvider(provider);
        api.setAuthProvider(provider);
      } catch (err) {
        console.error(err);
        setAuthError('Failed to retrieve authentication configuration');
      }
    }
    getConfig();
  }, []);

  function logout() {
    window.localStorage.clear();
    document.cookie = 'accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;';
    window.location = '/';
  }

  return authProvider ? (
    <AzureAD forceLogin provider={authProvider}>
      {({ authenticationState, accountInfo }) => (
        <Provider store={store}>
          <App
            accountInfo={accountInfo}
            authenticated={authenticationState === AuthenticationState.Authenticated}
            getAccessToken={authProvider.getAccessToken}
            logout={logout}
          />
        </Provider>
      )}
    </AzureAD>
  ) : (
    <Loading error={authError} />
  );
};

export default Authentication;
