import { getVmUsers } from './utils';

import styles from './VmSettings.module.css';

const VmUsers = ({ item }) => {
  const vmUsers = getVmUsers(item);

  return (
    <ul className={styles.userList}>
      {vmUsers.map(user => (
        <li key={user}>{user}</li>
      ))}
    </ul>
  );
};

export default VmUsers;
