export function isTargetButtonOrLink(event) {
  return event.target instanceof HTMLAnchorElement || event.target instanceof HTMLButtonElement;
}

export function generateRoute(url) {
  const route = [];
  const pathArray = url.split('/');

  for (let i = pathArray.length; i > 1; i--) {
    const path = pathArray.join('/');
    const name = pathArray.pop();
    route.push({ path, name });
  }

  return route.reverse();
}

function withoutEndingSlash(path) {
  return path.replace(/\/$/, '');
}

export function getContainingFolder(path) {
  return withoutEndingSlash(path.match(/^.*\//)[0]);
}

export function getTags(item) {
  return item.projects ? JSON.parse(item.projects) : [];
}

export function encodePath(path) {
  return path
    .split('/')
    .map(component => encodeURI(component))
    .join('/');
}

export function extractTags(items) {
  const tags = items
    .map((item) => {
      const itemTags = getTags(item);
      return Array.isArray(itemTags) ? itemTags : [];
    })
    .flat();
  return [...new Set(tags)].sort();
}

export function camelize(str) {
  return str.replace(/^.|-./g, (letter, index) =>
    index === 0 ? letter.toLowerCase() : letter.substr(1).toUpperCase()
  );
}

export async function getFileFromEntry(fileEntry) {
  try {
    return await new Promise(resolve => fileEntry.file(resolve));
  } catch (err) {
    console.log(err);
  }
}

export async function readAllDirectoryEntries(reader) {
  const entries = [];
  let readEntries = await readDirectoryEntries(reader);

  // To get all the files, readEntries must be called until the array comes back empty
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readDirectoryEntries(reader);
  }
  return entries;
}

function readDirectoryEntries(reader) {
  try {
    return new Promise((resolve) => { reader.readEntries(resolve); });
  } catch (err) {
    console.log(err);
  }
}

export function getAppGroups(groups) {
  return groups ? groups.map(x => x.value) : [];
}

export function hasAccessToAllDomainUsers(groups) {
  return groups.length === 0;
}

export function validateSshKey(input) {
  if (input.match(/^ssh-(dsa|ecdsa|ecdsa-sk|ed25519|ed25519-sk|rsa)/)) {
    const key = input.match(/^ssh-[^\s]* ([^\s]*)/)[1];

    try {
      return btoa(atob(key)) === key;
    } catch (err) {
      return false;
    }
  }

  return false;
};
