import React, { useState } from 'react';
import useInterval from '../utilities/hooks/useInterval';

import styles from './Skeleton.module.css';

const SkeletonLinks = ({ amount = 5 }) => {
  const [shownAmount, setShownAmount] = useState(0);
  const [timeout, setTimeout] = useState(500);

  useInterval(() => {
    if (shownAmount < amount) {
      setShownAmount(x => x + 1);
    } else {
      setTimeout(0);
    }
  }, timeout);

  const list = [];
  for (let i = 0; i < shownAmount; i++) {
    list.push(<span className={styles.link} key={i} />);
  }
  return list;
};

export default SkeletonLinks;
