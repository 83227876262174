import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ActionCell,
  AuthorCell,
  DateCell,
  FilenameCell,
  TextCell,
  SelectCell,
  SizeCell,
  FileTypeCell
} from '../TableCellTypes';

import styles from '../Table.module.css';
import SkeletonRows from '../SkeletonRows';
import SortableHeader from '../SortableHeader';

import FileBrowserError from './FileBrowserError';

const Message = ({ children, columns }) => (
  <tr>
    <td colSpan={columns.length}>
      {children}
    </td>
  </tr>
);

const sortByFavorite = (favoriteProjects = []) => (a, b) => {
  const isFavorited = project => favoriteProjects.includes(project.fullPath);

  if (isFavorited(a) && !isFavorited(b)) {
    return -1;
  }

  if (!isFavorited(a) && isFavorited(b)) {
    return 1;
  }

  return 0;
};

const isTimeField = key => key === 'lastModified' || key === 'creationTime';

const FileTable = (props) => {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();
  const favoriteProjects = useSelector(state => state.settings.favoriteProjects);

  const {
    actions,
    content,
    columns,
    errorCode,
    path,
    selected,
    handleNavigation,
    handleSelect,
    handleSelectOne,
    handleSelectAll,
    navigationInProgress,
    openPreview
  } = props;

  useEffect(() => {
    if (content?.items) {
      setData([...content?.items].sort(sortByFavorite(favoriteProjects)));
    }
  }, [content, favoriteProjects]);

  function sortBy(key, order) {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const favorite = sortByFavorite(favoriteProjects)(a, b);

      if (favorite !== 0) {
        return favorite;
      }

      if (isTimeField(key)) {
        const result = new Date(a[key]) < new Date(b[key]) ? -1 : 1;
        return order === 'asc' ? result : -1 * result;
      }

      if (order === 'asc') {
        return a[key]?.localeCompare(b[key]);
      } else {
        return b[key]?.localeCompare(a[key]);
      }
    });
    setData(sortedData);
  }

  function handleSort(key) {
    if (orderBy === key) {
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      setOrder(newOrder);
      sortBy(key, newOrder);
    } else {
      setOrderBy(key);
      sortBy(key, order);
    }
  }

  function renderCell(col, row) {
    switch (col) {
      case 'select':
        return <SelectCell col={col} handleSelect={handleSelect} row={row} selected={selected} />;
      case 'fileType':
        return <FileTypeCell row={row} />;
      case 'name':
        return <FilenameCell col={col} handleNavigation={handleNavigation} openPreview={openPreview} row={row} />;
      case 'creationTime':
      case 'lastModified':
        return <DateCell col={col} handleSelect={handleSelect} row={row} />;
      case 'contentLength':
        return <SizeCell col={col} row={row} />;
      case 'action':
        return <ActionCell actions={actions} handleSelectOne={handleSelectOne} row={row} />;
      case 'author_name':
        return <AuthorCell col={col} row={row} />;
      case 'owner_name':
        return <AuthorCell col={col} row={row} />;
      default:
        return <TextCell col={col} handleSelect={handleSelect} row={row} />;
    }
  }

  const renderContent = () => {
    if (errorCode) {
      return (
        <Message columns={columns}>
          <FileBrowserError errorCode={errorCode} path={path} />
        </Message>
      );
    }

    if (content?.items.length === 0) {
      return (
        <Message columns={columns}>
          This folder doesn&apos;t contain any files or subfolders
        </Message>
      );
    }

    return data.map(row => (
      <tr className={selected?.includes(row) ? styles.selectedRow : null} key={row.fullPath}>
        {columns.filter(key => !key.hide).map(col =>
          <td className={styles[col.key]} key={col.key} onClick={() => handleSelectOne(row.name)}>
            {renderCell(col.key, row)}
          </td>
        )}
      </tr>
    ));
  };

  const renderHeader = (col) => {
    if (col.sortable) {
      return (
        <SortableHeader
          active={col.key === orderBy}
          className={styles[col.key]}
          key={col.key}
          name={col.name}
          onClick={() => handleSort(col.key)}
          order={order}
        />
      );
    } else if (col.key === 'select') {
      if (selected) {
        return (
          <th className={styles.select} key={col.key}>
            <input
              checked={content?.items?.length > 0 && content.items.length === selected.length}
              disabled={content?.items?.length === 0}
              onChange={handleSelectAll}
              type="checkbox"
            />
          </th>);
      }

      return null;
    } else if (col.hideInHeaders) {
      return <th className={styles[col.key]} key={col.key} />;
    }

    return <th className={styles[col.key]} key={col.key}>{col.name}</th>;
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.headers}>
          {columns.filter(key => !key.hide).map(renderHeader)}
        </tr>
      </thead>
      <tbody>
        {navigationInProgress ? <SkeletonRows columns={columns} /> : renderContent()}
      </tbody>
    </table>
  );
};

export default FileTable;
