import React from 'react';
import SvgIcon from './SvgIcon';
import styles from './ActionButton.module.css';

const ActionButton = ({ name, icon, disabled, onClick, dividerAfter }) => {
  const Icon = SvgIcon(icon);

  return (
    <button
      className={dividerAfter ? `${styles.actionButton} ${styles.dividerAfter}` : styles.actionButton}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <Icon />
      {name}
    </button>
  );
};

export default ActionButton;
