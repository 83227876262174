function getUserPermissions(groups, groupPermissions) {
  const permissions = { read: false, write: false };

  groups.forEach(({ id }) => {
    permissions.read = permissions.read || groupPermissions[id]?.read;
    permissions.write = permissions.write || groupPermissions[id]?.write;
  });

  return permissions;
}

export function setUserPermissionsToFiles(data, groups) {
  const { groupPermissions } = data.directory;

  data.directory.permissions = getUserPermissions(groups, groupPermissions);
  data.items
    .filter(item => item.isDirectory)
    .forEach((dir) => { dir.permissions = getUserPermissions(groups, dir.groupPermissions); });
}

export function filterDirectoriesWithoutReadPermission(data) {
  data.items = data.items.filter(item => !item.isDirectory || item.permissions.read);
}
