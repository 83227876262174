import React, { useEffect, useState, useRef } from 'react';

import { listAllImages, deleteImage } from '../../../utilities/api';
import useToaster from '../../../utilities/hooks/useToaster';

import Content from '../../Content';
import Button from '../../Button';
import Modal, { Header, Body, Actions } from '../../modals/Modal';

import styles from './Images.module.css';

const Image = ({ image, handleDelete }) => (
  <tr>
    <td>{image.name}</td>
    <td>{image.tags.owner}</td>
    <td>{image.tags.sharedImage === 'true' ? 'Yes' : 'No'}</td>
    <td><Button onClick={() => handleDelete(image)}>Delete</Button></td>
  </tr>
);

const Images = () => {
  const { addErrorToast, addSuccessToast } = useToaster();
  const [images, setImages] = useState([]);
  const [imageToDelete, setImageToDelete] = useState(undefined);

  const getImages = useRef(async () => {
    try {
      setImages(await listAllImages());
    } catch (err) {
      console.error('Failed to get images', err);
      addErrorToast('Failed to get images');
    }
  });

  const closeModal = () => setImageToDelete(undefined);

  const handleDelete = async () => {
    const imageName = imageToDelete.name;

    try {
      closeModal();
      addSuccessToast(`Deleting image ${imageName}`);
      await deleteImage(imageName);
      addSuccessToast(`Deleted image ${imageName}`);
    } catch (err) {
      console.error('Failed to get images', err);
      addErrorToast(`Failed to delete image ${imageName}`);
    }

    getImages.current();
  };

  useEffect(() => { getImages.current(); }, [getImages]);

  return (
    <Content>
      <h2>Virtual machine images</h2>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Image</th>
            <th>Owner</th>
            <th>Shared</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {images.map(image => (
            <Image
              handleDelete={setImageToDelete}
              image={image}
              key={image.id}
            />
          ))}
        </tbody>
      </table>

      {imageToDelete && (
        <Modal close={closeModal}>
          <Header>Delete image</Header>
          <Body>Delete image {imageToDelete.name}?</Body>
          <Actions>
            <Button color="secondary" onClick={closeModal}>Cancel</Button>
            <Button color="primary" onClick={handleDelete}>Delete</Button>
          </Actions>
        </Modal>
      )}
    </Content>
  );
};

export default Images;
