import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import GridContainer from '../components/GridContainer';
import Hidden from '../components/Hidden';
import LayoutProvider from '../context/LayoutProvider';
import Logo from '../components/Logo';
import MobileMenu from '../components/navigation/MobileMenu';
import NavigationLeft from '../components/navigation/NavigationLeft';
import NavigationList from '../components/navigation/NavigationList';
import RequestWorkspace from '../components/datascience/RequestWorkspace';
import Overview from '../components/datascience/Overview';
import VirtualMachines from '../components/datascience/VirtualMachines';
import Workspaces from '../components/datascience/workspaces/Workspaces';

import useApplications from '../utilities/hooks/useApplications';
import useVirtualMachines from '../utilities/hooks/useVirtualMachines';
import useWorkspaces from '../utilities/hooks/useWorkspaces';

import AppContext from '../context/App';

import DataScienceAdmin from './DataScienceAdmin';

const Navigation = ({ accountInfo, logout, permissions }) => {
  const { restrictedAccess, admin } = permissions.dataScience;
  const items = [];

  if (!restrictedAccess) {
    items.push({ value: '/data-science', label: 'Overview' });
  }

  items.push(
    {
      value: '/data-science/workspaces',
      label: 'Workspaces',
      items: [
        { value: '/data-science/workspaces/rstudio', label: 'RStudio' },
        { value: '/data-science/workspaces/jupyter', label: 'Jupyter Notebook' }
      ]
    }
  );

  if (!restrictedAccess) {
    items.push({
      value: '/data-science/vms',
      label: 'Virtual machines',
      items: [
        { value: '/data-science/vms/create', label: 'Create virtual machine' }
      ]
    });
  }

  if (admin) {
    items.push({
      value: '/data-science/admin',
      label: 'Administration',
      items: [
        { value: '/data-science/admin/workspaces', label: 'Workspaces' },
        { value: '/data-science/admin/virtual-machines', label: 'Virtual machines' },
        { value: '/data-science/admin/applications', label: 'Applications' },
        { value: '/data-science/admin/images', label: 'Virtual machine images' },
        { value: '/data-science/admin/settings', label: 'Settings' }
      ]
    });
  }

  return (
    <NavigationLeft accountInfo={accountInfo} logout={logout} permissions={permissions}>
      <Logo id="data-science" label="Data Science" />
      <NavigationList centered items={items.filter(x => x)} />
    </NavigationLeft>
  );
};

const DataScienceRoutes = ({ permissions }) => {
  const { vmLocalAdmin, admin } = permissions.dataScience;

  const workspaces = useWorkspaces();
  const virtualMachines = useVirtualMachines();
  const applications = useApplications();

  return (
    <Switch>
      <Route exact path="/data-science">
        <Overview
          useApplications={applications}
          useVirtualMachines={virtualMachines}
          useWorkspaces={workspaces}
          vmLocalAdmin={vmLocalAdmin}
        />
      </Route>
      <Route exact path="/data-science/vms">
        <VirtualMachines useVirtualMachines={virtualMachines} vmLocalAdmin={vmLocalAdmin} />
      </Route>
      <Route exact path="/data-science/vms/create">
        <RequestWorkspace useVirtualMachines={virtualMachines} />
      </Route>
      <Route path="/data-science/workspaces">
        <Workspaces useWorkspaces={workspaces} />
      </Route>
      {admin && <DataScienceAdmin />}
      <Redirect to="/data-science" />
    </Switch>
  );
};

const DataScienceRoutesRestrictedAccess = () => {
  const workspaces = useWorkspaces();

  return (
    <Switch>
      <Route path="/data-science/workspaces">
        <Workspaces useWorkspaces={workspaces} />
      </Route>
      <Redirect to="/data-science/workspaces" />
    </Switch>
  );
};

const DataScience = () => {
  const { accountInfo, logout, mobileMenuOpen, permissions, setMobileMenuOpen } = useContext(AppContext);
  const routes = permissions.dataScience?.restrictedAccess
    ? <DataScienceRoutesRestrictedAccess />
    : <DataScienceRoutes permissions={permissions} />;

  return (
    <LayoutProvider>
      <GridContainer>
        <Hidden mobile>
          <Navigation accountInfo={accountInfo} logout={logout} permissions={permissions} />
        </Hidden>
        <Hidden desktop>
          <MobileMenu close={() => setMobileMenuOpen(false)} open={mobileMenuOpen}>
            <Navigation accountInfo={accountInfo} logout={logout} permissions={permissions} />
          </MobileMenu>
        </Hidden>
        {routes}
      </GridContainer>
    </LayoutProvider>
  );
};

export default DataScience;
