import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export function getAuthProvider(authority, clientId) {
  const config = {
    auth: {
      authority,
      clientId,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  };

  const authenticationParameters = {
    scopes: [
      'user.read',
      'groupmember.read.all',
      'user.readbasic.all'
    ],
    prompt: 'select_account'
  };

  const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.origin}/auth.html`
  };

  return new MsalAuthProvider(config, authenticationParameters, options);
}
