import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as ErrorIcon } from './icons/error.svg';
import { ReactComponent as SuccessIcon } from './icons/success.svg';

import styles from './Toast.module.css';

const icons = {
  success: SuccessIcon,
  error: ErrorIcon
};

const Button = props => <div className={styles.dismissButton} role="button" {...props} />;
const Content = props => <div className={styles.content} {...props}/>;

const Countdown = ({ autoDismissTimeout, opacity, isRunning, ...props }) => (
  <div
    className={styles.countdown}
    style={{
      animation: `countdown ${autoDismissTimeout}ms linear`,
      animationPlayState: isRunning ? 'running' : 'paused',
      opacity
    }}
    {...props}
  />
);

const Icon = ({ appearance, autoDismiss, autoDismissTimeout, isRunning }) => {
  const Glyph = icons[appearance];

  return (
    <div className={`${styles.iconWrapper} ${styles[`${appearance}Icon`]}`}>
      <Countdown
        autoDismissTimeout={autoDismissTimeout}
        isRunning={isRunning}
        opacity={autoDismiss ? 1 : 0}
      />
      <Glyph className={styles.icon} />
    </div>
  );
};

function getTranslate(placement) {
  const pos = placement.split('-');
  const relevantPlacement = pos[1] === 'center' ? pos[0] : pos[1];
  const translateMap = {
    right: 'translate3d(120%, 0, 0)',
    left: 'translate3d(-120%, 0, 0)',
    bottom: 'translate3d(0, 120%, 0)',
    top: 'translate3d(0, -120%, 0)'
  };

  return translateMap[relevantPlacement];
}

const toastStates = placement => ({
  entering: { transform: getTranslate(placement) },
  entered: { transform: 'translate3d(0,0,0)' },
  exiting: { transform: 'scale(0.66)', opacity: 0 },
  exited: { transform: 'scale(0.66)', opacity: 0 }
});

const ToastElement = ({ appearance, placement, transitionDuration, transitionState, ...props }) => {
  const [height, setHeight] = useState('auto');
  const elementRef = useRef(null);

  useEffect(
    () => {
      if (transitionState === 'entered') {
        const el = elementRef.current;
        setHeight(el.offsetHeight + 12);
      }
      if (transitionState === 'exiting') {
        setHeight(0);
      }
    },
    [transitionState]
  );

  return (
    <div ref={elementRef} style={{ height, transition: `height ${transitionDuration - 100}ms 100ms` }}>
      <div
        className={`${styles.toast} ${styles[appearance]}`}
        style={{
          transition: `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms`,
          ...toastStates(placement)[transitionState]
        }}
        {...props}
      />
    </div>
  );
};

const Toast = ({
  appearance,
  autoDismiss,
  autoDismissTimeout,
  children,
  isRunning,
  onDismiss,
  placement,
  transitionDuration,
  transitionState
}) =>
  (
    <ToastElement
      appearance={appearance}
      placement={placement}
      transitionDuration={transitionDuration}
      transitionState={transitionState}
    >
      <Icon
        appearance={appearance}
        autoDismiss={autoDismiss}
        autoDismissTimeout={autoDismissTimeout}
        isRunning={isRunning}
      />
      <Content>{children}</Content>
      {onDismiss &&
        <Button onClick={onDismiss}>
          <CloseIcon />
        </Button>
      }
    </ToastElement>
  );

export default Toast;
