import React from 'react';

import styles from './Card.module.css';

const Card = ({ className, isSelected, onClick, children, withBackground = true }) => (
  <div
    className={`${styles.card} ${withBackground ? styles.background : ''} ${isSelected ? styles.selected : ''} ${className}`}
    onClick={onClick}
  >
    {children}
  </div >
);

export default Card;
