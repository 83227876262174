import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';

import Button from '../../Button';
import Content from '../../Content';
import FormLabel from '../../FormLabel';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';
import Spinner from '../../Spinner';
import FormItem from '../../FormItem';

import useToaster from '../../../utilities/hooks/useToaster';

import formStyles from '../../form.module.css';

import {
  getAvailableConfigurations,
  getSettings,
  updateSettings,
  updateVmPrices
} from '../../../utilities/api';

import { toVmSizeOption } from '../../../utilities/formatting';

import styles from './Admin.module.css';

const Settings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [settings, setSettings] = useState({});
  const [vmOptions, setVmOptions] = useState({});
  const [isUpdatingPrices, setIsUpdatingPrices] = useState(false);
  const [pricesUpdated, setPricesUpdated] = useState(null);

  const form = useRef(null);
  const { addErrorToast, addSuccessToast } = useToaster();
  const { register, handleSubmit, errors, control, reset } = useForm();

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getSettings();
      setSettings(settings);
      const { configurations, pricesDate } = await getAvailableConfigurations();
      const options = Object.values(configurations).map(x => toVmSizeOption(x));
      setVmOptions(options);
      setPricesUpdated(pricesDate);

      const defaults = {
        vmCostLimit: settings.vmCostLimit,
        vmLimitPerUser: settings.vmLimitPerUser
      };

      for (const item of settings.defaultSizes) {
        defaults[`defaultSize${item.name}`] = options.find(x => x.value === item.size);
        defaults[`defaultSize${item.name}Desc`] = item.description;
      }

      reset(defaults);

      setIsLoading(false);
    };
    fetchSettings();
  }, [reset]);

  const onSubmit = async (data) => {
    setIsSaving(true);

    try {
      await updateSettings({
        vmCostLimit: Number(data.vmCostLimit),
        vmLimitPerUser: Number(data.vmLimitPerUser),
        defaultSizes: [
          {
            name: 'Small',
            size: data.defaultSizeSmall.value,
            description: data.defaultSizeSmallDesc
          },
          {
            name: 'Medium',
            size: data.defaultSizeMedium.value,
            description: data.defaultSizeMediumDesc
          },
          {
            name: 'Large',
            size: data.defaultSizeLarge.value,
            description: data.defaultSizeLargeDesc
          }
        ]
      });
    } catch (err) {
      console.error(`${err.message}\n${err.body}`);
      addErrorToast(`${err.message}\n${err.body}`);
    }

    setIsSaving(false);
    addSuccessToast('Settings saved');
  };

  const updatePrices = async () => {
    try {
      setIsUpdatingPrices(true);
      await updateVmPrices();
      addSuccessToast('VM pricing data updated');
    } catch (error) {
      addErrorToast('Failed to update VM pricing data');
    } finally {
      setIsUpdatingPrices(false);
    }
  };

  return (
    <>
      <Content>
        <h2>Administration</h2>
        <h4 className={styles.description}>
          Settings
        </h4>
        <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)} ref={form}>
          {isLoading && <Spinner />}
          <span className={styles.explanation}>
            You can currently define a cost limit for virtual machines and how many VMs can single user create.
            VM sizes above cost limit will not be shown to users.
          </span>
          <FormItem>
            <FormLabel>Cost limit for virtual machines (€/h)</FormLabel>
            <input
              className={`${formStyles.input} ${formStyles.narrow} ${errors.vmCostLimit && formStyles.error}`}
              lang="en-US"
              min="0"
              name="vmCostLimit"
              placeholder={settings.vmCostLimit || ''}
              ref={register({ required: true })}
              step="0.1"
              type="number"
            />
          </FormItem>
          <FormItem>
            <FormLabel>Limit of virtual machines per user</FormLabel>
            <input
              className={`${formStyles.input} ${formStyles.narrow} ${errors.vmLimitPerUser && formStyles.error}`}
              min="0"
              name="vmLimitPerUser"
              placeholder={settings.vmLimitPerUser || ''}
              ref={register({ required: true })}
              type="number"
            />
          </FormItem>
          <h4>Update virtual machine pricing data</h4>
          <div>
            <p>Pricing data last updated: {pricesUpdated}</p>
            <Button disabled={isUpdatingPrices} onClick={updatePrices}>
              Update pricing data
            </Button>
          </div>
          <h4 className={styles.description}>
            Recommended virtual machine choices
          </h4>
          <span className={styles.explanation}>
            These are the three default virtual machine sizes presented to users.
            You can edit the sizes and their descriptions.
          </span>
          {settings.defaultSizes?.map(item => (
            <React.Fragment key={item.name}>
              <FormItem>
                <FormLabel><b>{item.name}</b></FormLabel>
                <br />
                <FormLabel>Size</FormLabel>
                <Controller
                  as={
                    <Select
                      options={vmOptions}
                      styles={{
                        control: styles => ({
                          ...styles,
                          border: errors[`defaultSize${item.name}`] ? '2px solid var(--error)' : styles.border,
                          height: 46,
                          marginBottom: '0.5em'
                        })
                      }}
                    />}
                  control={control}
                  name={`defaultSize${item.name}`}
                  rules={{ required: true }}
                />
                <FormLabel>Description</FormLabel>
                <input
                  className={`${formStyles.input} ${errors[`defaultSize${item.name}Desc`] ? formStyles.error : ''}`}
                  name={`defaultSize${item.name}Desc`}
                  placeholder="Description"
                  ref={register({ required: true })}
                  type="text"
                />
              </FormItem>
            </React.Fragment>
          ))}
          <div className={formStyles.actions}>
            <Button color="primary" disabled={isLoading || isSaving} type="submit">Save</Button>
          </div>
        </form>
      </Content>
      <Hidden mobile>
        <NavigationRight />
      </Hidden>
    </>
  );
};

export default Settings;
