import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Breadcrumb from '../Breadcrumb';
import Button from '../Button';
import FileTable from '../files/FileTable';
import Spinner from '../Spinner';

import { listFiles, moveFile, copyFile } from '../../utilities/api';
import { generateRoute, getContainingFolder } from '../../utilities/utils';
import useToaster from '../../utilities/hooks/useToaster';

import Modal, { Header, Body, Actions } from './Modal';
import styles from './Modal.module.css';

const columns = [
  { key: 'fileType', name: 'Type', hideInHeaders: true },
  { key: 'name', name: 'Name' }
];

const parentDir = { isDirectory: true, name: '..', fullPath: '' };

const MoveAndCopy = ({ items, path, operation, close, update }) => {
  const { addErrorToast, addSuccessToast } = useToaster();
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [folderPath, setFolderPath] = useState(path);
  const groups = useSelector(state => state.permissions.groups);

  const getContent = useCallback(async () => {
    setIsLoading(true);

    const content = await listFiles(folderPath, groups);
    const subdirs = content.items.filter(item => item.isDirectory);
    const folders = folderPath === '' ? subdirs : [parentDir, ...subdirs];

    setFolders(folders);
    setIsLoading(false);
  }, [folderPath, groups]);

  const handleNavigation = (dir) => {
    if (dir.name === '..') {
      setFolderPath(getContainingFolder(folderPath));
    } else {
      setFolderPath(`${folderPath}/${dir.name}`);
    }
  };

  const performAction = async (action, message) => {
    close();

    await Promise.all(items.map(async (item) => {
      try {
        const filePath = `${path}/${item.name}`;
        const targetPath = `${folderPath}/${item.name}`;
        addSuccessToast('Processing file(s).');
        await action(filePath, targetPath);
        addSuccessToast(`${message} to ${targetPath}`);
      } catch (err) {
        addErrorToast(err.message);
      }
    }));

    update();
  };

  const moveItem = () => performAction(moveFile, 'File moved');
  const copyItem = () => performAction(copyFile, 'File copied');

  useEffect(() => { getContent(); }, [getContent]);

  const navigate = (e, item) => {
    e.preventDefault();
    setFolderPath(item.path);
  };

  const noop = () => { };

  return (
    <Modal close={close}>
      <Header>
        {operation} {items.length > 1 ? `${items.length} items` : items[0].name}
      </Header>
      <Body style={{height: 400, overflowY: 'scroll'}}>
        <span>Select destination folder</span>
        <div className={styles.breadcrumb}>
          <Breadcrumb clickHandler={navigate} route={generateRoute(folderPath)} />
        </div>
        {isLoading ?
          <Spinner /> :
          <FileTable
            columns={columns}
            content={{ items: folders }}
            handleNavigation={handleNavigation}
            handleSelectOne={noop}
          />
        }
      </Body>
      <Actions>
        <Button color="secondary" onClick={close}>Close</Button>
        {operation === 'move' && <Button color="primary" onClick={moveItem}>Move here</Button>}
        {operation === 'copy' && <Button color="primary" onClick={copyItem}>Copy here</Button>}
      </Actions>
    </Modal>
  );
};

export default MoveAndCopy;
