import { setUserPermissionsToFiles, filterDirectoriesWithoutReadPermission } from '../filePermissions';
import { encodePath } from '../utils';
import {
  SCOPES,
  getAuthorizationHeader,
  fetchJson,
  postJson,
  putJson,
  del,
  setAuthCookie
} from './request';

const BYTES_PER_CHUNK = 10000000; // Upload in 10MB chunks

export async function listFiles(path = '/', groups, page = 0) {
  const data = await fetchJson(`/api/files${encodePath(path)}?page=${page}`, [SCOPES.storage]);

  setUserPermissionsToFiles(data, groups);
  filterDirectoriesWithoutReadPermission(data);

  data.items.forEach((item) => {
    const extension = item.name.match(/\.([^.]+)$/);
    item.fileType = extension ? extension[1] : '';
  });

  return data;
}

export async function getFile(path, filename) {
  await setAuthCookie([SCOPES.storage]);
  const a = document.createElement('a');
  a.href = `/api/download/${encodePath(path)}`;
  a.target = '_blank';
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export async function uploadFileInChunks(path, file, setUploadProgress) {
  const totalSize = file.size;

  let start = 0;
  let end;

  while (start < totalSize) {
    end = start + BYTES_PER_CHUNK;
    if (end > totalSize) {
      end = totalSize;
    }

    const chunk = file.slice(start, end);
    const response = await processChunkUpload(path, file, chunk, start, end, totalSize, setUploadProgress);
    if (response === 'Canceled') {
      console.log('Upload canceled');
      break;
    }
    start = end;
  }
}

export async function processChunkUpload(path, file, chunk, start, end, totalSize, setUploadProgress) {
  const data = new FormData();
  data.append('file', chunk);
  const authHeader = await getAuthorizationHeader([SCOPES.storage]);

  const xhr = new XMLHttpRequest();

  let lastNow = new Date().getTime();
  let lastMbs = 0;

  return new Promise((resolve, reject) => {
    xhr.upload.onprogress = (e) => {
      const done = e.position || e.loaded;
      const percentage = Math.floor((start + done) / totalSize * 1000) / 10;

      const now = new Date().getTime();
      const mbs = done / 1024 / 1024;
      const uploadedMbs = mbs - lastMbs;
      const elapsed = (now - lastNow) / 1000;
      file.uploadSpeed = elapsed ? uploadedMbs / elapsed : 0;
      lastMbs = mbs;
      lastNow = now;

      file.xhr = xhr;
      setUploadProgress(file, percentage);
    };
    xhr.onabort = function () {
      resolve('Canceled');
    };
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    xhr.open('PUT', `/api/files${encodePath(path)}`);
    xhr.setRequestHeader('Authorization', authHeader.Authorization);
    xhr.setRequestHeader('Content-Range', `bytes ${start}-${end}/${totalSize}`);
    xhr.send(data);
  });
}

export async function uploadFile(path, file, setUploadProgress) {
  const data = new FormData();

  data.append('file', file);
  const authHeader = await getAuthorizationHeader([SCOPES.storage]);

  const xhr = new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    xhr.upload.onprogress = (e) => {
      const done = e.position || e.loaded;
      const total = e.totalSize || e.total;
      const percentage = Math.floor(done / total * 1000) / 10;
      file.xhr = xhr;
      setUploadProgress(file, percentage);
    };
    xhr.onabort = function () {
      resolve('Canceled');
    };
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    xhr.open('POST', `/api/files${encodePath(path)}`);
    xhr.setRequestHeader('Authorization', authHeader.Authorization);
    xhr.send(data);
  });
}

export function deleteFile(path, version) {
  const versionParam = version ? `?version=${version}` : '';
  return del(`/api/files${encodePath(path)}${versionParam}`, [SCOPES.storage]);
}

export function createDir(path) {
  return postJson(`/api/files/directory${encodePath(path)}`, [SCOPES.storage]);
}

export function createProject(path, metadata) {
  return postJson(`/api/files/project${encodePath(path)}`, null, metadata);
}

export async function updateMetadata(path, metadata) {
  await setAuthCookie();
  return putJson(`/api/files/metadata${encodePath(path)}`, [SCOPES.storage], metadata);
}

export function rename(path, name) {
  return postJson(`/api/files/rename${encodePath(path)}`, [SCOPES.storage], { name });
}

export function restoreVersion(path, version) {
  return postJson(`/api/files/restore${encodePath(path)}`, [SCOPES.storage], { version });
}

export function moveFile(path, target) {
  return postJson(`/api/files/move${encodePath(path)}`, [SCOPES.storage], { target });
}

export function copyFile(path, target) {
  return postJson(`/api/files/copy${encodePath(path)}`, [SCOPES.storage], { target });
}

export async function listProjects() {
  await setAuthCookie();
  return fetchJson('/api/files/project', [SCOPES.storage]);
}

export async function searchFiles(query) {
  await setAuthCookie();
  return fetchJson(`/api/files/search?q=${query}`, [SCOPES.storage]);
}

export async function getPdfFileRequest(fullPath) {
  return {
    url: `${window.location.origin}/api/download/${encodePath(fullPath)}`,
    httpHeaders: await getAuthorizationHeader([SCOPES.storage]),
    withCredentials: true
  };
}

export async function getFiles(filePaths, prefix) {
  const a = document.createElement('a');
  a.href = `/api/download/zip/${btoa(JSON.stringify({ filePaths, prefix }))}`;
  a.target = '_blank';
  a.download = 'dsw-files.zip';
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export async function setStorageAuthCookie() {
  await setAuthCookie([SCOPES.storage]);
}

export function deleteFolderCache(path) {
  return del(`/api/files/cache${encodePath(path)}`, [SCOPES.storage]);
}
