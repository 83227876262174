import React from 'react';

import useToaster from '../utilities/hooks/useToaster';

import styles from './CopyableText.module.css';

const CopyableText = ({ children }) => {
  const { addSuccessToast } = useToaster();

  async function copyToClipboard() {
    await navigator.clipboard.writeText(children);
    addSuccessToast('Copied to clipboard');
  }

  return (
    <span className={styles.text}>
      {children}
      <button className={styles.copy} onClick={copyToClipboard} title="Copy to clipboard" type="button" />
    </span>
  );
};

export default CopyableText;
