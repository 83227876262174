import React, { useEffect, useRef, useState } from 'react';
import SvgIcon from '../SvgIcon';
import styles from './FileAction.module.css';

const ActionItem = ({ icon, name, onClick }) => {
  const Icon = SvgIcon(icon);
  return (
    <span className={styles.actionItem} onClick={onClick}>
      <Icon />
      {name}
    </span>
  );
};

const ActionMenu = ({ children, handleClose }) => {
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={styles.menu} ref={ref}>
      {children}
    </div>
  );
};

const FileAction = ({ actions, item, handleSelectOne }) => {
  const [isOpen, setIsOpen] = useState(false);

  const applicableActions = item.isDirectory ? actions.folder : actions.singleFile;
  const buttonId = `${item.name}-action`;

  const triggerMenu = () => setIsOpen(!isOpen);

  const handleClick = () => {
    handleSelectOne(item.name);
    triggerMenu();
  };

  const handleAction = (action) => {
    setIsOpen(false);
    action(item);
  };

  return (
    <div className={styles.container}>
      <button className={styles.action} id={buttonId} onClick={handleClick} type="button">...</button>
      {isOpen &&
        <ActionMenu handleClose={triggerMenu} parentId={buttonId}>
          {applicableActions.filter(x => !x.disabled).map(action => (
            <ActionItem
              icon={action.icon}
              key={action.name}
              name={action.name}
              onClick={() => handleAction(action.action)}
            />
          ))}
        </ActionMenu>
      }
    </div>
  );
};

export default FileAction;
