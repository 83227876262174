import React from 'react';
import Button from '../Button';
import Modal, { Header, Body, Actions } from './Modal';

function parseType(type) {
  switch (type) {
    case 'vm':
      return 'virtual machine';
    case 'app':
      return 'application';
    case 'workspace':
      return 'workspace';
    default:
      return type;
  }
}

const ConfirmWorkspaceDelete = ({ item, confirm, close }) => (
  <Modal close={close}>
    <Header>
      Delete {parseType(item.type)}?
    </Header>
    <Body>
      Are you sure you want to delete {parseType(item.type)}{' '}
      <b>{item.tags?.dswname || item.name}</b> ?
    </Body>
    <Actions>
      <Button color="secondary" onClick={close}>Cancel</Button>
      <Button color="primary" onClick={confirm}>Delete</Button>
    </Actions>
  </Modal>
);

export default ConfirmWorkspaceDelete;
