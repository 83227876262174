const parseStatus = status => ({
  itemIsAvailable: status === 'available',
  itemIsOffline: status === 'offline',
  itemIsFailed: status === 'failed',
  itemIsStarting: status === 'starting'
});

module.exports = {
  parseStatus
};
