import React from 'react';
import Card from './Card';
import styles from './Card.module.css';
import StatusIcon from './StatusIcon';

const Status = ({ status }) => (
  <div className={styles.status}>
    {status}
    <StatusIcon status={status} />
  </div>
);

const WorkspaceCard = ({ item, image, isSelected, onClick, unselect, star }) => (
  <Card isSelected={isSelected} onClick={onClick}>
    {star &&
      <button className={styles.star} onClick={star} type="button">
        <img alt="Star" src={`/icons/${item.isStarred ? 'star' : 'star-border'}.svg`} />
      </button>
    }
    {isSelected &&
      <button className={styles.unselect} onClick={unselect} type="button">
        <img alt="Close" src="/icons/close.svg" />
      </button>
    }
    <Status status={item.status?.state ?? 'updating'} />
    {image}
    <h5 className={styles.title}>
      {item.dswName || item.tags?.dswname || item.name}
    </h5>
    <div className={styles.description}>
      {item.description && (item.description.length < 100 ? item.description : `${item.description.substring(0, 100)}...`)}
      {item.tags?.restricted === 'restricted' && (
        <div className={styles.restrictedAccess}>Restricted access</div>
      )}
    </div>
  </Card>
);

export default WorkspaceCard;
