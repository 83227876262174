import React from 'react';
import { useSelector } from 'react-redux';

import { DOMAIN } from '../../../../constants';
import CopyableText from '../../../CopyableText';
import Icon from '../../../Icon';
import NewTabLink from '../../../NewTabLink';

import styles from './VmLinks.module.css';

const SSH = ({ item }) => {
  const username = useSelector(state => state.user.username);

  return (
    <div className={styles.ssh}>
      <Icon iconName="ssh" title="SSH" />
      <span className={styles.sshCommand}>
        <CopyableText>
          {`ssh -4 ${item.tags.username || username}@${item.name}.${DOMAIN}`}
        </CopyableText>
      </span>
    </div>
  );
};

const VmLinks = ({ item, statusJupyter, statusRstudio }) => {
  const noServices = item.tags.noServices === 'true';

  if (noServices) {
    return <SSH item={item} />;
  }

  return (
    <>
      <NewTabLink
        disabled={!statusRstudio}
        type={{ name: 'RStudio', icon: 'rstudio' }}
        url={`https://${item.name}.${DOMAIN}/rstudio`}
      />
      <NewTabLink
        disabled={!statusJupyter}
        type={{ name: 'Jupyter Notebook', icon: 'jupyter' }}
        url={`https://${item.name}.${DOMAIN}/jupyter`}
      />
      <SSH item={item} />
    </>
  );
};

export default VmLinks;
