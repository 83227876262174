import React from 'react';
import useMediaQuery from '../utilities/hooks/useMediaQuery';

const Hidden = ({ children, mobile = false, desktop = false }) => {
  const { isDesktop } = useMediaQuery();
  const isHidden = (mobile && !isDesktop) || (desktop && isDesktop);

  if (isHidden) {
    return false;
  }
  return (
    <>
      {children}
    </>
  );
};

export default Hidden;


