import React from 'react';

import { ReactComponent as DataSharing } from '../components/icons/data-sharing.svg';
import { ReactComponent as DataSharingLogo } from '../components/icons/data-sharing-logo.svg';
import { ReactComponent as DataScience } from '../components/icons/data-science.svg';
import { ReactComponent as DataScienceLogo } from '../components/icons/data-science-logo.svg';

const dataSharing = {
  name: 'Data Sharing', url: '/data-sharing', icon: <DataSharing />, logo: <DataSharingLogo />
};
const dataScience = {
  name: 'Data Science', url: '/data-science', icon: <DataScience />, logo: <DataScienceLogo />
};

export function getApps(permissions) {
  const apps = [];

  if(permissions?.dataSharing?.user && !permissions?.dataSharing?.restrictedAccess) {
    apps.push(dataSharing);
  }

  if(permissions?.dataScience?.user) {
    apps.push(dataScience);
  }

  return apps;
}
