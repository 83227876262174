import { useEffect, useState } from 'react';

const useMediaQuery = () => {
  const desktopQuery = window.matchMedia('(min-width: 1024px)');
  const [isDesktop, setIsDesktop] = useState(desktopQuery.matches);

  useEffect(() => {
    const desktopQueryHandler = e => setIsDesktop(e.matches);
    desktopQuery.addListener(desktopQueryHandler);
    return () => {
      desktopQuery.removeListener(desktopQueryHandler);
    };
  });

  return {
    isDesktop
  };
};

export default useMediaQuery;
