import React, { useState } from 'react';
import Content from '../../Content';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';
import RefreshButton from '../../RefreshButton';
import Spinner from '../../Spinner';
import WorkspaceDetails from '../WorkspaceDetails';
import WorkspaceTable from '../WorkspaceTable';
import Button from '../../Button';

import styles from './Admin.module.css';

const Workspaces = ({ useWorkspaces }) => {
  const [selected, select] = useState();

  const {
    workspaces,
    isLoadingWorkspaces,
    refreshWorkspaces,
    updateWorkspace,
    updateAllWorkspaces
  } = useWorkspaces;

  const runningWs = workspaces.filter(x => x.status?.state === 'available').length;

  return (
    <>
      <Content>
        <h2>Administration</h2>
        <h4 className={styles.description}>
          Workspaces
          <RefreshButton disabled={isLoadingWorkspaces} onClick={refreshWorkspaces} />
        </h4>
        {isLoadingWorkspaces ?
          <Spinner /> :
          <span className={styles.explanation}>
            There are currently {workspaces.length} user workspaces, {runningWs} of which are available.
          </span>
        }
        <WorkspaceTable
          isLoading={isLoadingWorkspaces}
          items={workspaces}
          select={select}
          selected={selected}
        />
        <Button
          disabled={isLoadingWorkspaces}
          onClick={updateAllWorkspaces}
          title="Updates all workspaces to latest image version"
        >
          Update all workspaces
        </Button>
      </Content>
      <Hidden mobile>
        <NavigationRight>
          <WorkspaceDetails
            admin
            item={workspaces.find(x => x.name === selected)}
            refreshWorkspaces={refreshWorkspaces}
            select={select}
            updateWorkspace={updateWorkspace}
          />
        </NavigationRight>
      </Hidden>
    </>
  );
};

export default Workspaces;
