import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import { toVmSizeOption } from '../../../utilities/formatting';

import VmSizeCard from './VmSizeCard';

import styles from './RequestWorkspace.module.css';

const VmSize = ({ errors, setVmSize, setValue, defaultSizes, vmSize, vmConfigurations, control }) => {
  const [specialSize, setSpecialSize] = useState(false);

  const vmSizeStyles = {
    control: styles => ({
      ...styles,
      border: errors.vmSize ? '2px solid var(--error)' : styles.border
    })
  };

  const selectSize = (size) => {
    setSpecialSize(false);
    setVmSize(size);
    setValue('vmSize', toVmSizeOption(vmConfigurations[size]));
  };

  return (
    <>
      <h5 className={styles.description}>Select virtual machine size</h5>
      <div className={styles.formItem}>
        <div className={styles.cards}>
          {defaultSizes.map(item => (
            <VmSizeCard
              description={item.description}
              disabled={vmConfigurations[item.size] === undefined || vmConfigurations[item.size]?.disabled}
              key={item.name}
              name={item.name}
              selectSize={selectSize}
              size={vmConfigurations[item.size]}
              specialSize={specialSize}
              vmSize={vmSize}
            />
          ))}
        </div>
      </div>

      <div className={`${styles.formItem} ${styles.dividerAfter}`}>
        <div className={styles.radioButton}>
          <input
            checked={specialSize}
            id="customSize"
            name="Custom"
            onChange={() => setSpecialSize(s => !s)}
            type="radio"
          />
          <label htmlFor="customSize">Custom size</label>
          <Controller
            as={
              <Select
                isDisabled={!specialSize}
                isOptionDisabled={option => option.disabled}
                options={Object.values(vmConfigurations).map(x => toVmSizeOption(x))}
                styles={vmSizeStyles}
              />}
            control={control}
            defaultValue=""
            name="vmSize"
            rules={{ required: true }}
          />
        </div>
      </div>
    </>
  );
};

export default VmSize;
