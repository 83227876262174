import React from 'react';
import ReactDOM from 'react-dom';

import 'overlayscrollbars/css/OverlayScrollbars.css';

import * as serviceWorker from './serviceWorker';
import Authentication from './Authentication';
import TestAuthentication from './TestAuthentication';

import './index.css';
import './colors.css';

if (process.env.REACT_APP_MOCK_AUTH) {
  ReactDOM.render(<TestAuthentication />, document.getElementById('root'));
} else {
  ReactDOM.render(<Authentication />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
