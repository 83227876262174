import React, { useContext } from 'react';
import { LayoutContext } from '../context/LayoutProvider';
import styles from './GridContainer.module.css';

const minimizedStyle = {
  gridTemplateColumns: 'repeat(3, minmax(130px, 1fr)) repeat(8, 1fr) 24px',
  gridTemplateAreas: "'a a b b b b b b b b b c'"
};

const GridContainer = ({ children }) => {
  const [minimized] = useContext(LayoutContext);
  return (
    <div className={styles.container} style={minimized ? minimizedStyle : null}>
      {children}
    </div>
  );
};

export default GridContainer;
