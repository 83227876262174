import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignoutButton from '../components/SignoutButton';
import { getApps } from '../utilities/apps';

import AppContext from '../context/App';

import styles from './Home.module.css';

const Home = () => {
  const permissions = useSelector(state => state.permissions);
  const { logout } = useContext(AppContext);
  const apps = getApps(permissions);

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <img alt="Orion" className={styles.logo} src="/images/logo-white.svg" />
        <div className={styles.cards}>
          {apps.map(app => (
            <Link key={app.name} to={app.url}>
              <div className={styles.card}>
                {app.logo}
                <span className={styles.appName}>
                  {app.icon}
                  {app.name}
                </span>
              </div>
            </Link>
          ))}
          {apps.length === 0 &&
            <div className={styles.column}>
              <h3>You are not permitted to use this application</h3>
              <h5>Please contact your manager to obtain access</h5>
            </div>
          }
        </div>
      </div>
      <div className={styles.actions}>
        <SignoutButton logout={logout} />
      </div>
    </div>
  );
};

export default Home;
