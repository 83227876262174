import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import Loading from './pages/Loading';
import Page from './components/Page';
import Router from './components/Router';
import Toast from './components/Toast';

import { fetchPermissions } from './store/permissionsSlice';
import { fetchUserSettings } from './store/settingsSlice';
import { fetchUser } from './store/userSlice';

const App = ({ accountInfo, authenticated, getAccessToken, logout }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const permissions = useSelector((state) => state.permissions);
  const dispatch = useDispatch();
  const user = accountInfo?.account?.userName;

  useEffect(() => {
    async function getUserPermissions() {
      if (authenticated) {
        dispatch(fetchPermissions())
          .then(unwrapResult)
          .catch((err) => console.error('Failed to get permissions', err));
      }
    }

    if (authenticated) {
      getUserPermissions();

      dispatch(fetchUserSettings())
        .then(unwrapResult)
        .catch((err) => console.error('Failed to get user settings', err));

      dispatch(fetchUser())
        .then(unwrapResult)
        .catch((err) => console.error('Failed to get user data', err));
    }

    // Check if user permissions are updated in Azure AD in every 5 min
    const permissionsCheckInterval = setInterval(getUserPermissions, 300000);

    return () => clearInterval(permissionsCheckInterval);
  }, [authenticated, user, getAccessToken, dispatch]);

  return !permissions.loading ? (
    <ToastProvider
      autoDismiss
      autoDismissTimeout={5000}
      components={{ Toast: Toast }}
      placement="bottom-right"
    >
      <BrowserRouter>
        <Page mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen}>
          <Router
            accountInfo={accountInfo}
            logout={logout}
            mobileMenuOpen={mobileMenuOpen}
            permissions={permissions}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        </Page>
      </BrowserRouter>
    </ToastProvider>
  ) : (
    <Loading />
  );
};

export default App;
