import React from 'react';
import styles from './OrderAndFilter.module.css';
import { ReactComponent as Up } from './icons/arrow-up.svg';
import { ReactComponent as Down } from './icons/arrow-down.svg';
import { ReactComponent as Filter } from './icons/filter.svg';
import Tag from './Tag';

const OrderAndFilter = ({ order, changeOrder, useFilter, handleFiltering, activeFilters, removeFilter }) => (
  <>
    <div className={styles.container}>
      <div
        className={styles.item}
        onClick={changeOrder}
        style={{ color: order === 'desc' ? 'var(--dataSharing)' : 'var(--divider)' }}
        title="Order items ascending / descending"
      >
        Order
        {order === 'asc' ? <Up /> : <Down />}
      </div>
      {' / '}
      <div
        className={styles.item}
        onClick={handleFiltering}
        style={{ color: useFilter ? 'var(--dataSharing)' : 'var(--divider)' }}
        title="Filter items using tags"
      >
        Filter
        <Filter />
      </div>
    </div>
    {useFilter &&
      <div className={styles.tags}>
        {activeFilters.map(tag => <Tag className={styles.tag} key={tag} name={tag} onClick={() => removeFilter(tag)} />)}
      </div>
    }
  </>
);

export default OrderAndFilter;
