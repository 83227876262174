import React, { useState } from 'react';

import useInterval from '../utilities/hooks/useInterval';

import styles from './Skeleton.module.css';

const TextSkeleton = () => (
  <td>
    <span className={styles.cellContent} />
  </td>
);

const AvatarSkeleton = () => (
  <td>
    <div className={styles.centered}>
      <div className={styles.avatar} />
      <span className={styles.cellContent} />
    </div>
  </td>
);

const StatusSkeleton = () => (
  <td>
    <div className={styles.centered}>
      <div className={styles.status} />
      <span className={styles.cellContent} />
    </div>
  </td>
);

const SkeletonRows = ({ columns, amount = 5 }) => {
  const [shownAmount, setShownAmount] = useState(0);
  const [timeout, setTimeout] = useState(500);

  useInterval(() => {
    if (shownAmount < amount) {
      setShownAmount(x => x + 1);
    } else {
      setTimeout(0);
    }
  }, timeout);

  const renderSkeleton = (col) => {
    switch (col.key) {
      case 'author_name':
      case 'owner':
        return <AvatarSkeleton key={col.key} />;
      case 'status':
        return <StatusSkeleton key={col.key} />;
      default:
        return <TextSkeleton key={col.key} />;
    }
  };

  const list = [];
  for (let i = 0; i < shownAmount; i++) {
    list.push(
      <tr key={i}>
        {columns.filter(col => !col.hide).map(col => renderSkeleton(col))}
      </tr>
    );
  }
  return list;
};

export default SkeletonRows;
