import React from 'react';
import { useLocation } from 'react-router-dom';

import useMediaQuery from '../utilities/hooks/useMediaQuery';

import Header from './Header';

import styles from './Page.module.css';

const Page = ({ children, mobileMenuOpen, setMobileMenuOpen }) => {
  const { isDesktop } = useMediaQuery();
  const location = useLocation();
  return (
    <div className={styles.page}>
      {location.pathname !== '/' && !isDesktop &&
        <Header mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
      }
      <main className={styles.main}>
        {children}
      </main>
    </div>
  );
};

export default Page;
