import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import GridContainer from '../components/GridContainer';
import Hidden from '../components/Hidden';
import MobileMenu from '../components/navigation/MobileMenu';
import ProjectList from '../components/ProjectList';
import FileBrowser from '../components/files/FileBrowser';

import AppContext from '../context/App';

import useMediaQuery from '../utilities/hooks/useMediaQuery';
import useFiles from '../utilities/hooks/useFiles';

import DataSharingAdmin from './DataSharingAdmin';


const DataSharing = () => {
  const { isDesktop } = useMediaQuery();
  const location = useLocation();
  const path = location.pathname.replace(/^\/data-sharing/, '');
  const permissions = useSelector(state => state.permissions);
  const files = useFiles(path, permissions);
  const { accountInfo, logout, mobileMenuOpen, setMobileMenuOpen } = useContext(AppContext);

  return (
    <GridContainer>
      {isDesktop &&
        <ProjectList
          accountInfo={accountInfo}
          content={files.projects}
          isLoading={files.isLoadingProjects}
          logout={logout}
        />
      }
      <Hidden desktop>
        <MobileMenu close={() => setMobileMenuOpen(false)} open={mobileMenuOpen}>
          <ProjectList
            accountInfo={accountInfo}
            content={files.projects}
            isLoading={files.isLoadingProjects}
            logout={logout}
          />
        </MobileMenu>
      </Hidden>
      <Switch>
        <Route path="/data-sharing/admin">
          <DataSharingAdmin accountInfo={accountInfo} files={files} />
        </Route>
        <Route path="/data-sharing" render={() => <FileBrowser files={files} />} />
      </Switch>
    </GridContainer>
  );
};

export default DataSharing;
