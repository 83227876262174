import React, { useEffect, useState } from 'react';

import { listGroups } from '../../../utilities/api';

import Content from '../../Content';
import GroupMembers from './GroupMembers';

import styles from './ProjectGroups.module.css';

const Groups = ({ project, getGroupName, setShowMembers }) => (
  Object.entries(project.groupPermissions)
    .filter(([key]) => key !== '')
    .map(([key, value]) => (
      <tr key={`${project.name}-${key}`}>
        <td>
          <div>
            {getGroupName(key)}
          </div>
        </td>
        <td>
          {value.read === true && 'Read'} {value.write === true && 'Write'}
        </td>
        <td>
          <button className={styles.link} onClick={() => setShowMembers(key)} type="button">Members</button>
        </td>
      </tr>
    ))
);

const Project = ({ project, getGroupName, setShowMembers }) => (
  <>
    <tr key={project.name}>
      <td rowSpan={Object.keys(project.groupPermissions).length}>{project.name}</td>
    </tr>
    <Groups getGroupName={getGroupName} project={project} setShowMembers={setShowMembers} />
  </>
);

const ProjectGroups = ({ files }) => {
  const [groups, setGroups] = useState([]);
  const [showMembers, setShowMembers] = useState(undefined);

  useEffect(() => {
    const getGroups = async () => {
      const data = await listGroups();
      setGroups(data);
    };

    getGroups();
  }, []);

  const getGroupName = id => groups.find(group => id === group.id)?.name;

  if (!files?.projects) {
    return null;
  }

  if (showMembers) {
    return (
      <Content>
        <GroupMembers
          close={() => setShowMembers(undefined)}
          group={showMembers}
          name={getGroupName(showMembers)}
        />
      </Content>
    );
  }

  return (
    <Content>
      <h2>Project permissions</h2>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Project</th>
            <th>Assigned groups</th>
            <th>Permissions</th>
            <th>Members</th>
          </tr>
        </thead>
        <tbody>
          {files.projects.map(project => (
            <Project
              getGroupName={getGroupName}
              key={project.name}
              project={project}
              setShowMembers={setShowMembers}
            />
          ))}
        </tbody>
      </table>
    </Content>
  );
};

export default ProjectGroups;
