import React from 'react';

import styles from './Icon.module.css';

const Icon = ({ iconName, disabled, title }) => (
  <img
    alt="Icon"
    className={styles.icon}
    src={`/icons/${iconName}.svg`}
    style={{ filter: disabled ? 'grayscale(100%)' : 'none' }}
    title={title}
  />
);

export default Icon;
