import React from 'react';
import styles from './Dropzone.module.css';

const Dropzone = () => (
  <div className={styles.overlay}>
    <div className={styles.dropzone}>
      Drop folders or files here to upload
    </div>
  </div>
);

export default Dropzone;
