import React, { useContext, useEffect, useRef, useState } from 'react';

import { LayoutContext } from '../../../context/LayoutProvider';

import Content from '../../Content';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';
import Spinner from '../../Spinner';

import WorkspaceHelp from './WorkspaceHelp';
import styles from './Workspace.module.css';

const WorkspaceError = ({ name }) => (
  <div className={styles.error}>
    <div>Failed to start {name} session</div>
  </div>
);

const WorkspaceFrame = ({ item, isConnecting, onIframeLoad, workspaceIframe, url }) => (
  <>
    {isConnecting ? (
      <div className={styles.loading}>
        <img alt={item.name} src={item.icon} />
        <span>Starting session</span>
        <Spinner />
      </div>
    ) : (
      <iframe
        className={styles.iframe}
        onLoad={onIframeLoad}
        ref={workspaceIframe}
        src={url}
        title={item.name}
      />
    )}
  </>
);

const Workspace = ({ item, useWorkspaces }) => {
  const [, setMinimized] = useContext(LayoutContext);
  const [isConnecting, setIsConnecting] = useState(false);
  const [url, setUrl] = useState();
  const [retryCounter, setRetryCounter] = useState(0);
  const [isWorkspaceError, setWorkspaceError] = useState(false);
  const [workspaceType, setWorkspaceType] = useState();
  const workspaceIframe = useRef();

  const { refreshWorkspaces } = useWorkspaces;

  useEffect(() => {
    if (workspaceType === undefined) {
      setWorkspaceType(item.workspaceType);
    } else if (workspaceType !== item.workspaceType) {
      setWorkspaceType(item.workspaceType);
      setUrl(undefined);
    }
  }, [item, workspaceType]);

  useEffect(() => {
    const connect = async () => {
      if (isConnecting || url !== undefined) {
        return;
      }

      try {
        setIsConnecting(true);
        const { url } = await item.startWorkspace();
        setUrl(url);
        setIsConnecting(false);
      } catch (err) {
        setWorkspaceError(true);
      }
    };
    connect();
  }, [item, isConnecting, url]);

  useEffect(
    () => () => {
      setMinimized(false);
      refreshWorkspaces();
    },
    [setMinimized, refreshWorkspaces]
  );

  const maximizedLayout = { padding: 0, overflowY: 'hidden' };

  const onIframeLoad = () => {
    const iframe = workspaceIframe.current;
    const title = iframe.contentWindow.document.title;

    // Sometimes Rstudio seems to be ready but returns 503 error page for first request.
    if (title === '503 Service Temporarily Unavailable' && retryCounter < 10) {
      setRetryCounter(retryCounter + 1);
      setTimeout(() => iframe.contentWindow.location.reload(), 1000);
    }
  };

  return (
    <>
      <Content style={isConnecting ? null : maximizedLayout}>
        <div className={styles.container}>
          {isWorkspaceError ? (
            <WorkspaceError name={item.name} />
          ) : (
            <WorkspaceFrame
              isConnecting={isConnecting}
              item={item}
              onIframeLoad={onIframeLoad}
              url={url}
              workspaceIframe={workspaceIframe}
            />
          )}
        </div>
      </Content>
      <Hidden mobile>
        <NavigationRight>
          <WorkspaceHelp item={item} url={url} />
        </NavigationRight>
      </Hidden>
    </>
  );
};

export default Workspace;
