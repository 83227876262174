import React, { memo } from 'react';
import { stringToHslColor } from '../utilities/colors';
import styles from './Avatar.module.css';

const Avatar = ({ name }) => {
  const backgroundColor = stringToHslColor(name);
  try {
    const splitName = name.includes(',') ? name.split(',') : name.split(' ');
    const initials = splitName.reverse().map(name => name.trim().substring(0, 1)).join('');
    return (
      <div className={styles.avatar} style={{ backgroundColor }}>{initials}</div>
    );
  } catch (err) {
    console.log('Failed to build avatar', name);
    console.log(err);
    return <div />;
  }
};

export default memo(Avatar);
