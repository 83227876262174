import React, { useState } from 'react';
import Content from '../../Content';
import Hidden from '../../Hidden';
import NavigationRight from '../../navigation/NavigationRight';
import RefreshButton from '../../RefreshButton';
import Spinner from '../../Spinner';
import WorkspaceDetails from '../WorkspaceDetails';
import WorkspaceTable from '../WorkspaceTable';

import styles from './Admin.module.css';

const VirtualMachines = ({ useVirtualMachines }) => {
  const { virtualMachines, refreshVm, fetchVms, isLoadingVms, resize } = useVirtualMachines;
  const [selected, select] = useState();

  const runningVMs = virtualMachines.filter(vm => vm.status?.state === 'available').length;

  return (
    <>
      <Content>
        <h2>Administration</h2>
        <h4 className={styles.description}>
          Virtual machines
          <RefreshButton disabled={isLoadingVms} onClick={fetchVms} />
        </h4>
        {isLoadingVms ?
          <Spinner /> :
          <span className={styles.explanation}>
            There are currently {virtualMachines.length} virtual machines created, {runningVMs} of which are running.
          </span>
        }
        <WorkspaceTable
          isLoading={isLoadingVms && virtualMachines.length === 0}
          items={virtualMachines}
          select={select}
          selected={selected}
        />
      </Content>
      <Hidden mobile>
        <NavigationRight>
          <WorkspaceDetails
            admin
            item={virtualMachines.find(x => x.name === selected)}
            refreshVm={vm => refreshVm(vm)}
            resizeVm={resize}
            select={select}
            updateVmList={fetchVms}
          />
        </NavigationRight>
      </Hidden>
    </>
  );
};

export default VirtualMachines;
