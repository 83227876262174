import { fetchJson, postJson, putJson, del } from './request';

const HEALTH_ENDPOINTS = {
  rstudio: '/rstudio-health',
  jupyter: '/jupyter/hub/health'
};

export function listVMs() {
  return fetchJson(`/api/vm/`);
}

export function listAllVMs() {
  return fetchJson(`/api/vm?all=true`);
}

export function startVM(name) {
  return putJson(`/api/vm/start/${name}`);
}

export function stopVM(name) {
  return putJson(`/api/vm/stop/${name}`);
}

export async function getVM(name) {
  try {
    const vm = await fetchJson(`/api/vm/${name}`);
    const status = await getVmStatus(name);
    return { ...vm, status };
  } catch (err) {
    console.log('Failed to retrieve VM details.', err.message);
    return {};
  }
}

export function getVmStatus(name) {
  return fetchJson(`/api/vm/${name}/status`);
}

export async function createVM(vmProperties) {
  return await postJson('/api/vm', null, vmProperties);
}

export async function getAvailableConfigurations() {
  try {
    return await fetchJson('/api/vm/configurations');
  } catch (err) {
    console.error('Failed to retrieve VM configurations.', err.message);
    return {};
  }
}

export async function getConfiguration(name) {
  try {
    return await fetchJson(`/api/vm/configurations/${name}`);
  } catch (err) {
    console.error('Failed to retrieve VM configuration', err.message);
    return {};
  }
}

export function getDefaultSizes() {
  return fetchJson('/api/vm/defaults');
}

export function getVmLimitPerUser() {
  return fetchJson('/api/vm/costLimit');
}

export function deleteVM(vmName) {
  return del(`/api/vm/${vmName}`);
}

export function updateVmMetadata(vmName, propertyName, value) {
  return putJson(`/api/vm/${vmName}/${propertyName}`, null, { value });
}

export function getSshKey() {
  return fetchJson('/api/vm/ssh-key');
}

export function getSettings() {
  return fetchJson('/api/settings/data-science');
}

export function updateSettings(data) {
  return postJson('/api/settings/data-science', null, data);
}

export function checkVmServiceHealth(host, service) {
  return fetch(`https://${host}${HEALTH_ENDPOINTS[service]}`);
}

export function enableRootAccess(vmName) {
  return putJson(`/api/vm/${vmName}/sudo`);
}

export function disableRootAccess(vmName) {
  return del(`/api/vm/${vmName}/sudo`);
}

export function getAvailableSizesForVm(vmName) {
  return fetchJson(`/api/vm/${vmName}/sizes`);
}

export function resizeVm(vmName, vmSize) {
  return putJson(`/api/vm/${vmName}`, null, { vmSize });
}

export function updateSshKey(vmName, key, username) {
  return postJson(`/api/vm/${vmName}/ssh`, null, { key, username });
}

export function createImage(vmName, imageName, sharedImage = false) {
  return postJson(`/api/vm/image/${vmName}`, null, { sharedImage, imageName });
}

export function listImages() {
  return fetchJson('/api/vm/image');
}

export function listAllImages() {
  return fetchJson('/api/vm/image?all=true');
}

function setSshServiceStatus(vmName, enable) {
  return putJson(`/api/vm/${vmName}/ssh`, null, { enable });
}

export function enableSsh(vmName) {
  return setSshServiceStatus(vmName, true);
}

export function disableSsh(vmName) {
  return setSshServiceStatus(vmName, false);
}

export function deleteImage(imageName) {
  return del(`/api/vm/image/${imageName}`);
}

export function setIdleShutdown(vmName, enabled) {
  return postJson(`/api/vm/${vmName}/disble_idle_shutdown`, null, { enabled });
}

export function addUser(vmName, upn, sshKey, username) {
  return postJson(`/api/vm/${vmName}/user`, null, { upn, sshKey, username });
}

export function updateVmPrices() {
  return postJson('/api/settings/prices', null);
}
