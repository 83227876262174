import React from 'react';

import ActionButton from '../../ActionButton';
import { parseStatus } from './utils';

const WorkspaceActions = ({
  item, history, actionsDisabled, updateWorkspace, confirmDelete, refresh, refreshing, status
}) => {
  const { itemIsStarting } = parseStatus(status);

  return (
    <>
      {item.owner &&
        <ActionButton disabled={actionsDisabled} icon="open" name="Open" onClick={() => history.push(item.location)} />
      }
      {!itemIsStarting &&
        <ActionButton
          disabled={actionsDisabled}
          icon="update"
          name="Update"
          onClick={() => updateWorkspace(item.name)}
        />
      }
      <ActionButton disabled={actionsDisabled} icon="delete" name="Delete" onClick={confirmDelete} />
      <ActionButton disabled={refreshing} icon="refresh" name="Refresh" onClick={refresh} />
    </>
  );
};

export default WorkspaceActions;
