import React, { useRef } from 'react';

import Button from '../Button';
import FormLabel from '../FormLabel';
import FormItem from '../FormItem';

import { createImage } from '../../utilities/api';
import useToaster from '../../utilities/hooks/useToaster';

import formStyles from '../form.module.css';
import styles from './CreateImage.module.css';

import Modal, { Header, Body, Actions } from './Modal';

const CreateImage = ({ close, vmName, dswName, refresh }) => {
  const { addErrorToast, addSuccessToast } = useToaster();
  const imageName = useRef(null);
  const sharedImage = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      addSuccessToast('Creating image...');
      close();
      await createImage(vmName, imageName.current.value, sharedImage.current.checked);
      addSuccessToast('Image created');
      setTimeout(refresh, 5000); // Wait vm deletion is started
    } catch (err) {
      console.log(err);
      addErrorToast(`Creating image failed: ${err}`);
    }
  };

  return (
    <Modal close={close}>
      <Header>Create image from virtual machine</Header>
      <Body>
        <div>
          This creates image from virtual machine <em>{vmName} {dswName}</em> which
          can be used to create new virtual machines.
        </div>
        <p className={styles.warning}>
          <strong>Warning:</strong> This operation destroys original virtual machine and data disk.
          You should save any files that are not located on os disk to persistent
          storage.
        </p>
        <p className={`${styles.warning} ${styles.bold}`}>
          This virtual machine ({vmName}) is not available after this operation.
        </p>
        <FormItem>
          <FormLabel>Image name</FormLabel>
          <input
            className={formStyles.input}
            defaultValue={dswName.toLowerCase().replace(' ', '-')}
            name="imageName"
            ref={imageName}
            type="text"
          />
        </FormItem>
        <FormItem>
          <div>
            <input id="shared-image" ref={sharedImage} type="checkbox" />
            <label htmlFor="shared-image">Share image to other users</label>
          </div>
        </FormItem>
      </Body>
      <Actions>
        <Button color="secondary" onClick={close} type="button">Cancel</Button>
        <Button color="primary" onClick={handleSubmit} type="submit">Create</Button>
      </Actions>
    </Modal>
  );
};

export default CreateImage;
