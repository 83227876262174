import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { formatSize } from '../utilities/formatting';
import { updateMetadata } from '../utilities/api';
import useToaster from '../utilities/hooks/useToaster';

import Avatar from './Avatar';
import FileIcon from './files/FileIcon';
import Label from './Label';
import NavigationRight from './navigation/NavigationRight';
import DetaisPanelActions from './DetailsPanelActions';
import Tags from './files/Tags';
import DateTime from './DateTime';

import styles from './DetailsPanel.module.css';

function getFileType(file) {
  return file.isDirectory ? 'folder' : file.name.split('.').pop();
}

const ItemDescription = ({ actions, path, item, showVersionHistory }) => {
  const { addErrorToast, addSuccessToast } = useToaster();
  const [description, setDescription] = useState(item.description);
  const [tags, setTags] = useState(item.projects && JSON.parse(item.projects));
  const permissions = useSelector(state => state.permissions);

  const itemPath = `${path}/${item.name}`;
  const isRootDir = item.isDirectory && path === '';

  const saveMetadata = useRef(
    debounce(async (metadata) => {
      try {
        await updateMetadata(itemPath, metadata);
        addSuccessToast('Description updated');
      } catch (err) {
        console.log(err);
        addErrorToast(err.message);
      }
    }, 1000)
  );

  useEffect(() => {
    setDescription(item.description ?? '');
    setTags(item.projects && JSON.parse(item.projects));
  }, [item]);

  async function handleDescriptionUpdate(e) {
    setDescription(e.target.value);
    item.description = e.target.value;
    saveMetadata.current({ description: e.target.value });
  }

  async function handleTagsChange(values) {
    try {
      setTags(values);
      await updateMetadata(itemPath, { projects: JSON.stringify(values) });
      addSuccessToast('Tags updated');
    } catch (err) {
      console.log(err);
      addErrorToast(err.message);
    }
  }

  return (
    <div className={styles.column}>
      <h3 className={styles.header}>
        <FileIcon type={getFileType(item)} />
        <span className={styles.filename}>{item.name}</span>
      </h3>
      <div className={styles.details}>
        <Label>{item.isDirectory ? 'Folder' : 'File'} description</Label>
        <textarea
          className={styles.description}
          id="description"
          onChange={handleDescriptionUpdate}
          placeholder="Description goes here"
          rows="6"
          value={description}
        />

        <Label>Last modified</Label>
        {item.versions ?
          <span className={styles.clickable} onClick={showVersionHistory}>
            <DateTime date={item.lastModified} />
            {!item.isDirectory && item.last_modified_by_name && ` by ${item.last_modified_by_name}`}
          </span> :
          <span>
            <DateTime date={item.lastModified} />
            {!item.isDirectory && item.last_modified_by_name && ` by ${item.last_modified_by_name}`}
          </span>
        }
        {!item.isDirectory &&
          <>
            <Label>File size</Label>
            <span>{formatSize(item.contentLength)}</span>
          </>
        }

        {isRootDir && (
          <>
            <Label>Owner</Label>
            <div className={styles.flexCentered}>
              <Avatar name={item.owner_name || 'Unknown'} />
              {item.owner_name || 'Unknown'}
            </div>
          </>
        )}

        {!isRootDir && (
          <>
            <Label>Author</Label>
            <div className={styles.flexCentered}>
              <Avatar name={item.author_name || 'Unknown'} />
              {item.author_name || 'Unknown'}
            </div>
          </>
        )}

        {isRootDir && (
          <>
            <Label>Tags</Label>
            <Tags
              disabled={!permissions.dataSharing.admin}
              handleTagsChange={handleTagsChange}
              itemTags={tags}
            />
          </>
        )}

        {!isRootDir && (
          <>
            <Label>Path</Label>
            {itemPath}
          </>
        )}
      </div>
      <DetaisPanelActions actions={actions} item={item} />
    </div>
  );
};

const MultipleItems = ({ actions, items }) => (
  <div className={styles.column}>
    <h5 className={styles.header}>
      {items.length} {items.every(i => i.isDirectory)
        ? 'folders' : items.every(i => !i.isDirectory) ? 'files' : 'items'} selected
    </h5>
    <DetaisPanelActions actions={actions} multiple />
  </div>
);

const DetailsPanel = ({ actions, path, items, showVersionHistory }) => (
  <NavigationRight>
    {items.length !== 0 && (items.length === 1
      ? <ItemDescription actions={actions} item={items[0]} path={path} showVersionHistory={showVersionHistory} />
      : <MultipleItems actions={actions} items={items} />)}
  </NavigationRight>
);

export default DetailsPanel;
