import { useEffect, useCallback, useState } from 'react';

import SearchUsers from '../../SearchUsers';
import { searchUsers } from '../../../utilities/api';

import FormLabel from '../../FormLabel';

import styles from './RequestWorkspace.module.css';

const hasAccountName = user => user.accountName !== undefined;

const MultiUser = ({ errors, register, owner }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([owner.upn]);
  const [usernames, setUsernames] = useState([owner.username]);

  useEffect(() => {
    if (owner !== undefined) {
      setUsers([owner.upn]);
      setUsernames([owner.username]);
    }
  }, [owner]);

  const onChecked = useCallback(event => setIsOpen(event.target.checked), []);

  const userSelected = useCallback((user) => {
    if (!users.includes(user.upn)) {
      setUsers(previousUsers => [...previousUsers, user.upn]);
      setUsernames(previous => [...previous, user.accountName]);
    }
  }, [users]);

  const removeUser = upn => setUsers(previousUsers => previousUsers.filter(item => item !== upn));

  const filterUsers = user => hasAccountName(user) && !users.includes(user.upn);

  const User = ({ user }) => (
    <li>
      {user}
      {user !== owner.upn && (
        <button
          className={styles.removeUser}
          onClick={() => removeUser(user)}
          type="button"
        >
          &#10006;
        </button>
      )}
    </li>
  );

  return (
    <div className={styles.formItem}>
      <div className={styles.checkbox}>
        <input
          id="multiUser"
          name="multiUser"
          onChange={onChecked}
          ref={register}
          type="checkbox"
          value="true"
        />
        <label htmlFor="multiUser">Multi-user VM</label>
      </div>
      {isOpen && (
        <div className={styles.formItem}>
          <FormLabel>Users</FormLabel>
          {errors.users?.maxLength && <div className={styles.errorMessage}>Too many users for vm</div>}
          <ul>
            {users.map(user => <User key={user} user={user} />)}
          </ul>
          <FormLabel>Search users</FormLabel>
          <SearchUsers
            clearAfterSelect
            defaultUser={{}}
            filter={filterUsers}
            handleChange={userSelected}
            searchUsers={searchUsers}
          />
          <input
            name="users"
            ref={register({ maxLength: 256, required: true })}
            type="hidden"
            value={users.toString()}
          />
          <input
            name="usernames"
            ref={register({ required: true })}
            type="hidden"
            value={usernames.toString()}
          />
        </div>
      )}
    </div>
  );
};

export default MultiUser;
