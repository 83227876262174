import React, { useState } from 'react';
import Button from '../Button';
import FormLabel from '../FormLabel';
import FormItem from '../FormItem';

import formStyles from '../form.module.css';

import Modal, { Header, Body, Actions } from './Modal';

const CreateDirectory = ({ confirm, close }) => {
  const [dirName, setDirName] = useState('');

  const validate = () => dirName.length > 0;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    confirm(dirName);
  };

  return (
    <Modal close={close}>
      <form onSubmit={handleSubmit}>
        <Header>Create new folder</Header>
        <Body>
          <FormItem>
            <FormLabel>Folder name</FormLabel>
            <input
              autoFocus
              className={formStyles.input}
              onChange={e => setDirName(e.target.value)}
              placeholder="Folder name"
              required
              type="text"
              value={dirName}
            />
          </FormItem>
        </Body>
        <Actions>
          <Button color="secondary" onClick={close} type="button">Cancel</Button>
          <Button color="primary" type="submit">Create</Button>
        </Actions>
      </form>
    </Modal>
  );
};

export default CreateDirectory;
