import { fetchJson, fetchJsonUnauthenticated, postJson, getAuthProvider, putJson, del } from './request';

export async function getPermissions() {
  return await fetchJson('/api/user/permissions');
}

export function getUser() {
  return fetchJson('/api/user');
}

export function searchUsers(query) {
  return fetchJson(`/api/user/search?query=${query}`, ['https://graph.microsoft.com/user.readbasic.all']);
}

export function getAuthConfig() {
  return fetchJsonUnauthenticated('/auth/config');
}

export function sendFeedback(data) {
  return postJson(`/api/user/feedback`, null, data);
}

export function getUserSettings() {
  return fetchJson('/api/user/settings', [getAuthProvider().clientId]);
}

export function updateUserSettings(settings) {
  return putJson('/api/user/settings', [getAuthProvider().clientId], settings);
}

export function setUserVmStar(vmName, isStarred) {
  const path = `/api/user/star/vm/${vmName}`;
  return isStarred
    ? postJson(path, [getAuthProvider().clientId])
    : del(path, [getAuthProvider().clientId]);
}

export function setUserAppStar(appName, isStarred) {
  const path = `/api/user/star/app/${appName}`;
  return isStarred
    ? postJson(path, [getAuthProvider().clientId])
    : del(path, [getAuthProvider().clientId]);
}
