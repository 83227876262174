import React, { memo } from 'react';
import styles from './FileIcon.module.css';

function getIconName(type) {
  switch (type.toLowerCase()) {
    case 'folder':
      return type;
    case 'pdf':
      return 'file-pdf';
    case 'docx':
    case 'doc':
      return 'file-word';
    case 'csv':
    case 'xlsx':
    case 'xls':
      return 'file-excel';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
      return 'file-image';
    case 'avi':
    case 'mp4':
      return 'file-video';
    case 'txt':
      return 'file-text';
    default:
      return 'file';
  }
}
const FileIcon = ({ type }) => (
  <img alt={type} className={styles.icon} src={`/icons/${getIconName(type)}.svg`} />
);

export default memo(FileIcon);
