import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setAppStar } from '../../store/settingsSlice';
import { listApplications, getApp, setUserAppStar } from '../../utilities/api';
import useToaster from '../../utilities/hooks/useToaster';

const useApplications = () => {
  const dispatch = useDispatch();
  const [applications, setApplications] = useState([]);
  const [isLoadingApplications, setLoadingApplications] = useState(true);
  const { starredApps } = useSelector((state) => state.settings);

  const { addErrorToast } = useToaster();

  const getApplications = useCallback(async () => {
    setLoadingApplications(true);
    const list = await listApplications();

    list.forEach((app) => (app.isStarred = starredApps?.includes(app.name)));

    setApplications(list);
    setLoadingApplications(false);
  }, [starredApps]);

  const refreshItem = useCallback(
    async (item, group, get, setItems) => {
      try {
        const updatedItem = await get(item.name);
        const index = group.indexOf(group.find((x) => x.name === item.name));
        const updatedItems = [...group];

        updatedItems[index] = updatedItem;
        setItems(updatedItems);
      } catch (err) {
        addErrorToast(err.message);
      }
    },
    [addErrorToast]
  );

  const refreshApplication = useCallback(
    async (app) => {
      await refreshItem(app, applications, getApp, setApplications);
    },
    [applications, refreshItem]
  );

  function star(event, item, items) {
    event.stopPropagation();
    const updated = [...items];
    const targetItem = items.find((i) => i === item);
    targetItem.isStarred = !targetItem.isStarred;

    setUserAppStar(item.name, targetItem.isStarred);
    dispatch(setAppStar({ name: item.name, isStarred: targetItem.isStarred }));

    return updated;
  }

  function starApplication(event, item) {
    const updated = star(event, item, applications);
    setApplications(updated);
  }

  useEffect(() => {
    getApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    applications,
    getApplications,
    isLoadingApplications,
    refreshApplication,
    starApplication
  };
};

export default useApplications;
