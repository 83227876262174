import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NewTabLink.module.css';

const InternalLink = ({ url, type, disabled }) => url ? (
  <span className={styles.link} title={url}>
    <img
      alt="Icon"
      className={styles.icon}
      src={type.icon}
      style={{ filter: disabled ? 'grayscale(100%)' : 'none' }}
      title={type.name}
    />
    <Link to={url}>{type.name}</Link>
  </span>
) : false;

export default InternalLink;
