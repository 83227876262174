import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPermissions } from "../utilities/api";

export const fetchPermissions = createAsyncThunk('permissions/fetch', async () => {
  const permissions = await getPermissions();
  return permissions;
});

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    loading: true,
    dataSharing: {
      admin: false,
      user: false,
      restrictedAccess: false
    },
    dataScience: {
      user: false,
      admin: false,
      vmLocalAdmin: false,
      restricedAccess: false
    },
    groups: []
  },
  extraReducers: {
    [fetchPermissions.fulfilled]: (state, action) => ({ ...state, ...action.payload, loading: false })
  }
});

export default permissionsSlice.reducer;
