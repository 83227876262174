import React from 'react';

import { getVmSizeDescription } from '../../../utilities/formatting';

import Card from '../Card';

import cardStyles from '../Card.module.css';

const VmSizeCard = ({ name, size, description, disabled, selectSize, vmSize, specialSize }) => (
  <Card
    className={disabled ? cardStyles.disabled : undefined}
    isSelected={vmSize === size?.name && !specialSize}
    onClick={() => disabled ? undefined : selectSize(size.name)}
  >
    <img alt="Virtual machine" className={cardStyles.icon} src={'/icons/vm.svg'} />
    <h3 className={cardStyles.title}>{name}</h3>
    <div className={cardStyles.description}>{description}</div>
    <div className={cardStyles.decription}>{getVmSizeDescription(size)}</div>
  </Card>
);

export default VmSizeCard;
