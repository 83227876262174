import React, { useEffect, useRef } from 'react';
import { isTargetButtonOrLink } from '../../utilities/utils';
import styles from './MobileMenu.module.css';

const MobileMenu = ({ open, close, children }) => {
  const containerRef = useRef(undefined);

  const handleClickOutside = (event) => {
    if (containerRef.current &&
      (!containerRef.current.contains(event.target) || isTargetButtonOrLink(event))) {
      setTimeout(() => close(), 200); // Avoid closing before navigation is done
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={styles.overlay} style={{ visibility: open ? 'visible' : 'hidden' }}>
      <div className={styles.menu} ref={containerRef}>
        <button className={styles.headerMenu} onClick={close} type="button">
          <img alt="Menu" src="/icons/menu-open.svg" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default MobileMenu;
