import React from 'react';
import styles from './SortableHeader.module.css';

import { ReactComponent as Ascending } from './icons/arrow-up.svg';
import { ReactComponent as Descending } from './icons/arrow-down.svg';

const SortableHeader = ({ className, name, onClick, order, active }) => (
  <th className={`${styles.sortable} ${className || ''} ${active ? styles.active : ''}`} onClick={onClick}>
    {name}
    {order === 'asc' ?
      <Ascending className={styles.sortIcon} /> :
      <Descending className={styles.sortIcon} />
    }
  </th>
);

export default SortableHeader;
