import React from 'react';
import { Provider } from 'react-redux';

import App from './App';

import store from './store';
import * as api from './utilities/api';

const authProvider = {
  getAccessToken: () => 'token',
  getProviderOptions: () => ({}),
  acquireTokenSilent: () => ({})
};

const accountInfo = {
  account: {
    userName: 'Test User'
  }
};

const TestAuthentication = () => {
  api.setAuthProvider(authProvider);

  function logout() {
    window.localStorage.clear();
    document.cookie = 'accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;';
    window.location = '/';
  }

  return (
    <Provider store={store}>
      <App
        accountInfo={accountInfo}
        authenticated
        getAccessToken={authProvider.getAccessToken}
        logout={logout}
      />
    </Provider>
  );
};

export default TestAuthentication;
