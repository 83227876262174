import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import Button from '../Button';
import FormItem from '../FormItem';
import FormLabel from '../FormLabel';
import Spinner from '../Spinner';

import { getAvailableSizesForVm, getAvailableConfigurations } from '../../utilities/api';
import { toVmSizeOption } from '../../utilities/formatting';

import Modal, { Header, Body, Actions } from './Modal';

import styles from './Modal.module.css';

const ResizeVm = ({ close, item, resizeVm }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sizes, setSizes] = useState([]);
  const [newSize, setNewSize] = useState(item.vmSize);

  useEffect(() => {
    const getSizes = async () => {
      const data = await getAvailableSizesForVm(item.name);
      const { configurations } = await getAvailableConfigurations();
      const sizes = data.map(size => toVmSizeOption(configurations[size.name]));
      setSizes(sizes);
      setNewSize(sizes.find(x => x.value === item.vmSize)?.value);
      setIsLoading(false);
    };

    getSizes();
  }, [item]);

  const resizeDisabled = isLoading || newSize === item.vmSize;

  const resize = () => {
    resizeVm(item.name, newSize);
    close();
  };

  const selectStyle = { height: 46, minWidth: 450 };

  return (
    <Modal close={close}>
      <Header>Resize virtual machine</Header>
      <Body>
        <FormItem>
          <FormLabel>Name</FormLabel>
          {item.tags?.dswname} ({item.name})
        </FormItem>
        <FormItem>
          <FormLabel>Owner</FormLabel>
          {item.tags?.owner_name}
        </FormItem>
        <FormItem>
          <FormLabel>Current size</FormLabel>
          {isLoading ? item.vmSize : sizes.find(x => x.value === item.vmSize)?.label}
        </FormItem>
        <div className={styles.selectArea}>
          <FormLabel>New size</FormLabel>
          {sizes.length > 0 ?
            <Select
              isClearable={false}
              isOptionDisabled={option => option.disabled}
              menuPlacement="top"
              onChange={({ value }) => setNewSize(value)}
              options={sizes}
              styles={{
                control: styles => ({
                  ...styles,
                  border: styles.border,
                  ...selectStyle
                }),
                menuList: styles => ({
                  ...styles,
                  maxHeight: 4 * 37
                })
              }}
              value={sizes.find(x => x.value === newSize)}
            /> :
            <div style={selectStyle}>
              <Spinner />
            </div>
          }
        </div>

      </Body>
      <Actions>
        <Button color="secondary" onClick={close} type="button">Cancel</Button>
        <Button
          color="primary"
          disabled={resizeDisabled}
          onClick={resize}
          title={isLoading ? 'Loading size options, please wait' : 'Click to resize VM'}
          type="submit"
        >
          Resize
        </Button>
      </Actions>
    </Modal>
  );
};

export default ResizeVm;
