import { useEffect, useRef, useState } from 'react';

const useDropzone = (addFolderUpload, canWrite) => {
  const isFileFromOS = useRef(true);
  const hasDraggedOutsideOfWindow = useRef(false);
  const cachedTarget = useRef(null);
  const [dragActive, setDragActive] = useState(false);

  const handleDragStart = () => {
    isFileFromOS.current = false;
    hasDraggedOutsideOfWindow.current = false;
  };

  const handleDragEnter = (e) => {
    cachedTarget.current = e.target;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    // If document still has focus, that means the user never dropped the file.
    if (hasDraggedOutsideOfWindow.current && document.hasFocus()) {

    } else if (isFileFromOS.current) {
      hasDraggedOutsideOfWindow.current = false;

      if (!dragActive) {
        setDragActive(true);
      }
    }
  };

  const handleDragLeave = (e) => {
    if (e.target === cachedTarget.current) {
      if (isFileFromOS.current && !hasDraggedOutsideOfWindow.current) {
        if (dragActive) {
          setDragActive(false);
        }
      } else {
        hasDraggedOutsideOfWindow.current = true;
        isFileFromOS.current = true;
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    isFileFromOS.current = true;
    hasDraggedOutsideOfWindow.current = false;

    if (dragActive) {
      setDragActive(false);
    }

    if (e.dataTransfer.items) {
      const items = [...e.dataTransfer.items].map(item => item.webkitGetAsEntry());
      addFolderUpload(items);
    }
  };

  useEffect(() => {
    if (canWrite) {
      window.addEventListener('dragstart', handleDragStart);
      window.addEventListener('dragenter', handleDragEnter);
      window.addEventListener('dragover', handleDragOver);
      window.addEventListener('dragleave', handleDragLeave);
      window.addEventListener('drop', handleDrop);
    }
    return () => {
      if (canWrite) {
        window.removeEventListener('dragstart', handleDragStart);
        window.removeEventListener('dragenter', handleDragEnter);
        window.removeEventListener('dragover', handleDragOver);
        window.removeEventListener('dragleave', handleDragLeave);
        window.removeEventListener('drop', handleDrop);
      }
    };
  });

  return dragActive;
};

export default useDropzone;
