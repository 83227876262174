import React from 'react';
import { Link } from 'react-router-dom';
import {camelize} from '../utilities/utils';
import styles from './Logo.module.css';

/* eslint-disable max-len */
const Logo = ({ id, label }) => (
  <Link to={`/${id}`}>
    <svg className={`${styles.logo} ${styles[camelize(id)]}`} height="86" viewBox="0 0 138 86" width="138" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path d="M.011.182h137.803V38H.01z" id="a" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M71.149 36.383h6.468V18.596h-6.468zM54.467 31.756h-4.39l.02 4.627H43.66V18.596h6.436c8.736 0 8.716.014 8.716.014h.18c4.355 0 7.885 2.952 7.885 6.593 0 2.831-2.225 5.222-5.262 6.144l5.491 5.023-9.02.002-3.619-4.616zm-4.39-4.706h7.917c1.077-.109 1.912-.852 1.912-1.75s-.786-1.674-1.857-1.789h-7.973v3.539zM130.27 18.596h6.368v17.787h-6.369l-10.71-9.612v9.612h-6.368V18.596h6.368l10.71 9.492v-9.492" fill="#fff" />
        <g>
          <path d="M89.075 27.893c0-1.891 2.761-3.422 6.166-3.422 3.407 0 6.169 1.53 6.169 3.422 0 1.889-2.762 3.422-6.169 3.422-3.405 0-6.166-1.533-6.166-3.422m6.166 9.218c7.519 0 13.612-4.128 13.612-9.218 0-5.093-6.093-9.222-13.612-9.222-7.516 0-13.61 4.13-13.61 9.222 0 5.09 6.094 9.218 13.61 9.218" fill="#fff" mask="url(#b)" />
          <path d="M19.911 29.48c5.249 0 9.504-2.044 9.504-4.565 0-2.523-4.255-4.564-9.504-4.564-5.25 0-9.505 2.041-9.505 4.564 0 2.52 4.256 4.564 9.505 4.564M.726 21.923c2.136-5.354 8.482-9.267 12.796-11.132 9.316-4.025 19.115-6.389 28.91-8.042C52.962.972 58.644.755 69.065.396c12.87-.44 20.178-.257 33.008 1.063 13.28 1.365 24.114 3.844 34.586 6.966l1.155 3.441c-11.837-2.655-21.097-4.11-32.63-5.02-12.395-.978-18.837-.946-30.655-.38-19.362.928-33.539 4.249-43.948 8.107 5.525 2.267 9.24 6.235 9.24 10.754 0 7-8.914 12.673-19.91 12.673S0 32.327 0 25.327c0-1.178.253-2.32.726-3.404" fill="#fff" />
        </g>
        <text fill="#fff" fontFamily="TrebuchetMS, Trebuchet MS" fontSize="16">
          <tspan textAnchor="middle" x="50%" y="62">{label}</tspan>
        </text>
        <path d="M26.5 68.5h86" stroke="currentColor" strokeLinecap="square" strokeWidth="3" />
      </g>
    </svg>
  </Link>
);
/* eslint-enable */

export default Logo;
