import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getTags, extractTags } from '../utilities/utils';

import Logo from './Logo';
import NavigationLeft from './navigation/NavigationLeft';
import NavigationList from './navigation/NavigationList';
import OrderAndFilter from './OrderAndFilter';
import ProjectSearch from './ProjectSearch';
import SkeletonLinks from './SkeletonLinks';

import styles from './navigation/NavigationList.module.css';

const prefix = '/data-sharing';

const sortByFavorite = (favoriteProjects = []) => (a, b) => {
  const isFavorited = project => favoriteProjects.includes(project.name);

  if (isFavorited(a) && !isFavorited(b)) {
    return -1;
  }

  if (!isFavorited(a) && isFavorited(b)) {
    return 1;
  }

  return 0;
};

const ProjectList = ({ accountInfo, logout, content, isLoading }) => {
  const [items, setItems] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [order, setOrder] = useState('asc');
  const [useFilter, setUseFilter] = useState(false);
  const [tags, setTags] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const permissions = useSelector(state => state.permissions);
  const { favoriteProjects } = useSelector(state => state.settings);

  useEffect(() => {
    if (content) {
      setItems([...content].sort(sortByFavorite(favoriteProjects)));
      setTags(extractTags(content));
    }
  }, [content, favoriteProjects]);

  const shownFolders = items
    .filter(x => x.name.toLowerCase().includes(searchInput.toLowerCase()))
    .filter(x => useFilter ? getTags(x).some(x => activeFilters.includes(x)) : x) || [];

  function changeOrder() {
    const direction = order === 'asc' ? 'desc' : 'asc';
    setOrder(direction);
    const orderedItems = [...items];
    orderedItems.sort((a, b) => direction === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
    orderedItems.sort(sortByFavorite(favoriteProjects));
    setItems(orderedItems);
  }

  function handleFiltering() {
    if (useFilter) {
      setActiveFilters([]);
    } else {
      setActiveFilters(tags);
    }

    setUseFilter(!useFilter);
  }

  function removeFilter(filter) {
    const filters = activeFilters.filter(f => f !== filter);
    setActiveFilters(filters);
    if (filters.length === 0) {
      setUseFilter(false);
    }
  }

  function renderItems() {
    return (
      <>
        <NavigationList
          centered
          items={shownFolders.map(x => ({ label: x.name, value: `${prefix}/${encodeURIComponent(x.name)}` }))}
        />
        {shownFolders.length === 0 &&
          <span className={styles.notFound}>
            {searchInput.length === 0 ? 'No assigned projects' : 'No projects found with current search'}
          </span>
        }
      </>
    );
  }

  function handleChange(e) {
    const value = e.target.value;

    setSearchInput(value);
  }

  return (
    <NavigationLeft accountInfo={accountInfo} logout={logout} permissions={permissions}>
      <Logo id="data-sharing" label="Data Sharing" />
      <ProjectSearch onChange={handleChange} value={searchInput} />
      <OrderAndFilter
        activeFilters={activeFilters}
        changeOrder={changeOrder}
        handleFiltering={handleFiltering}
        order={order}
        removeFilter={removeFilter}
        useFilter={useFilter}
      />
      {isLoading ? <SkeletonLinks /> : renderItems()}
      {!isLoading && permissions.dataSharing?.admin &&
        <NavigationList
          centered
          items={[
            {
              value: `${prefix}/admin`,
              label: 'Administration',
              items: [
                { value: `${prefix}/admin/new-project`, label: 'Create new project' },
                { value: `${prefix}/admin/project-groups`, label: 'Review project permissions' }
              ]
            }]}
        />
      }
    </NavigationLeft >
  );
};

export default ProjectList;
