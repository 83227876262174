import React, { useState } from 'react';

import { validateSshKey } from '../../../utilities/utils';
import formStyles from '../../form.module.css';

import styles from './RequestWorkspace.module.css';

const SshKey = ({ errors, sshKey, register }) => {
  const [useSshKey, setUseSshKey] = useState(false);

  return (
    <>
      <div className={styles.formItem}>
        <div className={styles.checkbox}>
          <input checked={useSshKey} id="useSshKey" onChange={() => setUseSshKey(!useSshKey)} type="checkbox" />
          <label htmlFor="useSshKey">Use SSH public key</label>
        </div>
      </div>
      {useSshKey &&
        <div className={styles.formItem}>
          {errors.sshKey && (
            <div className={`${styles.error} ${styles.marginBottom}`}>
              SSH key should be in single line format <strong>ssh-rsa AABBcc...</strong>
            </div>
          )}
          <textarea
            className={`${formStyles.input} ${errors.sshKey && formStyles.error}`}
            defaultValue={sshKey}
            name="sshKey"
            placeholder="SSH public key"
            ref={register({ validate: validateSshKey })}
            rows="4"
          />
          <div className={styles.error} />
        </div>}
    </>
  );
};

export default SshKey;
