import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Dashboard from '../components/files/admin/Dashboard';
import NewProject from '../components/files/admin/NewProject';
import ProjectGroups from '../components/files/admin/ProjectGroups';

const DataSharingAdmin = ({ files, accountInfo }) => {
  const permissions = useSelector(state => state.permissions);

  if (!permissions.dataSharing.admin) {
    return <Redirect to="/data-sharing" />;
  }

  return (
    <Switch>
      <Route exact path="/data-sharing/admin">
        <Dashboard files={files} />
      </Route>
      <Route exact path="/data-sharing/admin/new-project">
        <NewProject accountInfo={accountInfo} files={files} />
      </Route>
      <Route exact path="/data-sharing/admin/project-groups">
        <ProjectGroups files={files} />
      </Route>
    </Switch>
  );
};

export default DataSharingAdmin;
