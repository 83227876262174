import { fetchJson, putJson, del } from './request';

export function listApplications() {
  return fetchJson('/api/apps?type=running');
}

export function listAvailableApps() {
  return fetchJson('/api/apps?type=available');
}

export function publishApp(name, image, tag, displayName, groups) {
  return putJson(`/api/apps/${name}`, null, { displayName, image, tag, groups });
}

export function deleteApp(name) {
  return del(`/api/apps/${name}`);
}

export function getApp(name) {
  return fetchJson(`/api/apps/${name}`);
}
