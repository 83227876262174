import React, { useEffect, useRef } from 'react';
import { subSeconds, isAfter } from 'date-fns';
import styles from './UploadStatus.module.css';

const FileStatus = ({ file, cancelUpload }) => {
  const speeds = useRef([]);

  useEffect(() => {
    if (file.uploadSpeed !== undefined) {
      const tenSecondsAgo = subSeconds(new Date(), 10);

      speeds.current = [
        ...speeds.current.filter(value => isAfter(value.time, tenSecondsAgo)),
        { time: new Date(), speed: file.uploadSpeed }
      ];
    }
  });

  const avgSpeed = speeds.current.reduce((acc, val) => acc + val.speed, 0) / speeds.current.length || 0;

  return (
    <div className={styles.file}>
      <img alt="Upload in progress" className={styles.progressIcon} src="/icons/uploading.svg" />
      <span className={styles.filename} title={file.name}>{file.name}</span>
      <ProgressBar progress={file.progress} uploadSpeed={avgSpeed} />
      <button className={styles.cancelButton} onClick={() => cancelUpload(file)} type="button" />
    </div>
  );
};

const ProgressBar = ({ onClick, progress = 0, uploadSpeed }) => (
  <div className={styles.progress} onClick={onClick}>
    <div className={styles.progressAmount} style={{ width: `${progress}%` }}>
      {`${progress.toFixed(1)} %`}
      {uploadSpeed && ` - ${uploadSpeed.toFixed(2)} Mb/s`}
    </div>
  </div>
);

const UploadStatus = ({ files, cancelUpload }) => (
  <div className={styles.container} style={{ height: files.length * 68 }}>
    {files.map(file =>
      <FileStatus
        cancelUpload={cancelUpload}
        file={file}
        key={file.webkitRelativePath || file.name}
      />
    )}
  </div>
);

export default UploadStatus;
