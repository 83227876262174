import React from 'react';

import Label from '../../Label';
import Tooltip from '../../Tooltip';
import CopyableText from '../../CopyableText';
import Avatar from '../../Avatar';

import { formatVmSize, getVmSizeDescription } from '../../../utilities/formatting';

import styles from '../../DetailsPanel.module.css';
import DateTime from '../../DateTime';

import WorkspaceLinks from './WorkspaceLinks';

export const CreationDate = ({ item }) => (
  <>
    {item.creationDate &&
      <>
        <Label>Created at</Label>
        <span>
          <DateTime date={item.creationDate} />
        </span>
      </>
    }
  </>
);

export const Size = ({ item, setIsResizing, vmSizeDetails }) => {
  if (item.vmSize === 'Container') {
    return (
      <>
        <Label>Size</Label>
        <span>Container</span>
      </>
    );
  } else {
    return (
      <>
        <Label>Size</Label>
        <div
          className={`${styles.resizeable}`}
          onClick={() => setIsResizing(true)}
          title="Click to resize"
        >
          <Tooltip content={getVmSizeDescription(vmSizeDetails)}>
            <span>
              {formatVmSize(item.vmSize)}
            </span>
          </Tooltip>
        </div>
      </>
    );
  }
};

export const UserName = ({ item }) => (
  <>
    {item.tags?.username &&
      <>
        <Label>Username</Label>
        <CopyableText>{item.tags.username}</CopyableText>
      </>
    }
  </>
);

export const CostCenter = ({ item }) => (
  <>
    {item.tags?.costcenter &&
      <>
        <Label>Cost center</Label>
        <span>{item.tags.costcenter}</span>
      </>
    }
  </>
);

export const OwnerName = ({ item }) => (
  <>
    {item.tags?.owner_name &&
      <>
        <Label>Owner</Label>
        <div className={styles.flexCentered}>
          <Avatar name={item.tags?.owner_name} />
          <span>{item.tags.owner_name}</span>
        </div>
      </>
    }
  </>
);

export const WbsCode = ({ item }) => (
  <>
    {item.tags?.wbscode &&
      <>
        <Label>WBS code</Label>
        <span>{item.tags.wbscode}</span>
      </>
    }
  </>
);

export const Image = ({ item }) => (
  <>
    {item.osType && item.image &&
      <>
        <Label>Image</Label>
        <span className={styles.longText}>{item.osType} / {item.image.split('_')[1] || item.image}</span>
      </>
    }
    {item.image && item.imageTag &&
      <>
        <Label>Image</Label>
        <span className={styles.longText}>{item.image.split('/')[1]}/{item.imageTag}</span>
      </>
    }
  </>
);

export const Groups = ({ item, groups }) => (
  <>
    {item.groups && item.groups.length > 0 &&
      <>
        <Label>Assigned to groups</Label>
        {item.groups.map(group =>
          <span className={styles.longText} key={group}>
            {groups?.find(x => x.id === group).name}
          </span>
        )}
      </>
    }
  </>
);

export const Services = ({ itemIsAvailable, itemIsWorkspace, admin, item, status }) => (
  <>
    {itemIsAvailable && !(itemIsWorkspace && admin) &&
      <>
        <Label>Services</Label>
        <WorkspaceLinks item={item} status={status} />
      </>
    }
  </>
);
