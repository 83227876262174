import React from 'react';

import Breadcrumb from '../components/Breadcrumb';
import Content from '../components/Content';
import GridContainer from '../components/GridContainer';
import Navigation from '../components/navigation/Navigation';

import { generateRoute } from '../utilities/utils';
import Button from '../components/Button';

import { ReactComponent as Add } from '../components/icons/add.svg';

const DesignSystem = () => (
  <GridContainer>
    <Navigation items={[{ value: '/design-system', label: 'Fonts' }]} label="Design" logoId="design-system" />
    <Content>
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
      <p>
        This is a paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Cras ut est non dolor aliquam bibendum sit amet non ex.
        Praesent eget eleifend ex, in tincidunt tellus.
        Nulla sit amet velit feugiat, dapibus tortor et, tempus odio.
        Vestibulum convallis eros dui, at vehicula mi dignissim sit amet.
        Aliquam malesuada cursus mauris, in dapibus nunc tincidunt sit amet.
        Duis semper dui sit amet fermentum ullamcorper. Duis at porttitor augue, imperdiet tincidunt turpis.
        Etiam sit amet semper dolor. Quisque et orci pellentesque, euismod felis in, tincidunt nisi.
        Sed in interdum ipsum. Nulla eu gravida nulla. Nullam pharetra hendrerit libero eget imperdiet.
        Suspendisse eget faucibus risus. Sed condimentum massa eu ex mollis, in porttitor massa congue.
        Suspendisse condimentum felis ut nulla cursus, eget ornare odio commodo.
      </p>
      <span>This is an example span</span>
      <pre>This is preformatted text</pre>
      <Breadcrumb clickHandler={() => {}} prefix="/" route={generateRoute("/this-is/a-test-path")} />
      <br/>
      <Button color="primary">Primary button</Button>
      <Button color="secondary">Secondary button</Button>
      <Button color="new">
        <Add />
        New button
      </Button>
    </Content>
  </GridContainer >
);

export default DesignSystem;
