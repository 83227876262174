import React, { useState } from 'react';

import useInterval from '../utilities/hooks/useInterval';

import Card from './datascience/Card';
import cardStyles from './datascience/Card.module.css';
import styles from './Skeleton.module.css';

const SkeletonCards = ({ amount = 3 }) => {
  const [shownAmount, setShownAmount] = useState(0);
  const [timeout, setTimeout] = useState(500);

  useInterval(() => {
    if (shownAmount < amount) {
      setShownAmount(x => x + 1);
    } else {
      setTimeout(0);
    }
  }, timeout);

  const list = [];

  for (let i = 0; i < shownAmount; i++) {
    list.push(
      <Card key={i} withBackground={false}>
        <button className={cardStyles.star} type="button">
          <div className={styles.icon} />
        </button>
        <div className={cardStyles.status}>
          <span className={styles.textSmall} />
          <div className={styles.iconSmall} />
        </div>
        <div className={styles.image} />
        <h5 className={cardStyles.title}>
          <span className={styles.text} />
        </h5>
        <div className={cardStyles.description}>
          <span className={styles.text} />
          <span className={styles.text} />
        </div>
      </Card>
    );
  }

  return list;
};

export default SkeletonCards;
