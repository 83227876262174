import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Applications from '../components/datascience/admin/Applications';
import Dashboard from '../components/datascience/admin/Dashboard';
import Settings from '../components/datascience/admin/Settings';
import VirtualMachines from '../components/datascience/admin/VirtualMachines';
import Workspaces from '../components/datascience/admin/Workspaces';
import Images from '../components/datascience/admin/Images';

import useApplications from '../utilities/hooks/useApplications';
import useVirtualMachines from '../utilities/hooks/useVirtualMachines';
import useWorkspaces from '../utilities/hooks/useWorkspaces';

const DataScienceAdmin = () => {
  const applications = useApplications();
  const virtualMachines = useVirtualMachines(true);
  const workspaces = useWorkspaces(true);
  const permissions = useSelector(state => state.permissions);

  if (!permissions.dataScience.admin) {
    return <Redirect to="/data-science" />;
  }

  return (
    <Switch>
      <Route exact path="/data-science/admin">
        <Dashboard
          useApplications={applications}
          useVirtualMachines={virtualMachines}
          useWorkspaces={workspaces}
        />
      </Route>
      <Route exact path="/data-science/admin/workspaces">
        <Workspaces useWorkspaces={workspaces} />
      </Route>
      <Route exact path="/data-science/admin/virtual-machines">
        <VirtualMachines useVirtualMachines={virtualMachines} />
      </Route>
      <Route exact path="/data-science/admin/applications">
        <Applications useApplications={applications} />
      </Route>
      <Route component={Images} exact path="/data-science/admin/images" />
      <Route component={Settings} exact path="/data-science/admin/settings" />
      <Redirect to="/data-science" />
    </Switch>
  );
};

export default DataScienceAdmin;
