import { format, formatDistance } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function formatDate(date, timezone) {
  const dateFormat = 'yyyy-MM-dd HH:mm';

  try {
    const zonedDate = utcToZonedTime(new Date(date), timezone);
    return format(zonedDate, dateFormat);
  } catch (err) {
    if (err.message.match(/^Invalid time zone specified/)) {
      return format(new Date(date), dateFormat);
    }

    console.log(err);
    return '';
  }
}

export function formatDuration(date) {
  try {
    return formatDistance(new Date(date), new Date());
  } catch (err) {
    console.log(err);
    return '';
  }
}

export function round(number) {
  return Math.round(number * 100) / 100;
}

export function formatSize(size) {
  if (size < 1000) {
    return `${size} B`;
  } else if (size < 1000000) {
    return `${round(size / 1000)} KB`;
  } else if (size < 1000000000) {
    return `${round(size / 1000000)} MB`;
  } else {
    return `${round(size / 1000000000)} GB`;
  }
}

export function formatVmSize(size) {
  return size
    .replace(/Standard_|Basic_/, '')
    .replace('_', ' ');
}

export function formatVersionTimestamp(date) {
  return new Date(date).toISOString()
    .replace(/T/, '')
    .replace(/\..+/, '')
    .replace(/:/g, '')
    .replace(/-/g, '');
}

export const getVmSizeDescription = (size) => {
  if (size) {
    if (size.GPUs) {
      return `${size.vCPUs} vCPUs / ${size.MemoryGB} GB / ${size.GPUs} GPUs - ${round(size.price)} €/h`;
    } else {
      return `${size.vCPUs} vCPUs / ${size.MemoryGB} GB - ${round(size.price)} €/h`;
    }
  }
};

export const toVmSizeOption = vmSize => ({
  value: vmSize?.name,
  label: `${vmSize?.name} - ${getVmSizeDescription(vmSize)}`,
  disabled: vmSize?.disabled
});
