import { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVirtualMachineStar } from '../../store/settingsSlice';

import useToaster from '../../utilities/hooks/useToaster';
import {
  listVMs,
  listAllVMs,
  getVmStatus,
  getVM,
  createVM,
  updateVmMetadata,
  resizeVm,
  setUserVmStar
} from '../api';

const useVms = (isAdmin = false) => {
  const dispatch = useDispatch();
  const { addErrorToast, addSuccessToast } = useToaster();
  const [virtualMachines, setVirtualMachines] = useState([]);
  const [isLoadingVms, setLoadingVms] = useState(true);
  const addErrorToastRef = useRef(addErrorToast);
  const { starredVms } = useSelector(state => state.settings);

  const updateVmStatuses = useCallback(async (items) => {
    items.forEach(async (vm) => {
      let vmStatus = { status: 'unavailable' };

      try {
        vmStatus = await getVmStatus(vm.name);
      } catch (err) {
        console.log('Unable to retrieve status for vm', vm.name, err);
      }

      setVirtualMachines((vms) => {
        const items = [...vms];
        const index = items.findIndex(x => x.name === vm.name);

        if (items[index]) {
          const updatedVm = {
            ...items[index],
            ...vmStatus
          };
          items[index] = updatedVm;
        } else {
          console.log(`Tried to update status for ${vm.name}, but not found any more`);
        }

        return items;
      });
    });
  }, []);

  const fetchVms = useCallback(async () => {
    try {
      setLoadingVms(true);
      const machines = isAdmin ? await listAllVMs() : await listVMs();

      machines.forEach(vm => (vm.isStarred = starredVms?.includes(vm.name)));

      setVirtualMachines(machines);
      setLoadingVms(false);

      await updateVmStatuses(machines);
    } catch (err) {
      console.log(err);
      addErrorToastRef.current(err.message);
    }
  }, [updateVmStatuses, isAdmin, addErrorToastRef, starredVms]);

  const refreshVm = useCallback(
    async (vm) => {
      const updatedVm = {
        ...(await getVM(vm.name)),
        ...(await getVmStatus(vm.name))
      };

      const index = virtualMachines.indexOf(virtualMachines.find(x => x.name === updatedVm.name));
      const updatedVirtualMachines = [...virtualMachines];
      updatedVirtualMachines[index] = updatedVm;

      setVirtualMachines(updatedVirtualMachines);
    },
    [virtualMachines]
  );

  const createVirtualMachine = async (data) => {
    try {
      await createVM({
        ...data,
        vmSize: data.vmSize.value,
        users: data.users?.split(','),
        usernames: data.usernames?.split(',')
      });
      addSuccessToast('Virtual machine created');
    } catch (err) {
      console.error(`${err.message}\n${err.body}`);
      addErrorToast(`${err.message}\n${err.body}`, { autoDismiss: false });
    }

    fetchVms();
  };

  const updateMetadata = async (vmName, propertyName, value) => {
    try {
      await updateVmMetadata(vmName, propertyName, value);
    } catch (err) {
      addErrorToast('Failed to update VM details');
      console.log(err);
    }
  };

  const resize = async (vmName, newSize) => {
    try {
      addSuccessToast('Resizing virtual machine...');
      await resizeVm(vmName, newSize);
      addSuccessToast('Virtual machine resized.');
    } catch (err) {
      console.error(`${err.message}\n${err.body}`);
      addErrorToast(`Failed to resize VM.\n${err.message}\n${err.body}`);
    }
  };

  const setVmStar = async (vmName, isStarred) => {
    try {
      await setUserVmStar(vmName, isStarred);
      dispatch(setVirtualMachineStar({ name: vmName, isStarred: isStarred }));
    } catch (error) {
      console.error(error);
      addErrorToast('Failed to toggle star');
    }
  };

  useEffect(() => {
    fetchVms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    createVirtualMachine,
    fetchVms,
    refreshVm,
    setVirtualMachines,
    updateMetadata,
    updateVmStatuses,
    resize,
    setVmStar,
    isLoadingVms,
    virtualMachines
  };
};

export default useVms;
