import React, { useState } from 'react';

import FormLabel from '../../FormLabel';

import formStyles from '../../form.module.css';
import styles from './RequestWorkspace.module.css';

const CostAllocation = ({ register }) => {
  const [allocateCosts, setAllocateCosts] = useState(false);

  return (
    <div className={`${styles.formItem} ${styles.dividerAfter}`}>
      <div className={styles.checkbox}>
        <input
          checked={allocateCosts}
          id="allocateCosts"
          onChange={() => setAllocateCosts(!allocateCosts)}
          type="checkbox"
        />
        <label htmlFor="allocateCosts">
          Allocate costs to specific cost center & WBS code.
        </label>
      </div>
      {allocateCosts && (
        <>
          <div className={styles.row}>
            <div className={styles.left}>
              <FormLabel>Cost center (optional)</FormLabel>
              <input className={formStyles.input} name="costCenter" ref={register} type="text" />
            </div>
            <div className={styles.right}>
              <FormLabel>WBS code (optional)</FormLabel>
              <input className={formStyles.input} name="wbsCode" ref={register} type="text" />
            </div>
          </div>
          <div className={styles.infoText}>
            If costs need to be allocated to a specific project, fill in the relevant cost center and WBS codes.
            Otherwise leave the fields blank to use the default values.
          </div>
        </>
      )}
    </div>
  );
};

export default CostAllocation;
