import React from 'react';

import DateTime from '../DateTime';

import Button from '../Button';
import Modal, { Header, Body, Actions } from './Modal';
import styles from './Modal.module.css';

const DeleteModal = ({ close, confirm, header, children }) => (
  <Modal close={close}>
    <Header>{header}</Header>
    <Body>{children}</Body>
    <Actions>
      <Button color="secondary" onClick={close}>Cancel</Button>
      <Button color="primary" onClick={confirm}>Delete</Button>
    </Actions>
  </Modal>
);

const ConfirmSingleItemDelete = ({ item, confirm, close }) => (
  <DeleteModal
    close={close}
    confirm={confirm}
    header={`Delete ${item.isDirectory ? 'folder' : 'file'}?`}
  >
    Are you sure you want to delete {item.isDirectory ? 'folder' : 'file'} <b>{item.name}</b>?
  </DeleteModal>
);

const ConfirmMultipleItemDelete = ({ items, confirm, close }) => (
  <DeleteModal close={close} confirm={confirm} header={`Delete ${items.length} items?`}>
    Are you sure you want to delete following {items.length} items?
    <ul className={styles.fileList}>
      {items.sort((a, b) => a.name.localeCompare(b.name)).map(item => <li key={item.name}>{item.name}</li>)}
    </ul>
  </DeleteModal>
);

const ConfirmVersionDelete = ({ item, confirm, close }) => (
  <DeleteModal close={close} confirm={() => confirm(item)} header="Delete version?">
    Are you sure you want to delete version of <b>{item.currentVersionName}</b> created at
    {' '} <DateTime date={item.creationTime} />?
  </DeleteModal>
);

const ConfirmDelete = ({ items, confirm, close, version }) => {
  if (version) {
    return <ConfirmVersionDelete close={close} confirm={confirm} item={items[0]} />;
  } else if (items.length === 1) {
    return <ConfirmSingleItemDelete close={close} confirm={confirm} item={items[0]} />;
  } else {
    return <ConfirmMultipleItemDelete close={close} confirm={confirm} items={items} />;
  }
};

export default ConfirmDelete;
