import React, { useState } from 'react';

import Button from '../Button';
import FormLabel from '../FormLabel';
import FormItem from '../FormItem';
import formStyles from '../form.module.css';

import Modal, { Header, Body, Actions } from './Modal';

const Rename = ({ item, confirm, close }) => {
  const originalName = item.type === 'vm' ? item.dswName : item.name;
  const [name, setName] = useState(originalName);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name !== originalName && name !== '') {
      confirm(name);
    }
  };

  const itemType = item.isDirectory ? 'Folder' : item.type === 'vm' ? 'Virtual machine' : 'File';

  return (
    <Modal close={close}>
      <form onSubmit={handleSubmit}>
        <Header>Rename {itemType.toLowerCase()}?</Header>
        <Body>
          <FormItem>
            <FormLabel>{itemType} name</FormLabel>
            <input
              autoCorrect="off"
              autoFocus
              className={formStyles.input}
              name="renameInput"
              onChange={event => setName(event.target.value)}
              required
              size="50"
              type="text"
              value={name}
            />
          </FormItem>
        </Body>
        <Actions>
          <Button color="secondary" onClick={close} type="button">Cancel</Button>
          <Button color="primary" disabled={name === originalName} type="submit">Rename</Button>
        </Actions>
      </form>
    </Modal>
  );
};

export default Rename;
