import React from 'react';
import { useLocation } from 'react-router-dom';

import Tree from '../Tree';

import styles from './NavigationList.module.css';

const NavigationList = ({ items, centered }) => {
  const location = useLocation();

  const isCurrent = url => location.pathname.includes(url);
  const isExact = url => location.pathname === url;

  return (
    <div className={styles.list} style={centered && { width: 'auto' }}>
      {items.map(item => (
        <Tree
          active={isExact(item.value)}
          defaultOpen={isCurrent(item.value)}
          key={item.label}
          name={item.label}
          to={item.value}
        >
          {item.items?.map(subItem => (
            <Tree
              active={isExact(subItem.value)}
              key={subItem.label}
              name={subItem.label}
              to={subItem.value}
            />
          ))}
        </Tree>
      ))}
    </div>
  );
};

export default NavigationList;
