import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Person } from './icons/person.svg';
import SignoutButton from './SignoutButton';
import styles from './AccountInfo.module.css';

const AccountInfo = ({ accountInfo, logout }) => (
  <div className={styles.accountInfo}>
    <div className={styles.column}>
      <Link to="/account">
        <div className={styles.profile}>
          <Person />
          <span className={styles.username}>{accountInfo.account.userName}</span>
        </div>
      </Link>
      <SignoutButton logout={logout} />
    </div>
  </div>
);

export default AccountInfo;
