import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Breadcrumb from '../Breadcrumb';
import ConfirmDelete from '../modals/ConfirmDelete';
import Content from '../Content';
import DetailsPanel from '../DetailsPanel';
import RefreshButton from '../RefreshButton';
import Spinner from '../Spinner';

import CreateDirectory from '../modals/CreateDirectory';
import MoveAndCopy from '../modals/MoveAndCopy';
import Preview from '../modals/Preview';
import Rename from '../modals/Rename';
import VersionHistory from '../modals/VersionHistory';

import { getFile, getFiles, deleteFile, rename, restoreVersion, deleteFolderCache } from '../../utilities/api';
import useDropzone from '../../utilities/hooks/useDropzone';
import useMediaQuery from '../../utilities/hooks/useMediaQuery';
import useUploader from '../../utilities/hooks/useUploader';
import { generateRoute, encodePath } from '../../utilities/utils';
import useToaster from '../../utilities/hooks/useToaster';

import Dropzone from './Dropzone';
import FileTable from './FileTable';
import New from './New';
import UploadStatus from './UploadStatus';
import Search from './Search';

import styles from './FileBrowser.module.css';

const FileBrowser = ({ files }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmVersionDelete, setConfirmVersionDelete] = useState(false);
  const [confirmFileRename, setConfirmFileRename] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [versionHistory, setVersionHistory] = useState(false);
  const [moveOrCopy, setMoveOrCopy] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { addErrorToast, addSuccessToast } = useToaster();
  const { isDesktop } = useMediaQuery();

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.replace(/^\/data-sharing/, '');
  const route = generateRoute(path);

  const {
    folderContent,
    getFolderContent,
    getProjects,
    createFolder,
    selected,
    setSelected,
    navigationInProgress,
    errorCode,
    hasWriteAccess,
    setSearchResults,
    getNextPage,
    isLoadingPage
  } = files;

  const { uploadFiles, addUploads, addFolderUpload, cancelUpload } = useUploader(path, refresh, createFolder);
  const dropzoneActive = useDropzone(addFolderUpload, hasWriteAccess);
  const isRootFolder = path === '';
  const isSearchResults = folderContent?.directory?.path === 'search-results';

  async function refresh() {
    getFolderContent();
    if (path === '') {
      getProjects();
    }
  }

  async function handleCopyLink() {
    try {
      const item = selected[0];

      const url = item.isDirectory ?
        encodeURI(`${window.location.origin}/data-sharing${path}/${item.name}`) :
        encodeURI(`${window.location.origin}/data-sharing${path}?file=${item.name}`);
      await navigator.clipboard.writeText(url);
      addSuccessToast('Link copied to clipboard');
    } catch (err) {
      addErrorToast(err.message);
    }
  }

  async function handleDelete() {
    setConfirmDelete(false);
    try {
      const result = await Promise.all(selected.map(item => deleteFile(`${path}/${item.name}`)));

      result.forEach((value, index) => {
        if (value === false) {
          addErrorToast(`Failed to delete ${selected[index].name}`);
        } else {
          addSuccessToast(`Deleted ${selected[index].name}`);
        }
      });

      refresh();
      setSelected([]);
    } catch (err) {
      addErrorToast(err.message);
    }
  }

  async function handleFolderCreation(dirName) {
    if (dirName === '') {
      return;
    }

    try {
      await createFolder(`/${dirName}`, true);
      addSuccessToast(<span>Folder <b>{dirName}</b> created</span>);
      setCreatingFolder(false);
    } catch (err) {
      addErrorToast('Failed to create folder');
      console.log(err);
    }
  }

  async function handleRename(newName) {
    if (newName === '') {
      return;
    }

    try {
      const item = selected[0];
      await rename(`${path}/${item.name}`, newName);
      addSuccessToast(`${item.isDirectory ? 'Folder' : 'File'} renamed`);
      setConfirmFileRename(false);
      refresh();
    } catch (err) {
      addErrorToast('Failed to rename file');
      console.log(err);
    }
  }

  function handleSelect(name) {
    let selection = [...selected];
    const selectedItem = selection.find(x => x.name === name);
    if (selectedItem) {
      selection = selection.filter(x => x !== selectedItem);
    } else {
      const item = folderContent.items.find(x => x.name === name);
      if (item) {
        selection.push(item);
      }
    }
    setSelected(selection);
  }

  function handleSelectOne(name) {
    if (selected.length === 1 && selected[0].name === name) {
      setSelected([]);
    } else {
      const item = folderContent.items.find(x => x.name === name);
      if (item) {
        setSelected([item]);
      }
    }
  }

  function handleSelectAll() {
    const allSelected = folderContent.items.length === selected.length;
    if (allSelected) {
      setSelected([]);
    } else {
      setSelected([...folderContent.items]);
    }
  }

  function handleOpen(item) {
    if (item) {
      if (item.isDirectory) {
        if (!navigationInProgress) {
          history.push(encodePath(item.fullPath));
        }
      } else {
        getFile(item.fullPath, item.name);
      }
    } else {
      console.log('Undefined item for open/download');
    }
  }

  async function handleVersionRestore(item) {
    try {
      await restoreVersion(`${path}/${item.currentVersionName}`, `${path}/${item.name}#${item.version}`);
      refresh();
      addSuccessToast(`Restored old version for ${item.name}`);
    } catch (err) {
      addErrorToast(`Failed to restore old version for ${item.name}`);
    }
  }

  function handleVersionDelete(item) {
    setConfirmVersionDelete(item);
  }

  async function handleGetFileVersion(item) {
    await navigator.clipboard.writeText(item.versionName);
    addSuccessToast('Copied to clipboard');
  }

  async function deleteFileVersion(item) {
    setConfirmVersionDelete(false);

    try {
      await deleteFile(`${path}/${item.name}`, item.version);
      addSuccessToast(`Deleted version ${item.version} of file ${item.name}`);
      refresh();
    } catch (err) {
      addErrorToast(`Failed to delete version ${item.version} of file ${item.name}`);
    }
  }

  function openPreview(item) {
    setSelected([item]);
    setPreviewOpen(true);
  }

  function downloadFolder(folder) {
    getFiles([folder.fullPath], path);
  }

  function downloadMultipleFiles(items) {
    getFiles(items.map(item => item.fullPath), path);
  }

  async function refreshFolder() {
    try {
      setIsRefreshing(true);
      await deleteFolderCache(path);
      await refresh();
    } catch (err) {
      addErrorToast('Failed to refresh folder contents.');
    }

    setIsRefreshing(false);
  }

  const columns = [
    { key: 'select', name: 'select', hideInHeaders: true },
    { key: 'fileType', name: 'Type', sortable: true },
    { key: 'name', name: 'Name', sortable: true },
    { key: 'lastModified', name: 'Last modified', sortable: true, hide: !isDesktop },
    { key: 'author_name', name: 'Author', sortable: true, hide: (isRootFolder && !isSearchResults) || !isDesktop },
    { key: 'owner_name', name: 'Owner', sortable: true, hide: !isRootFolder || !isDesktop || isSearchResults },
    { key: 'action', name: 'Actions', hide: isDesktop }
  ];

  const foldersAreSelected = selected.some(x => x.isDirectory);

  const actions = {
    singleFile: [
      { name: 'Download', icon: 'download', action: () => handleOpen(selected[0]) },
      { name: 'Get link', icon: 'share', action: () => handleCopyLink() },
      { name: 'Rename', icon: 'rename', action: () => setConfirmFileRename(true), disabled: !hasWriteAccess },
      { name: 'Copy', icon: 'copy', action: () => setMoveOrCopy('copy'), disabled: !hasWriteAccess },
      { name: 'Move', icon: 'move', action: () => setMoveOrCopy('move'), disabled: !hasWriteAccess },
      { name: 'Delete', icon: 'delete', action: () => setConfirmDelete(true), disabled: !hasWriteAccess }
    ],
    multipleFiles: [
      { name: 'Download', icon: 'download', action: () => downloadMultipleFiles(selected) },
      { name: 'Copy', icon: 'copy', action: () => setMoveOrCopy('copy'), disabled: !hasWriteAccess || foldersAreSelected },
      { name: 'Move', icon: 'move', action: () => setMoveOrCopy('move'), disabled: !hasWriteAccess || foldersAreSelected },
      { name: 'Delete', icon: 'delete', action: () => setConfirmDelete(true), disabled: !hasWriteAccess }
    ],
    folder: [
      { name: 'Open', icon: 'open', action: () => handleOpen(selected[0]) },
      { name: 'Download', icon: 'download', action: () => downloadFolder(selected[0]) },
      { name: 'Get link', icon: 'share', action: () => handleCopyLink() },
      { name: 'Rename', icon: 'rename', action: () => setConfirmFileRename(true), disabled: !hasWriteAccess },
      { name: 'Copy', icon: 'copy', action: () => setMoveOrCopy('copy'), disabled: !hasWriteAccess },
      { name: 'Move', icon: 'move', action: () => setMoveOrCopy('move'), disabled: !hasWriteAccess },
      { name: 'Delete', icon: 'delete', action: () => setConfirmDelete(true), disabled: !hasWriteAccess }
    ]
  };

  const folderDescription = folderContent?.directory?.description;

  function renderContent() {
    if (dropzoneActive) {
      return <Dropzone />;
    }

    return (
      <>
        <FileTable
          actions={actions}
          columns={columns}
          content={folderContent}
          errorCode={errorCode}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          handleSelectOne={handleSelectOne}
          navigationInProgress={navigationInProgress}
          openPreview={openPreview}
          path={path}
          selected={selected}
          update={refresh}
        />
        {folderContent?.hasMorePages && !isLoadingPage
          && <button className={styles.loadMore} onClick={getNextPage}>Load more...</button>}
        {isLoadingPage && <div className={styles.loadingPage}><Spinner /></div>}
      </>
    );
  }

  return (
    <>
      <Content>
        <Search clear={refresh} isSearchActive={isSearchResults} setSearchResults={setSearchResults} />

        <div className={styles.navigationRow}>
          {isSearchResults
            ? <div className={styles.searchResults}>Search results</div>
            : <Breadcrumb route={[...route]} />
          }
          {isRootFolder === false &&
            <New
              addUploads={addUploads}
              createFolder={createFolder}
              hasWriteAccess={hasWriteAccess}
              inProgress={uploadFiles.length > 0}
              isRootFolder={isRootFolder}
              showNewFolderDialog={() => setCreatingFolder(true)}
            />
          }
          <button
            className={`${styles.refresh} ${isRefreshing && styles.isRefreshing}`}
            onClick={refreshFolder}
            title="Refresh folder contents"
            type="button"
          >
            <RefreshButton />
          </button>
        </div>
        {folderDescription &&
          <p className={styles.folderDescription}>
            {folderDescription}
          </p>
        }
        <UploadStatus cancelUpload={cancelUpload} files={uploadFiles} />
        {renderContent()}
      </Content>
      {isDesktop &&
        <DetailsPanel
          actions={actions}
          items={selected}
          path={path}
          showVersionHistory={() => setVersionHistory(true)}
        />
      }
      {confirmDelete && selected.length > 0 &&
        <ConfirmDelete close={() => setConfirmDelete(false)} confirm={handleDelete} items={selected} />
      }
      {confirmVersionDelete && (
        <ConfirmDelete
          close={() => setConfirmVersionDelete(false)}
          confirm={deleteFileVersion}
          items={[confirmVersionDelete]}
          version
        />
      )}
      {confirmFileRename && selected.length > 0 &&
        <Rename close={() => setConfirmFileRename(false)} confirm={handleRename} item={selected[0]} />
      }
      {creatingFolder &&
        <CreateDirectory close={() => setCreatingFolder(false)} confirm={handleFolderCreation} path={path}/>
      }
      {versionHistory && selected.length > 0 &&
        <VersionHistory
          close={() => setVersionHistory(false)}
          handleGetFileVersion={handleGetFileVersion}
          handleVersionDelete={handleVersionDelete}
          handleVersionRestore={handleVersionRestore}
          item={selected[0]}
        />
      }
      {moveOrCopy &&
        <MoveAndCopy
          close={() => setMoveOrCopy(false)}
          items={selected}
          operation={moveOrCopy}
          path={path}
          update={refresh}
        />
      }
      {previewOpen &&
        <Preview
          close={() => setPreviewOpen(false)}
          fileName={selected[0].name}
          filePath={selected[0].fullPath}
        />
      }
    </>
  );
};

export default FileBrowser;
