import { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { listFiles, createDir, listProjects } from '../../utilities/api';
import { SCOPES, setAuthCookie } from '../../utilities/api/request';
import useToaster from '../../utilities/hooks/useToaster';

const useFiles = (path, permissions) => {
  const [projects, setProjects] = useState();
  const [folderContent, setFolderContent] = useState();
  const [selected, setSelected] = useState([]);
  const [navigationInProgress, setNavigationInProgress] = useState(true);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [errorCode, setErrorCode] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const groups = useSelector(state => state.permissions.groups);

  const { addErrorToast } = useToaster();
  const addErrorToastRef = useRef(addErrorToast);

  const hasWriteAccess =
    folderContent?.directory?.permissions.write ||
    (path === '' && permissions.dataSharing.admin);

  const refreshSelectedItems = (content) => {
    const params = new URLSearchParams(window.location.search);
    const preSelectedFile = params.get('file');

    if (preSelectedFile == null) {
      setSelected(selected => selected.map(s => content.items.find(item => item.guid === s.guid)).filter(x => !!x));
    } else {
      setSelected([content.items.find(item => item.name === preSelectedFile)].filter(x => !!x));
    }
  };

  const getFolderContent = useCallback(
    async () => {
      try {
        if (path.startsWith('/admin')) {
          setFolderContent([]);
          return;
        }
        const content = await listFiles(path, groups);
        setFolderContent(content);
        refreshSelectedItems(content);
        setErrorCode(false);
      } catch (err) {
        setFolderContent({ items: [] });
        setErrorCode(err.statusCode);
        console.log(err);
      }

      setNavigationInProgress(false);
    }, [path, groups]
  );

  const getNextPage = useCallback(
    async () => {
      try {
        setIsLoadingPage(true);
        const nextPage = page + 1;
        const content = await listFiles(path, groups, nextPage);
        const updatedContent = {
          ...folderContent,
          hasMorePages: content.hasMorePages,
          items: [
            ...folderContent.items,
            ...content.items
          ]
        };

        setPage(nextPage);
        setFolderContent(updatedContent);
      } catch (err) {
        setFolderContent({ items: [] });
        setErrorCode(err.statusCode);
        console.log(err);
      }

      setIsLoadingPage(false);
    }, [page, path, folderContent, groups]
  );

  const getProjects = useCallback(
    async () => {
      setIsLoadingProjects(true);
      try {
        const content = await listProjects();
        setProjects(content);
        setIsLoadingProjects(false);
        await setAuthCookie([SCOPES.storage]);
        return content;
      } catch (err) {
        addErrorToastRef.current(err.message);
        setIsLoadingProjects(false);
        console.log(err);
      }
    }, [addErrorToastRef]
  );

  const createFolder = async (folderName, refresh = true) => {
    const fullPath = path + folderName;
    await createDir(fullPath);
    refresh && getFolderContent();
  };

  const setSearchResults = (results) => {
    setFolderContent({
      directory: {
        path: 'search-results',
        permissions: { read: true, write: false },
        searchResults: true
      },
      items: results
    });
  };

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  useEffect(() => {
    getFolderContent();
  }, [getFolderContent]);

  useEffect(() => {
    setSelected([]);
    setNavigationInProgress(true);
  }, [path]);

  return {
    projects,
    folderContent,
    getFolderContent,
    getProjects,
    createFolder,
    selected,
    setSelected,
    isLoadingProjects,
    navigationInProgress,
    errorCode,
    hasWriteAccess,
    setSearchResults,
    getNextPage,
    isLoadingPage
  };
};

export default useFiles;
