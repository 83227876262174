import { useToasts } from 'react-toast-notifications';

import { error, success } from '../../toastTypes';

const useToaster = () => {
  const { addToast } = useToasts();

  const addSuccessToast = (message, options = {}) => addToast(message, { ...success, ...options });
  const addErrorToast = (message, options = {}) => addToast(message, { ...error, ...options });

  return {
    addSuccessToast,
    addErrorToast
  };
};

export default useToaster;
