import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import Avatar from '../Avatar';
import SkeletonRows from '../SkeletonRows';
import SortableHeader from '../SortableHeader';
import styles from '../Table.module.css';
import cellStyles from '../TableCellTypes.module.css';
import { formatVmSize } from '../../utilities/formatting';

import { ReactComponent as Add } from '../icons/add.svg';
import DateTime from '../DateTime';

import Status from './Status';

const PublishRow = ({ publish }) => (
  <tr>
    <td className={styles.clickable} colSpan="2" onClick={publish}>
      <div className={styles.centered}>
        <Add />
        Publish new application
      </div>
    </td>
    <td colSpan="3" />
  </tr>
);

const WorkspaceTable = ({ items, selected, select, isLoading, publish }) => {
  const [data, setData] = useState();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();

  function parseItems(items) {
    return items.map((item) => ({
      key: item.name,
      name: item.tags.dswname,
      owner: item.tags.owner_name || 'Unknown',
      size: formatVmSize(item.vmSize),
      creationDate: item.creationDate,
      status: item.status
    }));
  }

  useEffect(() => {
    setData(parseItems(items));
  }, [items]);

  function sortBy(key, order) {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (order === 'asc') {
        return get(a, key).localeCompare(get(b, key));
      } else {
        return get(b, key).localeCompare(get(a, key));
      }
    });
    setData(sortedData);
  }

  const columns = [
    { key: 'name', name: 'Name' },
    { key: 'owner', name: 'Owner' },
    { key: 'size', name: 'Size' },
    { key: 'creationDate', name: 'Created at' },
    { key: 'status.state', name: 'Status' }
  ];

  function handleSelect(name) {
    const item = items.find((x) => x.name === name);
    select(selected === item.name ? undefined : item.name);
  }

  function handleSort(key) {
    if (orderBy === key) {
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      setOrder(newOrder);
      sortBy(key, newOrder);
    } else {
      setOrderBy(key);
      sortBy(key, order);
    }
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.headers}>
          {columns.map((col) => (
            <SortableHeader
              active={col.key === orderBy}
              key={col.key}
              name={col.name}
              onClick={() => handleSort(col.key)}
              order={order}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => (
          <tr
            className={selected === item.key ? styles.selectedRow : null}
            key={item.key}
            onClick={() => handleSelect(item.key)}
          >
            <td>{item.name || item.key}</td>
            <td className={cellStyles.nowrap}>
              <Avatar name={item.owner} />
              {item.owner}
            </td>
            <td>{item.size}</td>
            <td>
              <DateTime date={item.creationDate} />
            </td>
            <td className={cellStyles.nowrap}>
              <Status status={item.status?.state ?? 'updating'} />
            </td>
          </tr>
        ))}
        {isLoading && <SkeletonRows amount={3} columns={columns} />}
        {publish && <PublishRow publish={publish} />}
      </tbody>
    </table>
  );
};

export default WorkspaceTable;
