import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import styles from './SearchUsers.module.css';

const SingleValue = props => (
  <components.SingleValue {...props}>
    <span>{props.data.name}</span>
    <span className={styles.upn}>{props.data.upn}</span>
  </components.SingleValue>
);

const Option = props => (
  <components.Option {...props}>
    <span>{props.data.name}</span>
    <span className={`${styles.upn} ${props.isSelected ? styles.selected : ''}`}>
      {props.data.upn}
    </span>
  </components.Option>
);

const Menu = props => (
  <>
    <div className={styles.menuText}>
      Type to search users.
    </div>
    <components.Menu {...props}>{props.children}</components.Menu>
  </>
);

const SearchUsers = ({ className, searchUsers, defaultUser, handleChange, filter, clearAfterSelect }) => {
  const [value, setValue] = useState();
  const defaultUserOption = {
    value: defaultUser.userName,
    upn: defaultUser.userName,
    name: defaultUser.name,
    accountName: defaultUser.accountName
  };

  const loadUsers = async (inputValue, callback) => {
    const users = await searchUsers(inputValue);
    const mappedUsers = users.map(user => ({
      value: user.userPrincipalName,
      upn: user.userPrincipalName,
      name: user.displayName,
      accountName: user.onPremisesSamAccountName
    }));

    if (filter) {
      callback(mappedUsers.filter(filter));
    } else {
      callback(mappedUsers);
    }
  };

  const onChange = (value) => {
    if (value && value.value !== undefined) {
      handleChange(value);
    }

    if (clearAfterSelect) {
      setValue(null);
    }
  };

  const onInputChange = (input) => {
    if (input === '' && clearAfterSelect) {
      setValue(null);
    }
  };

  return (
    <AsyncSelect
      className={className}
      components={{ SingleValue, Option, Menu }}
      defaultOptions={[defaultUserOption]}
      defaultValue={defaultUserOption}
      loadOptions={loadUsers}
      onChange={onChange}
      onInputChange={onInputChange}
      value={value}
    />
  );
};

export default SearchUsers;
